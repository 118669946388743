import { split, map, toPairs, compose, defaultTo, join, has, fromPairs } from "ramda"

export const objectToQueryString = compose<any, any, any, any, any, any>(
    join("&"),
    map(join("=")),
    toPairs,
    map(encodeURIComponent),
    defaultTo({})
)

export const queryStringAsObject = () => compose(
    fromPairs,
    map(split("=")) as any,
    split("&")
)(window.location.search.substring(1)) as any
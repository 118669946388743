
import * as R from "ramda"
import { ProviderDefinition } from "@sennen/dashboards-react-client"

export const SennenFileServiceProvider = {

    name: "sennenFileService",
    causesMutation: true,

    createMethod: (options): any => {
        return {
            async uploadBase64Files(fileServiceUrl, files = [], callBack) {
                const uploadResults = await Promise.all(
                    R.map(
                        async (file) => {
                            const { fileName, data, name } = file

                            const headers = new Headers()
                            headers.append("Content-Type", "text/plain")

                            try {
                                const result = await fetch(`${fileServiceUrl}/${fileName}?format=base64`, {
                                    method: "PUT",
                                    body: data,
                                    headers
                                })

                                const jsonResults = result.ok ? await result.json() : { success: false }
                                const uploaded = !!(jsonResults?.success === true)
                                
                                return { fileName, uploaded }
                            } catch (err) {
                                console.error("File Service - upload error", err.message)
                                return { fileName, uploaded: false }
                            }
                        },
                        files
                    )
                )

                callBack?.(uploadResults)

                return uploadResults
            },
            async deleteFiles(fileServiceUrl, fileNames = [], callBack) {
                const deleteResults = await Promise.all(
                    R.map(
                        async (fileName) => {
                            const headers = new Headers()
                            headers.append("Content-Type", "text/plain")

                            try {
                                const result = await fetch(`${fileServiceUrl}/${fileName}`, {
                                    method: "DELETE",
                                    headers
                                })

                                const jsonResults = result.ok ? await result.json() : { success: false }
                                const deleted = !!(jsonResults?.success === true)
                                
                                return { fileName, deleted }
                            } catch (err) {
                                console.error("File Service - delete error", err.message)
                                return { fileName, deleted: false }
                            }
                        },
                        fileNames
                    )
                )

                callBack?.(deleteResults)

                return deleteResults
            }
        }
    }

} as ProviderDefinition

import tinyGradient from "tinygradient"
import { map } from "ramda"
export type GradientColorSpaces = "rgb" | "hsv" | "revhsv"

export const gradientGenerator = (colorStops: any[], noSteps: number, colorSpace: GradientColorSpaces = "rgb"): string[] => {
    if (!Array.isArray(colorStops) || colorStops.length < 2) {
        throw new Error("The colorStops parameter must be an array of at least 2 values")
    }
    if (noSteps < 1) {
        throw new Error("The noSteps parameter must be greater than 0")
    }
    const tg = new tinyGradient(colorStops)
    const colors = colorSpace === "hsv" ? tg.hsv(noSteps) : colorSpace === "revhsv" ? tg.hsv(noSteps, true) : tg.rgb(noSteps)
    return map(c => c.toRgbString(), colors)
}
import { gradientGenerator, GradientColorSpaces } from "./gradientGenerator"

export default function (configType: string, config: any): any {
    return (minValue: number, maxValue: number, colorStops: any[], colorSpace: GradientColorSpaces = "rgb"): (value) => string => {
        if (minValue > maxValue) throw new Error("minValue cannot be greater than maxValue")
        if (maxValue < minValue) throw new Error("maxValue cannon be less than minValue")
        if (maxValue === minValue) throw new Error("minValue and maxValue cannot be equal")
        const gradient = gradientGenerator(colorStops, (maxValue - minValue) + 1, colorSpace)
        return (value: number) => {
            if (value > maxValue) value = maxValue
            if (value < minValue) value = minValue
            return gradient[value - minValue]
        }
    }
}
import * as React from "react" 
import {
    GanttOverlayProps, GanttOverlayState,
    GanttOverlayStartMethod, GanttOverlayStartMethodOptions
} from "./GanttInterfaces" 
import * as R from "ramda"

export class GanttOverlay extends React.Component<GanttOverlayProps, GanttOverlayState> {
    private disposed: boolean = false

    constructor(props) {
        super(props)
        this.state = { overlayState: "hidden" }
        this.initDocEvents()
        this.itemResizeMonitor()
    }

    public componentDidMount() {
        const { onSetStartMethod } = this.props
        if (onSetStartMethod) {
            onSetStartMethod(this.start.bind(this))
        }
    }

    public start: GanttOverlayStartMethod = (options: GanttOverlayStartMethodOptions) => {
        const { onResizeStart } = this.props
        const { element, mode, sourceReference } = options
        const elementRect = element.getRectRelativeToContainer()

        const initialState: GanttOverlayState = {
            overlayState: "resizing",
            element: element,
            elementRect,
            resizeMode: mode,
            overlayRect: elementRect,
            sourceReference
        }

        const modifiedState = onResizeStart(initialState) || initialState
        const newState = { ...this.state, ...modifiedState }
        this.setState(newState)
    }

    public componentWillUnmount() {
        this.disposed = true
        this.disposeDocEvents()
    }

    private initDocEvents() {
        this["mouseUpHandler"] = ev => {
            if (this.state.overlayState === "hidden") return
            this.setState(
                { ...this.state, ...{ overlayState: "hidden" } },
                () => this.props.onResizeEnd(this.state)
            )
        }
        document.addEventListener("mouseup", this["mouseUpHandler"])
    }

    private disposeDocEvents() {
        document.removeEventListener("mouseup", this["mouseUpHandler"])
    }


    private itemResizeMonitor() {
        const { overlayState, element, overlayRect } = this.state
        if (overlayState === "resizing" && element) {
            const updateRect = this.props.onResize(this.state)
            if (JSON.stringify(overlayRect) !== JSON.stringify(updateRect)) {
                this.setState({ ...this.state, ...{ overlayRect: updateRect } })
            }
        }
        if (!this.disposed) setTimeout(() => this.itemResizeMonitor(), 25)
    }

    // Render
    public render() {
        const { overlayState, overlayRect, offset } = this.state

        if (overlayState === "resizing") {
            const styles = {
                display: "block",
                top: overlayRect.top, left: overlayRect.left,
                width: overlayRect.width, height: overlayRect.height
            }
            return <div className="gantt-resize-overlay" style={styles} ></div>
        }
        return null
    }

}
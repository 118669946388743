import { QuickTracksFormControl, QuickTracksFormPickListItem, QuickTracksTimePickerControl } from "./QuickTracksForm"
import { cond, equals, always, T, map, path, find, clone } from "ramda"

const generateSelectMetaData = (formControl: QuickTracksFormControl) => {
    const sourceItems = (path(["PickList", "Items"], formControl) || []) as QuickTracksFormPickListItem[]
    const items = map((i) => {
        return { value: i.Value, text: i.Text }
    }, sourceItems)
    const defaultValue = find(i => !!i.Selected, sourceItems)
    return { items }
}

const generateTextAreaMetaData = (formControl: QuickTracksFormControl) => {
    return {
        rows: formControl.NumberOfRows || 4
    }
}

const generateDateTimeMetaData = (formControl: QuickTracksFormControl) => {
    const timePicker = (formControl.TimePicker || {}) as QuickTracksTimePickerControl

    // QUICKTRACKS BUG  FIX -> ShowMinutes property is always false, assume we always show minutes
    timePicker.ShowMinutes = true
    // QUICKTRACKS BUG FIX

    return {
        minutesVisible: timePicker.ShowMinutes,
        minutesStep: timePicker.MinutesStep || 1,
        secondsVisible: timePicker.ShowSeconds,
        secondsStep: timePicker.SecondsStep || 1
    }
}

export const metaDataGenerator = (controlType: string, formControl: QuickTracksFormControl) => {
    const getMetaDataForType = cond<any, any>([
        [equals("multiSelect"), () => generateSelectMetaData(formControl)],
        [equals("select"), () => generateSelectMetaData(formControl)],
        [equals("textArea"), () => generateTextAreaMetaData(formControl)],
        [equals("dateTime"), () => generateDateTimeMetaData(formControl)],
        [T, always(null)]
    ])
    return getMetaDataForType(controlType)
}
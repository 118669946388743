
import {
    addIndex, forEach, compose, head, split, ifElse,
    map, identity, flatten, join,
    filter, type, curry, path, assocPath, forEachObjIndexed,
    contains, fromPairs, find
} from "ramda"

import { pathString, assocPathString, parsePath } from "../../helpers/path"

const forEachIndexed = addIndex(forEach)
const isArray = Array.isArray
const omitPathArrays = compose<any, any, any, string>(join("."), filter(x => type(x) === "String"), parsePath)
export const stringifyPath = (p, obj) => assocPathString(p, JSON.stringify(pathString(p, obj)), obj)

const indexObj = (prefix, o, list) => {
    forEachObjIndexed((v, k: any) => {
        if (!v) return
        if (isArray(v)) {
            forEachIndexed((v, i) => {
                const aPfx = prefix + k + "[" + i + "]"
                list.push(aPfx)
                indexObj(aPfx + ".", v, list)
            }, v)
        } else {
            list.push(prefix + k)
            indexObj(prefix + k + ".", v, list)
        }
    }, o)
}

export const explodeObj = obj => {
    const list = []
    indexObj("", obj, list)
    return list
}

export const matchPaths = (pathList: string[], obj) => {
    const paths = explodeObj(obj)
    const isInPathList = p => contains(omitPathArrays(p), pathList)
    const requiredPaths = filter(isInPathList, paths)
    return map(p => {
        return [p, find(x => omitPathArrays(p) === x, pathList)] as [string, string]
    }, requiredPaths)
}

export const deconstruct = (pathList: string[], obj) => {
    const paths = explodeObj(obj)
    const isInPathList = p => contains(omitPathArrays(p), pathList)
    const requiredPaths = filter(isInPathList, paths)
    return fromPairs(map(p => {
        return [
            p,
            {
                match: find(x => omitPathArrays(p) === x, pathList),
                data: JSON.stringify(pathString(p, obj))
            }

        ] as any
    }, requiredPaths))
}


import { FormProps } from "@sennen/dashboard-extension-sennen-core"
import { JsonSnippetInsertOptions } from "../../../actionItems/JsonInsertSnippetActionItem"
import { GenericEditorActionItemHandlerArgs } from "../../../genericEditor/GenericEditorConfigProviderInterfaces"
import { requiredSelect, requiredIdInput, integerInput } from "../../../../helpers/formControls"
import { map, identity, merge, sortBy, pathOr } from "ramda"

const toTextValue = x => identity({ text: x, value: x })

export const addDataSourceSubscription: JsonSnippetInsertOptions = {
    name: "add-ds-sub",
    buttonText: "DataSource Subscription",
    buttonIconClassName: "fa fa-plus",
    insertLocation: "child",
    insertPath: ["dataSourceSubscriptions"],
    insertMode: "append",
    template: async data => {
        const { dataPath, dataSourceId, refreshPeriod } = data
        return {
            "dataPath": dataPath,
            "properties": {
                "query": null,
                "refreshPeriod": refreshPeriod
            },
            "dataSourceId": dataSourceId,
            "inputMappers": []
        }

    },
    templateDataFormResolver: async (args: GenericEditorActionItemHandlerArgs) => {
        const dataSources = pathOr([], ["context", "data", "dataSourceList"], args)
        const dataSourceItems = map(toTextValue, sortBy(identity, dataSources))

        return {
            formTitle: "Add DataSource Subscription",
            data: {
                dataPath: null,
                dataSourceId: null,
                refreshPeriod: null
            },
            controls: [
                requiredIdInput("dataPath", "Data Path"),
                requiredSelect("dataSourceId", "Data Source", dataSourceItems),
                merge(integerInput("refreshPeriod", "Refresh Period"), { placeHolderText: "Period in milliseconds ..." })
            ]
        } as FormProps
    }
}

export const addDataSourceSubscriptionSnippet = [addDataSourceSubscription]
import { compose, merge, identity, always, prop } from "ramda"

import {
    requiredStringInput,
    idInput,
    requiredIdInput,
    requiredJsonAce,
    stringInput
} from "../../../helpers/formControls"
import { pathString, assocPathString } from "../../../helpers/path"
import {
    GenericEditorConfigProvider,
    GenericEditorConfig,
    GenericEditorDescriptor,
    GenericEditorEditor
} from "../../genericEditor/GenericEditorConfigProviderInterfaces"
import { JsonInsertSnippet } from "../../actionItems/JsonInsertSnippetActionItem"
import { addMenuItemSnippet } from "./snippets/menu"

import { deleteNode, moveNodeUp, moveNodeDown } from "../dashboard/snippets/general"

const jsonIn = d => JSON.stringify(d, null, 4)
const jsonOut = d => d ? JSON.parse(d) : undefined
const transformPath = (path, trans, data) => assocPathString(path, trans(pathString(path, data)), data)

const JsonAce = merge(requiredJsonAce("authorisation", "Authorisation", "16vh"), { validationMessage: null })

const menuItemEditor: GenericEditorEditor = {
    actions: [
        JsonInsertSnippet(addMenuItemSnippet),
        moveNodeUp("Menu Item"),
        moveNodeDown("Menu Item"),
        deleteNode("Menu Item", ["title"])
    ],
    toForm: d => transformPath("authorisation", jsonIn, d),
    fromForm: d => transformPath("authorisation", jsonOut, d),
    controls: d => [
        requiredStringInput("title", "Title"),
        idInput("dashBoardId", "DashBoard ID"),
        stringInput("dashBoardPath", "DashBoard Path"),
        JsonAce
    ]
}

const rootMenuItemEditor: GenericEditorEditor = {
    actions: [JsonInsertSnippet(addMenuItemSnippet)],
    toForm: identity,
    fromForm: identity,
    controls: d => [
        requiredIdInput("dashBoardEditorId", "Editor DashBoard ID")
    ]
}

const nodeIcon = node => node.items?.length ? "ri-menu-unfold-fill" : null

const descriptors: GenericEditorDescriptor[] = [
    {
        selected: true,
        icon: "ri-menu-line",
        iconColor: "#cbcb41",
        description: "Menu",
        match: "entry",
        editor: rootMenuItemEditor
    },
    {
        selected: false,
        icon: nodeIcon,
        iconColor: "#519aba",
        description: prop("title"),
        match: "entry.items",
        editor: menuItemEditor
    },
    {
        selected: false,
        icon: nodeIcon,
        iconColor: "#519aba",
        description: prop("title"),
        match: "entry.items.items",
        editor: menuItemEditor
    },
    {
        selected: false,
        icon: nodeIcon,
        iconColor: "#519aba",
        description: prop("title"),
        match: "entry.items.items.items",
        editor: menuItemEditor
    },
    {
        selected: false,
        icon: nodeIcon,
        iconColor: "#519aba",
        description: prop("title"),
        match: "entry.items.items.items.items",
        editor: menuItemEditor
    },
    {
        selected: false,
        icon: nodeIcon,
        iconColor: "#519aba",
        description: prop("title"),
        match: "entry.items.items.items.items.items",
        editor: menuItemEditor
    }
]



export class MenuEditorConfigProvider implements GenericEditorConfigProvider {
    public propertyName = "menuEditorConfig"
    public createMethod = () => () => {
        const config: GenericEditorConfig = {
            explorerSortByDescription: false,
            libraryEntryType: "menu",
            entityDescription: "Menu",
            getEntityName: (d) => pathString("entry.properties.title", d),
            getTitle: (d) => pathString("entry.properties.title", d),
            descriptors,
            resolveAdditionalEntryProperties: always({})
        }
        return config
    }
}
import * as React from "react"
import { Theme, ComponentRender } from "@sennen/dashboards-react-client"
import { ThemedComponentDecorator, ThemedComponentStyles } from "@sennen/dashboards-react-component"
import { requiredJavaScriptAce, requiredStringInput } from "../../helpers/formControls"
import { CreatePacker, UnPackedFunction } from "@sennen/js-function-packer"
import * as R from "ramda"


const { pack, unpack } = CreatePacker("DashBoardFunction")

const DB_NEW_FUNC_TEMPLATE = `function newFunction() {\n\n}`
const mapIndexed = R.addIndex(R.map)

export type formControlEvent = (controlId: string, value: any) => void

export interface TabbedFunctionEditorProps {
    id: string
    theme?: Theme
    value: string
    height?: string
    disabled?: boolean
    componentRender?: ComponentRender
    onChange?: formControlEvent
    onBlur?: formControlEvent
    confirm?: (message: string, onOk?: () => void, onCancel?: () => void) => void
}

export interface TabbedFunctionEditorState {
    unwrapped: UnPackedFunction,
    selectedFunctionIndex?: number
}


const defaultState = {
    unwrapped: [{ name: null, value: "" }],
    selectedFunctionIndex: 0
} as TabbedFunctionEditorState



const stateFromProps = (props: TabbedFunctionEditorProps, currentState: TabbedFunctionEditorState) => {
    const selectedIndex = currentState.selectedFunctionIndex
    const unwrapped = unpack(props.value)
    const selectedFunctionIndex = unwrapped.length > selectedIndex ? selectedIndex : 0
    return { unwrapped, selectedFunctionIndex }
}

@ThemedComponentDecorator((theme: Theme) => {
    return {
        css: `
            @id .form > .formField .controlContainer .aceEditor {
                border: none;
                border-radius: 0;
            } 
        `
    }
})
export class TabbedFunctionEditor extends React.Component<TabbedFunctionEditorProps, TabbedFunctionEditorState> {

    constructor(props) {
        super(props)
        this.state = stateFromProps(props, defaultState)
    }

    private disposed = false

    public componentWillReceiveProps(props) {
        const newState = stateFromProps(props, this.state)
        this.setState(newState)
    }

    public componentWillUnmount() {
        this.disposed = true
    }

    private editorRef: any

    public focus() {
        if (this.editorRef) this.editorRef.focus()
    }

    public render() {
        const { disabled } = this.props
        const { unwrapped, selectedFunctionIndex } = this.state
        const syncMethod = this.editorSync(selectedFunctionIndex)
        const functionString = R.nth(selectedFunctionIndex, unwrapped).value

        let editor = this.renderEditorComponent(functionString, syncMethod, disabled)

        const tabs = mapIndexed((k: any, idx) => {
            let className = "tab-button"
            const { name } = k
            const removeIcon = idx === selectedFunctionIndex && idx > 0 ?
                <i className="fa fa-minus-square tab-button-icon--right" onClick={() => !disabled && this.removeFunction(idx)}></i>
                : null
            className += idx === selectedFunctionIndex ? " tab-button--selected" : " tab-button--not-selected"
            return <div key={`function${idx} `} className={className} onClick={() => this.selectFunction(idx)}>
                {name}{removeIcon}
            </div>
        }, unwrapped)
        return this["renderCssContainer"](
            <div className="tab-container container container-no-chrome tab-container--small">
                <div className="tab-header cf">
                    {tabs}
                    <div className="tab-button tab-button--not-selected" onClick={() => !disabled && this.addFunction()}>
                        <i className="fa fa-plus-square" aria-hidden="true"></i>
                    </div>
                    <div className="tab-button tab-button--filler">&nbsp;</div>
                </div>
                <div className="tab-content">
                    <div className="tab-panel tab-panel--selected tab-panel--no-padding">
                        {editor}
                    </div>
                </div>
            </div>
        )
    }

    private editorSync = R.curry((
        functionIndex: number,
        functionString: string,
        eventTrigger: (data: any) => void
    ) => {
        const { unwrapped } = this.state
        const updatedItem = R.assoc("value", functionString, R.nth(functionIndex, unwrapped))
        const updated = R.update(functionIndex, updatedItem, unwrapped)
        const updatedWrapped = pack(updated)
        if (!this.disposed) {
            this.setState(
                { ...this.state, ...{ unwrapped: updated } },
                () => eventTrigger(updatedWrapped)
            )
        }
    })

    private renderEditorComponent(
        functionString: string,
        syncMethod: (f: string, eventTrigger: (data: any) => void) => void,
        disabled: boolean
    ) {
        const { id, componentRender, onChange, onBlur, height } = this.props
        const componentProps = {
            hostedComponentName: "Form",
            onEvent: () => {
                debugger
            },
            metaData: {
                id: "TabbedAceEditor",
                hideButtonBar: true,
                hideValidationMessages: true,
                // theme,
                controls: [{
                    ...requiredJavaScriptAce("val", "", height),
                    ...{ disabled }
                }],
                data: { val: functionString },
                onSync: d => syncMethod(d.val, (value) => onBlur(id, value)),
                onChangeSync: d => syncMethod(d.val, (value) => onChange(id, value)),

            }
        }
        return componentRender.renderHostedComponent(componentProps)
        // return <Component ref={r => this.editorRef = r} key="TabbedAceEditor" {...componentProps}></Component>;
        // }
        return null;
    }

    private addFunction() {
        const { onChange, id } = this.props
        const { unwrapped } = this.state
        const updated = R.append({ name: null, value: DB_NEW_FUNC_TEMPLATE }, unwrapped)
        this.setState(
            { ...this.state, ...{ selectedFunctionIndex: updated.length - 1, unwrapped: updated } },
            () => {
                onChange(id, pack(updated))
                this.clearUndoHistory()
            }
        )
    }

    private removeFunction(functionIndex: number) {
        this.confirm("Are you sure?", () => {
            const { onChange, id } = this.props
            const { unwrapped } = this.state
            const updated = R.remove(functionIndex, 1, unwrapped)
            this.setState(
                { ...this.state, ...{ selectedFunctionIndex: 0, unwrapped: updated } },
                () => {
                    onChange(id, pack(updated))
                    this.clearUndoHistory()
                }
            )
        })
    }

    private confirm(message: string, onOk: () => void) {
        const { confirm } = this.props
        if (R.is(Function, confirm)) {
            confirm(message, onOk)
        } else {
            if (window.confirm(message)) onOk()
        }

    }

    private clearUndoHistory() {
        setTimeout(() => {
            const aceRef = this.editorRef && this.editorRef.getControlRef("val")
            if (aceRef) aceRef.resetUndoHistory()
        }, 250);
    }

    private selectFunction(selectedFunctionIndex) {
        this.setState(
            {
                ...this.state,
                ...{ selectedFunctionIndex }
            },
            () => this.clearUndoHistory()
        )
    }

}

import { resolveEditorConfig, setBusyIfNotEditing } from "./GenericEditorProviderShared"
import * as R from "ramda"

export const titleBarMapper = (providers, descriptorProviderName, context) => {
    const { editingDashBoard } = context.state
    const { textColors } = providers.theme.getCurrent()
    const { lowIntensity } = textColors

    const config = resolveEditorConfig(providers, descriptorProviderName)
    const titleParts = R.split("\n", R.defaultTo("", config.getTitle(editingDashBoard)))
    const title = R.head(titleParts)
    const subTitle = R.nth(1, titleParts)
    const display = context.state.loadState === "dataLoadError" ? "hidden" : "visible"

    document.title = `Edit: ${title}`

    const areas = ["title", "subTitle"]
    const columns = "1fr"

    const components = R.map(
        R.assoc("alignSelf", "center"),
        [
            { hostedComponentName: "TextElement", area: "title", metaData: { id: "title", content: title, type: "h1", overflowEllipsis: true } },
            { hostedComponentName: "TextElement", area: "subTitle", metaData: { id: "subTitle", content: subTitle, type: "h6", overflowEllipsis: true } }
        ]
    )

    return setBusyIfNotEditing(context, { columnGap: "medium", areas, components, columns, display })

}


import { DateTime } from "luxon"
import * as R from "ramda"
import { formatUTC, isNilOrEmpty, UTCTimeRegex } from "./dateTimeUtils"
import { isContext } from "../../helpers/isContext"

export type FormatOptions = {
    emptyValue?: string
}

export const validTimeFormats = [
    "TIME_SIMPLE",
    "TIME_WITH_SECONDS",
    "TIME_24_SIMPLE",
    "TIME_24_WITH_SECONDS",
]

export const timeUnits = [
    "hour",
    "hours",
    "minute",
    "minutes",
    "second",
    "seconds",
    "millisecond",
    "milliseconds"
]
const validate = (context: any, timeISO: string): { valid: boolean, errorString?: string } => {
    if (!isContext(context)) return { valid: false, errorString: "Context not passed" }
    if (!R.is(String, timeISO)) return { valid: false, errorString: `Invalid time` }
    if (!UTCTimeRegex.test(timeISO)) return { valid: false, errorString: `Invalid ISO time` }
    return { valid: true }
}

export const SennenTime = (context: any, timeISO?: string) => {
    const { valid, errorString } = validate(context, timeISO)

    const sennenTime = {
        isValid: valid,
        now: () => SennenTime(context, DateTime.utc().toISOTime().split("Z")[0]),
        toISO: () => {
            if (!valid) throw new Error(errorString)
            return timeISO
        },

        format: (format: string, options: FormatOptions = {}) => {
            const { emptyValue = "" } = options

            if (isNilOrEmpty(timeISO)) return emptyValue
            if (!valid) return errorString
            if (!validTimeFormats.includes(format)) return `Invalid Format`

            return formatUTC(context, timeISO, format)
        }
    }

    return sennenTime
}
import { Theme } from "@sennen/dashboards-interfaces"
import { ThemedComponentDecorator } from "@sennen/dashboards-react-component"
import { scale, toCss } from "@sennen/dashboards-react-client"
import * as R from "ramda"
import * as React from "react"
import tc from "tinycolor2"
import { styles } from "./ArcGaugeStyles"

export interface ArcGaugeProps {
    // Axis
    axisMin?: number
    axisMax?: number
    axisPosition?: number

    // Values
    value?: number
    secondaryValue?: number

    // Text
    text?: string
    secondaryText?: string
    maxText?: string
    minText?: string
    condensedFont?: boolean

    // Text Scaling
    textScale?: number
    secondaryTextScale?: number
    minMaxTextScale?: number

    // Misc
    color?: string //| Array<string>
    secondaryColor?: string //| Array<string>

    // Framework Props
    theme?: Theme
}

export interface ArcGaugeState { }

const toPercent = R.curry((min, max, value) => {
    const clampedValue = R.clamp(min, max, value)
    const total = Math.abs(min) + Math.abs(max)
    return Math.round(((clampedValue - min) / total) * 10000) / 100
})

const percentToDegrees = p => R.clamp(0, 180, Math.round(p * 7.2) / 4)

const arcFromPercent = (percent: number, axisPercent: number) => {
    const percentages = { start: 0, end: 0 }
    if (percent < axisPercent) {
        percentages.start = percent
        percentages.end = axisPercent
    } else {
        percentages.start = axisPercent
        percentages.end = percent
    }
    return {
        startAngle: percentToDegrees(percentages.start),
        endAngle: percentToDegrees(percentages.end)
    }
}

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0
    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

const linePath = (x, y, dx, dy) => R.join(" ", ["M", x, y, "L", dx, dy])

const arcPath = (x, y, radius, startAngle, endAngle): string => {
    const start = polarToCartesian(x, y, radius, endAngle)
    const end = polarToCartesian(x, y, radius, startAngle)
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"
    return R.join(" ", [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ])
}

const gaugePath = (x, y, radius, startAngle, endAngle) => arcPath(x, y, radius, startAngle - 90, endAngle - 90)

const renderSingleChart = (styleId: any, start: number, end: number) => {
    return [
        <path key="arcTotal1" id="arcTotal1" fill="none" stroke={`url(#${styleId}-gradientTotal)`} d={gaugePath(200, 200, 156, 0, 180)} strokeWidth="72" />,
        <path key="arc1" id="arc1" fill="none" stroke={`url(#${styleId}-gradient)`} d={gaugePath(200, 200, 156, start, end)} strokeWidth="72" />,
    ]
}

const renderDoubleChart = (styleId: any, start1: number, end1: number, start2: number, end2: number) => {
    return [
        <path key="arcTotal1" id="arcTotal1" fill="none" stroke={`url(#${styleId}-gradientTotal)`} d={gaugePath(200, 200, 147, 0, 180)} strokeWidth="54" />,
        <path key="arcTotal2" id="arcTotal2" fill="none" stroke={`url(#${styleId}-gradientTotal)`} d={gaugePath(200, 200, 185, 0, 180)} strokeWidth="14" />,
        <path key="arc1" id="arc1" fill="none" stroke={`url(#${styleId}-gradient)`} d={gaugePath(200, 200, 147, start1, end1)} strokeWidth="54" />,
        <path key="arc2" id="arc2" fill="none" stroke={`url(#${styleId}-gradient-secondary)`} d={gaugePath(200, 200, 185, start2, end2)} strokeWidth="14" />,
    ]
}

const renderOutline = (theme: Theme, axisPos: number) => {
    const lineColor = theme.textColors.highIntensity
    const arcStartAngle = axisPos === 0 ? 4 : percentToDegrees(axisPos)
    return [
        <path key="arcOutline" id="arcOutline" fill="none" stroke={lineColor} strokeWidth="4" d={arcPath(200, 200, 198, -90, 90)} />,
        <path key="tickArcStart" id="tickArcStart" fill="none" stroke={lineColor} strokeWidth="4" d={arcPath(200, 200, 114, -90, arcStartAngle - 90)} />,
        <path key="tickArcEnd" id="tickArcEnd" fill="none" stroke={lineColor} strokeWidth="4" d={arcPath(200, 200, 114, 86, 90)} />,
        <path key="tickStart" id="tickStart" stroke={lineColor} strokeWidth="4" d={linePath(1, 198, 85, 198)} />,
        <path key="tickEnd" id="tickEnd" stroke={lineColor} strokeWidth="4" d={linePath(314, 198, 399, 198)} />
    ]
}

const renderGradient = (styleId: any, theme: Theme, color: string, secondaryColor: string) => {

    const bgColor = tc(theme.backgroundColors.lighter).setAlpha(.15).toRgbString()
    const pc = color || "white"
    const sc = secondaryColor || pc

    return [
        < linearGradient key="gradient-a" gradientUnits="userSpaceOnUse" id={`${styleId}-gradient`} x1="0%" y1="0%" x2="100%" y2="0%" >
            <stop offset="0%" stopColor={pc} />
            <stop offset="100%" stopColor={pc} />
        </linearGradient >,
        < linearGradient key="gradient-b" gradientUnits="userSpaceOnUse" id={`${styleId}-gradient-secondary`} x1="0%" y1="0%" x2="100%" y2="0%" >
            <stop offset="0%" stopColor={sc} />
            <stop offset="100%" stopColor={sc} />
        </linearGradient >,
        <linearGradient key="gradient-c" gradientUnits="userSpaceOnUse" id={`${styleId}-gradientTotal`} x1="0%" y1="0%" x2="100%" y2="0%">
            {/* <stop offset="0%" stopColor="rgba(0,255,0,.1)" />
            <stop offset="40%" stopColor="rgba(255,255,0,.1)" />
            <stop offset="100%" stopColor="rgba(255,0,0,.1)" /> */}
            <stop offset="0%" stopColor={bgColor} />
            <stop offset="100%" stopColor={bgColor} />
        </linearGradient>
    ]
}


@ThemedComponentDecorator(styles)
export class ArcGauge extends React.Component<ArcGaugeProps, ArcGaugeState> {

    public static defaultProps: ArcGaugeProps = {
        axisMin: 0,
        axisMax: 100,
        axisPosition: 0,
        value: 0,
        condensedFont: true,
        textScale: 2,
        secondaryTextScale: 1,
        minMaxTextScale: .8
    }

    public render() {
        const {
            axisMin, axisMax, axisPosition,
            value, secondaryValue,
            text, secondaryText, minText, maxText,
            condensedFont, textScale, secondaryTextScale, minMaxTextScale,
            color, secondaryColor,
            theme,
        } = this.props

        const styleId = this["styleId"] // Get style id from themedComponent base

        // Values
        const percent = toPercent(axisMin, axisMax)
        const axisPos = percent(axisPosition)
        const valueArc = arcFromPercent(percent(value), axisPos)
        const secondaryValueArc = arcFromPercent(percent(secondaryValue), axisPos)

        // Fonts
        const baseFont = condensedFont ? theme.fonts.condensed : theme.fonts.default
        const textFontSize = toCss(scale(baseFont.size, textScale))
        const secondaryTextFontSize = toCss(scale(baseFont.size, secondaryTextScale))
        const minMaxFontSize = toCss(scale(baseFont.size, minMaxTextScale))

        const className = `gauge ${condensedFont ? "gauge--condensed" : ""}`
        const style = secondaryValue ? "double" : "single"
        const chart = style === "single" ?
            renderSingleChart(styleId, valueArc.startAngle, valueArc.endAngle) :
            renderDoubleChart(styleId, valueArc.startAngle, valueArc.endAngle, secondaryValueArc.startAngle, secondaryValueArc.endAngle)

        return this["renderCssContainer"](
            <div className={className} >
                <svg key="gauge-svg" className="gauge-svg" style={{ paddingBottom: minMaxFontSize }} preserveAspectRatio="xMidYMin" viewBox="0 0 400 200" version="1.1">
                    {renderGradient(styleId, theme, color, secondaryColor)}
                    <defs>
                        <mask id={`${styleId}-arcClip`} maskUnits="userSpaceOnUse" >
                            <rect x="0" y="0" width="400" height="192" fill="white" strokeWidth="0" />
                        </mask>
                    </defs>
                    <g mask={`url(#${styleId}-arcClip)`}>{chart}</g>
                    {renderOutline(theme, axisPos)}
                </svg>
                <div key="gauge-metrics" className="gauge-metrics" style={{ paddingBottom: minMaxFontSize }}>
                    <div className="gauge-text-secondary" style={{ fontSize: secondaryTextFontSize }} >{secondaryText || secondaryValue || ""}</div>
                    <div className="gauge-text" style={{ fontSize: textFontSize }} >{text || value}</div>
                </div>
                <div key="gauge-min" className="gauge-min" style={{ fontSize: minMaxFontSize }} >{minText || axisMin}</div>
                <div key="gauge-max" className="gauge-max" style={{ fontSize: minMaxFontSize }}>{maxText || axisMax}</div>

            </div >
        )

    }
}

import * as R from "ramda"

export const syncShowDashBoard = (providers: any, context: any, options: any, onEvent: (event: any) => void) => {
    providers.dashBoardState({
        showDashBoardEvent: null,
        showDashBoard: {
            ...options,
            mode: "sync",
            visible: true
        }
    })

    const onEventFactory = eventName => eventData => {
        providers.dashBoardState(
            { "showDashBoard": null, "showDashBoardEvent": null }
        )
        onEvent({ eventName, eventData })
    }
    providers.navigation.modalLayout("modalDashBoardLayout", {
        resultCallBack: onEventFactory("onResult"),
        cancelCallBack: onEventFactory("onCancel")
    })

}


export const syncShowModalForm = (providers: any, context: any, formType: string) => {
    providers.dashBoardState("modalType", formType)
    providers.navigation.modalLayout("modalFormLayout", {
        visibleCallBack: () => providers.focus.dashPart("dashBoardModalForm")
    })
}

import { ClientApp } from "@sennen/dashboards-react-client"
import { GenericEditorProvider } from "./genericEditor/GenericEditorProvider"
import { DataSourceEditorProvider } from "./editorConfigProviders/dashboard/DataSourceEditorProvider"
import { DashBoardEditorConfigProvider } from "./editorConfigProviders/dashboard/DashBoardEditorConfigProvider"
import { MenuEditorConfigProvider } from "./editorConfigProviders/menu/MenuEditorConfigProvider"
import { RegisteredProvider } from "./clientRegistry/RegisteredProvider"

export const registerEditors = (clientApp: ClientApp) => {
    clientApp.registerProvider("DataSourceEditorProvider", DataSourceEditorProvider)
    clientApp.registerProvider("GenericEditorProvider", GenericEditorProvider)
    clientApp.registerProvider("DashBoardEditorConfigProvider", DashBoardEditorConfigProvider)
    clientApp.registerProvider("RegisteredProvider", RegisteredProvider)
    clientApp.registerProvider("MenuEditorConfigProvider", MenuEditorConfigProvider)
}


import { ProviderDefinition, developerDeprecationDebounced } from "@sennen/dashboards-react-client"
import { formatDateTime, formatDate, formatTime } from "./SennenDateTimeFormatters"

const deprecationWarning = developerDeprecationDebounced("'sennenSystem' provider is deprecated. Use 'sennen' provider instead.")

const showDeprecationOnInvoke = fn => (...args) => {
    deprecationWarning()
    return fn(...args)
}

export const SennenSystemProvider: ProviderDefinition = {
    name: "sennenSystem",
    causesMutation: false,
    createMethod: (options): any => {
        return {
            formatDateTime: showDeprecationOnInvoke(formatDateTime),
            formatDate: showDeprecationOnInvoke(formatDate),
            formatTime: showDeprecationOnInvoke(formatTime)
        }
    }
}
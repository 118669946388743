import { pathOr, find, propEq, curry, is, assoc, head, defaultTo, path } from "ramda"
import {
    GenericEditorConfig, GenericEditorDescriptor,
    GenericEditorEditor, GenericEditorActionItem,
    GenericEditorActionItemHandlerArgs,
    GenericEditorAlert
} from "./GenericEditorConfigProviderInterfaces"
import { Theme } from "@sennen/dashboards-interfaces"

const isArray = is(Array)

export const descriptorByMatch = (descriptors: GenericEditorDescriptor[], m: string) => {
    return find(propEq("match", m), descriptors) as GenericEditorDescriptor
}

export const resolveEditorConfig = (providers, editorConfigProviderName): GenericEditorConfig => {
    const providerName = editorConfigProviderName
    const provider = providers[providerName]
    if (!provider) throw new Error(`GenericEditorProvider Error - The provider '${providerName} has not been registered.'`)
    return provider()
}

export const resolveDescriptors = (providers, editorConfigProviderName): GenericEditorDescriptor[] => {
    return resolveEditorConfig(providers, editorConfigProviderName).descriptors
}

export const resolveEditorDescriptor = (providers: any, descriptorProviderName: string, params: any): GenericEditorEditor => {
    const { match, path } = params.selectedNode
    const configDescriptors = resolveDescriptors(providers, descriptorProviderName)
    const descriptor = descriptorByMatch(configDescriptors, match)
    const { editor } = descriptor
    return editor
}


export const setBusyIfNotEditing = (context, mapperResult) => {
    const loadState: string = pathOr("init", ["params", "loadState"], context)
    return assoc("isBusy", loadState !== "editing" && loadState !== "dataLoadError", mapperResult)
}

export const showRunCommandResult = curry((providers: any, editorConfig: GenericEditorConfig, commandData, result) => {
    return new Promise((resolve, reject) => {
        const { commandName } = commandData
        const enityName = editorConfig.getEntityName(commandData.editing)
        const entityDescription = editorConfig.entityDescription
        if (result.statusCode === 200) {
            providers.notify(`Success`, `${entityDescription}: '${enityName}'`, { type: "success", onClose: resolve })

        } else if (result.statusCode === 405) {
            providers.notify(`Error: Library is Read Only`, `${entityDescription}: '${enityName}'`, { type: "warning", onClose: resolve })

        } else {
            const message = defaultTo(
                `${entityDescription}: '${enityName}'`,
                path(["body"], result)
            )
            providers.notify(`Error ${commandName}`, message, { type: "warning", onClose: resolve, sticky: true })

        }
    })
})



const runCommandInternal = (nonBlocking) => (
    providers: any,
    descriptorProviderName: any,
    context,
    commandName: string,
    commandParams: any,
    cb: (editorConfig: GenericEditorConfig, commandData, result) => void
) => {
    const editorConfig = resolveEditorConfig(providers, descriptorProviderName)
    const editing = context.params.editingDashBoard
    const loadedData = context.params.loadedData
    const { libraryEntryType } = editorConfig
    const libraryId = context.params.dashBoardId
    const addtionalEntryProperties = editorConfig.resolveAdditionalEntryProperties(editing)
    const commandData = { editing, addtionalEntryProperties, loadedData, commandName, libraryId, libraryEntryType, commandParams }
    const commandProviderName = nonBlocking ? "dataSourceAsyncCommand" : "dataSourceCommand"
    providers[commandProviderName]("command", commandData, (r) => cb(editorConfig, commandData, r))
}


export const runCommand = runCommandInternal(false)

export const runCommandNonBlocking = runCommandInternal(true)

export const ChainedActionItem = (actionItems: GenericEditorActionItem[]): GenericEditorActionItem => {
    if (isArray(actionItems) && actionItems.length > 0) {
        const firstActionItem = head(actionItems)
        return assoc(
            "actionHandler",
            async (args: GenericEditorActionItemHandlerArgs): Promise<any> => {
                const chainResults = []
                let lastResult
                for (var actionItem of actionItems) {
                    const handlerArgs = assoc("chainResults", chainResults, args)
                    lastResult = await actionItem.actionHandler(handlerArgs)
                    chainResults.push(lastResult)
                }
                return lastResult
            },
            firstActionItem
        )
    }
    throw new Error("The actionItems parameter must contain at least one item.")
}

export const resolveEditorAlertStyles = (theme: Theme, alert: GenericEditorAlert) => {
    const { type } = alert
    const { indicatorColors } = theme
    const color = indicatorColors[type]
    const iconClassName = {
        error: "ri-error-warning-fill",
        info: "ri-information-fill",
        warning: "ri-error-warning-fill"
    }[type]
    return { iconClassName, color }
}
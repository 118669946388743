import { SennenAuthVariables } from "./SennenAuthVariables"
import { objectToQueryString, queryStringAsObject } from "../../helpers/url"
import { has } from "ramda"

export class SennenAuthClientService {

    public static async getToken(variables: SennenAuthVariables) {
        const query = queryStringAsObject()
        if (has("token", query)) {
            return query.token
        } else {
            throw new Error("No 'token' found")
        }
    }

    public static login(variables: SennenAuthVariables) {
        const urlParams = {
            "response-type": "post",
            "response-url": `${window.location.protocol}//${window.location.host}/authentication/login/`
        }
        window.location.href = `${variables.baseUrl}/login?${objectToQueryString(urlParams)}`
    }

    public static logout(variables: SennenAuthVariables) {
        const urlParams = {
            "response-url": `${window.location.protocol}//${window.location.host}/authentication/logout/`
        }
        window.location.href = `${variables.baseUrl}/logout?${objectToQueryString(urlParams)}`
    }

} 
import * as R from "ramda"
import { isNilOrEmpty, UTCDateTimeRegex, UTCTimeRegex, formatUTC, UTCDateRegex, formatUTCInZoneFactory } from "./dateTimeUtils"
import { isContext } from "../../helpers/isContext"
import { validDateFormats } from "./SennenDate"
import { FormatOptions, validDateTimeFormats } from "./SennenDateTime"
import { validTimeFormats } from "./SennenTime"

export const formatDateTime = (context: any, format: string, dateTimeUTC: string, options: FormatOptions = {}) => {

    const { emptyValue = "", timezone } = options
    const formatUTCInZone = formatUTCInZoneFactory({})

    if (!isContext(context)) return "context not passed"
    if (isNilOrEmpty(dateTimeUTC)) return emptyValue
    if (!R.is(String, dateTimeUTC)) return `Invalid DateTime`
    if (!UTCDateTimeRegex.test(dateTimeUTC)) return `Invalid UTC DateTime`
    if (!validDateTimeFormats.includes(format)) return `Invalid format`

    return formatUTCInZone(context, dateTimeUTC, format, timezone)
}

export const formatDate = (context: any, format: string, dateISO: string, options: FormatOptions = {}) => {

    const { emptyValue = "" } = options

    if (!isContext(context)) return "context not passed"
    if (isNilOrEmpty(dateISO)) return emptyValue
    if (!R.is(String, dateISO)) return `Invalid ISO Date`
    if (!UTCDateRegex.test(dateISO)) return `Invalid ISO Date`
    if (!validDateFormats.includes(format)) return `Invalid format`

    return formatUTC(context, dateISO, format)
}

export const formatTime = (context: any, format: string, timeISO: string, options: FormatOptions = {}) => {

    const { emptyValue = "" } = options

    if (!isContext(context)) return "context not passed"
    if (isNilOrEmpty(timeISO)) return emptyValue
    if (!R.is(String, timeISO)) return `Invalid ISO Time`
    if (!UTCTimeRegex.test(timeISO)) return `Invalid ISO Time`
    if (!validTimeFormats.includes(format)) return `Invalid format`

    return formatUTC(context, timeISO, format)
}
import { JsonSnippetInsertOptions } from "../../../actionItems/JsonInsertSnippetActionItem"
import { GenericEditorActionItemHandlerArgs, GenericEditorActionItem } from "../../../genericEditor/GenericEditorConfigProviderInterfaces"
import { confirmP } from "./helpers"
import * as R from "ramda"
import { CreatePacker, UnPackedFunction } from "@sennen/js-function-packer"
const { pack, unpack } = CreatePacker("DashBoardFunction")

export const addDataSourceSubscriptionInputMapperSnippet: JsonSnippetInsertOptions = {
    name: "add-ds-sub-input-mapper",
    buttonText: "Input Mapper",
    buttonIconClassName: "fa fa-plus",
    insertLocation: "child",
    insertPath: ["inputMappers"],
    insertMode: "append",
    template: async data => {
        return {
            "type": "FunctionMapper",
            "properties": {
                "function": pack([{
                    name: "main",
                    value: "() => {\n   // Function Mapper Code \n   // returning false supresses server-side subscriptions \n   return false\n}"
                }])
            }
        }
    }
}

export const deleteNode = (
    typeName: string,
    idPropPath: Array<string | number>
): GenericEditorActionItem => {
    return {
        actionType: "button",
        actionOptions: {
            name: `delete-${typeName}`,
            text: "Delete",
            type: "system",
            align: "left",
            iconClassName: "fa fa-trash",
            minWidth: "auto",
            divider: "left"
        },
        actionHandler: async (args: GenericEditorActionItemHandlerArgs) => {
            const { providers, data, nodeData } = args
            const nodePath = R.path(["node", "parsedPath"], args) as any
            const id = R.path(idPropPath, nodeData)
            const confirmRes = await confirmP(providers, `Are you sure you want to delete ${typeName} '${id}'?`)
            if (confirmRes) {
                return R.dissocPath(nodePath, data)
            }
            return null
        }
    }
}

export const moveNodeUp = (
    typeName: string
): GenericEditorActionItem => {
    return {
        actionType: "button",
        actionOptions: {
            name: `move-up-${typeName}`,
            text: "Move Up"
        },
        actionHandler: async (args: GenericEditorActionItemHandlerArgs) => {
            const { providers, data, nodeData } = args
            const nodePath = R.path(["node", "parsedPath"], args) as any
            const arrayPath = R.init(nodePath) as any
            const index = parseInt(R.last(nodePath), 10)
            if (index > 0) {
                const array = R.path(arrayPath, data) as any
                const item = array[index]
                const deletedArray = R.remove(index, 1, array)
                const insertedArray = R.insert(index - 1, item, deletedArray)
                return R.assocPath(arrayPath, insertedArray, data)
            }
            return data
        }
    }
}

export const moveNodeDown = (
    typeName: string
): GenericEditorActionItem => {
    return {
        actionType: "button",
        actionOptions: {
            name: `move-down-${typeName}`,
            text: "Move Down"
        },
        actionHandler: async (args: GenericEditorActionItemHandlerArgs) => {
            const { providers, data, nodeData } = args
            const nodePath = R.path(["node", "parsedPath"], args) as any
            const arrayPath = R.init(nodePath) as any
            const index = parseInt(R.last(nodePath), 10)
            const array = R.path(arrayPath, data) as any
            const item = array[index]
            const deletedArray = R.remove(index, 1, array)
            const insertedArray = R.insert(index + 1, item, deletedArray)
            return R.assocPath(arrayPath, insertedArray, data)
        }
    }
}
import { ProviderDefinition } from "@sennen/dashboards-interfaces"
import { assertContext } from "../../helpers/isContext"
import { PREFER_DEV_VERSION_DEFAULT } from "./DashBoardEditorShared"
import * as R from "ramda"

export const DashBoardEditorProvider: ProviderDefinition = {
    name: "dashBoardEditor",
    causesMutation: false,
    createMethod: (options): any => {
        const { ClientApp } = options

        return {
            getPreferDevVersion: (context): Boolean => {
                assertContext(context)
                return R.defaultTo(
                    PREFER_DEV_VERSION_DEFAULT,
                    R.path(
                        ["state", "__requestContextParams", "dashBoardEditor", "preferDevVersion"],
                        context
                    )
                )
            },

            setPreferDevVersion: (preferDevVersion: boolean) => {
                ClientApp.socketManager.emit(
                    "app:updateRequestContextParams",
                    { dashBoardEditor: { setPreferDevVersion: preferDevVersion } }
                )
            }

        }
    }
}

import * as React from "react"
import * as ReactDOM from "react-dom"
import { MessageServiceListener } from "@sennen/dashboards-interfaces"
import { Theme, ComponentRender, ClientApp } from "@sennen/dashboards-react-client"
import { ThemedComponentDecorator } from "@sennen/dashboards-react-component"
import { styles } from "./DashBoardDebugOverlayStyles"
import debounce from "lodash.debounce"
import * as R from "ramda"
import { trackElementPosition } from "./DashBoardDebugOverlayFuncs"

export type formControlEvent = (controlId: string, value: any) => void

export interface DashBoardDebugOverlayProps {
    id: string
    theme?: Theme
    componentRender?: ComponentRender
}

type Rect = { x: number, y: number, width: number, height: number }

const getRootElement = () =>
    document.documentElement.querySelector("body div.rootContainer") || document.documentElement

const renderDashBoardRootPortal = (element: JSX.Element) =>
    ReactDOM.createPortal(element, getRootElement())

const getRootElementRect = () => getRootElement().getBoundingClientRect()

const isDev = ({ id }) => R.endsWith(":999999", id)

const constrainToContainer = (container: Rect, rect: Rect) => {
    const { width, height } = container
    const x = R.clamp(0, width, rect.x)
    const y = R.clamp(0, height, rect.y)
    return {
        x, y,
        width: R.clamp(0, width - x, rect.width),
        height: R.clamp(0, height - y, rect.height)
    }
}

const padRect = (rect: DOMRect, paddingX: number, paddingY: number): Rect => {
    return {
        x: rect.x - paddingX,
        y: rect.y - paddingY,
        width: rect.width + 2 * paddingX,
        height: rect.height + 2 * paddingY
    }
}

@ThemedComponentDecorator(styles)
export class DashBoardDebugOverlay extends React.Component<DashBoardDebugOverlayProps, any> {

    private constructedListener: MessageServiceListener
    private destroyedListener: MessageServiceListener
    private registeredDevContainers: { [uid: string]: { element: HTMLElement, dashBoardId: string, trackerRef: () => void, visible: boolean, uid: string } } = {}

    constructor(props) {
        super(props)
        this.state = { refreshId: 0 }
    }

    componentDidMount(): void {
        const { globalMessageService } = ClientApp

        this.constructedListener = globalMessageService.listen(
            "@@dashBoardContainer::constructed",
            ({ uid, config = { id: "" }, element }) => {
                try {
                    if (isDev(config)) {
                        this.registeredDevContainers[uid] = {
                            uid,
                            element,
                            dashBoardId: config?.id,
                            visible: true,
                            trackerRef: trackElementPosition(element, () => this.debouncedUpdate(), 100)
                        }
                        this.debouncedUpdate()
                    }
                } catch (e) {
                    console.error("Error in DashBoardDebugOverlay - constructed event", e)
                }
            }
        )

        this.destroyedListener = globalMessageService.listen(
            "@@dashBoardContainer::destroyed",
            ({ uid }) => {
                try {
                    const container = this.registeredDevContainers[uid]
                    if (container) {
                        container.trackerRef?.()
                        delete this.registeredDevContainers[uid]
                        this.debouncedUpdate()
                    }
                } catch (e) {
                    console.error("Error in DashBoardDebugOverlay - destroyed event", e)
                }
            }
        )
    }

    componentWillUnmount(): void {
        this.constructedListener?.cancel()
        this.destroyedListener?.cancel()
    }

    render(): React.ReactNode {
        const { theme } = this.props

        const areVisible = R.filter(({ element }) => element.checkVisibility(), this.registeredDevContainers)

        const others = R.map(
            ({ uid, element, visible }) => {

                const glyphWidth = 24
                const glyphHeight = 20
                const margin = 2

                const paddedRect = padRect(element.getBoundingClientRect(), margin, margin + glyphHeight)
                const pos = constrainToContainer(getRootElementRect(), paddedRect)

                const overlayStyles = {
                    position: "absolute",
                    top: pos.y + glyphHeight,
                    left: pos.x,
                    width: pos.width,
                    height: pos.height - glyphHeight * 2,
                } as React.CSSProperties

                const handleStyles = {
                    top: pos.y,
                    left: pos.x + pos.width - glyphWidth,
                    width: glyphWidth,
                    height: glyphHeight
                } as React.CSSProperties

                const hiddedClass = visible ? "" : " dashboard-editor-debug--hidden"
                const handleIcon = visible ? "ri-lg ri-file-pdf-fill" : "ri-lg ri-eye-off-fill"

                return renderDashBoardRootPortal([
                    <div className={`dashboard-editor-debug-handle${hiddedClass}`} style={handleStyles} onClick={() => this.toggleVisibility(uid)}>
                        <i className={handleIcon}></i>
                    </div>,
                    <div className={`dashboard-editor-debug-overlay${hiddedClass}`} style={overlayStyles} ></div>
                ] as any)

            },
            R.values(areVisible)
        )

        return [
            renderDashBoardRootPortal(<style type="text/css"> {styles(theme).css} </style>),
            ...others
        ]
    }

    private toggleVisibility = (uid: string) => {
        const container = this.registeredDevContainers[uid]
        if (container) {             
            container.visible = !container.visible
            this.debouncedUpdate()
        }
    }

    private debouncedUpdate = debounce(
        () => this.setState({ refreshId: this.state.refreshId + 1 }),
        100, { leading: false, trailing: true }
    )

}


import { DateTime, Duration } from "luxon"
import * as R from "ramda"
import { formatUTC, getTimezone, isNilOrEmpty, UTCDateRegex, getLocaleZonedDateTime, diffToHumanFixed, getLocaleDateTime } from "./dateTimeUtils"
import { isContext } from "../../helpers/isContext"
import { FormatOptions, RelativeCalendarOptions } from "./SennenDateTime"
import { timeUnits } from "./SennenTime"

export interface DateDurationObject {
    year?: number;
    quarter?: number;
    month?: number;
    week?: number;
    day?: number;
}

export interface DateDurationObjectPlural {
    years?: number;
    quarters?: number;
    months?: number;
    weeks?: number;
    days?: number;
}

export type DateDurationKeys = keyof DateDurationObject
export type DateDurationKeysPlural = keyof DateDurationObjectPlural

export type DiffHumanOptions = FormatOptions & {
    minUnit?: DateDurationKeysPlural
    maxUnit?: DateDurationKeysPlural
}

export const validDateFormats = [
    "DATE_SHORT",
    "DATE_MED",
    "DATE_MED_WITH_WEEKDAY",
    "DATE_FULL",
    "DATE_HUGE",
]

export const validateIsoDate = (context: any, dateISO: string): { valid: boolean, errorString?: string } => {
    if (!isContext(context)) return { valid: false, errorString: "Context not passed" }
    if (!R.is(String, dateISO)) return { valid: false, errorString: `Invalid ISO Date` }
    if (!UTCDateRegex.test(dateISO)) return { valid: false, errorString: `Invalid ISO Date` }
    return { valid: true }
}

const validUnitsForArithmetic = (durationObject: any) => R.pipe(R.keys, R.all(u => !timeUnits.includes(u)))(durationObject)

export const SennenDate = (context: any, dateISO?: string) => {
    const { valid, errorString } = validateIsoDate(context, dateISO)

    const toRelativeFunc = (funcName) => (options: RelativeCalendarOptions = {}) => {
        // We default the baseISO to nowAsDate otherwise relative
        // calculations take into account the current time
        // which is incorrect when dealing exclusively with dates
        const nowAsDate = SennenDate(context).now().toISO()
        const { emptyValue = "", timezone, baseISO = nowAsDate } = options
        const relativeOptions = {}

        if (isNilOrEmpty(dateISO)) return emptyValue
        if (!valid) return errorString
        const dt = getLocaleZonedDateTime(context, dateISO, timezone)

        if (!isNilOrEmpty(baseISO)) {
            let { valid, errorString } = validateIsoDate(context, baseISO)
            if (!valid) return `baseISO: ${errorString}`
            relativeOptions["base"] = getLocaleZonedDateTime(context, baseISO, timezone)
        }

        return dt[funcName]({
            ...relativeOptions,
            unit: funcName === "toRelative" ? ["years", "months", "weeks", "days"] : undefined
        })
    }

    const sennenDate = {
        isValid: valid,

        //zone to get correct date
        now: () => SennenDate(context, DateTime.utc().setZone(getTimezone(context)).toISODate()),

        toISO: () => {
            if (!valid) throw new Error(errorString)
            return DateTime.fromISO(dateISO).toISODate()
        },

        format: (format: string, options: FormatOptions = {}) => {
            const { emptyValue = "" } = options

            if (isNilOrEmpty(dateISO)) return emptyValue
            if (!valid) return errorString
            if (!validDateFormats.includes(format)) return `Invalid Format`

            return formatUTC(context, dateISO, format)
        },

        toRelativeCalendar: toRelativeFunc("toRelativeCalendar"),

        toRelative: toRelativeFunc("toRelative"),

        get: (unit: keyof DateTime) => {
            const res = DateTime.fromISO(dateISO).get(unit)
            if (R.is(Function, res)) throw new Error(`Getting ${unit} is not permitted as it is a Function.`)
            return res
        },

        diff: (otherDateISO: string, diff: DateDurationKeysPlural | DateDurationKeysPlural[]): Duration => {
            if (!valid) throw new Error(errorString)
            return DateTime.fromISO(dateISO).diff(DateTime.fromISO(otherDateISO), diff)
        },

        diffHuman: (otherDateISO: string, options: DiffHumanOptions = {}) => {
            const { emptyValue = "", minUnit = "days", maxUnit = "years" } = options

            if (isNilOrEmpty(dateISO) || isNilOrEmpty(otherDateISO)) return emptyValue
            if (!valid) return errorString
            const dt = getLocaleDateTime(context, dateISO)

            const { errorString: otherErrorString, valid: otherValid } = validateIsoDate(context, otherDateISO)
            if (!otherValid) return `otherDateTimeISO: ${otherErrorString}`
            const otherDt = getLocaleDateTime(context, otherDateISO)

            return diffToHumanFixed(dt, otherDt, minUnit, maxUnit, { unitDisplay: "short" } as any)

        },

        isAfter: (otherDateISO: string): boolean => {
            if (!valid) throw new Error(errorString)
            return DateTime.fromISO(dateISO) > DateTime.fromISO(otherDateISO)
        },

        isBefore: (otherDateISO: string): boolean => {
            if (!valid) throw new Error(errorString)
            return DateTime.fromISO(dateISO) < DateTime.fromISO(otherDateISO)
        },

        equals: (otherDateISO: string): boolean => {
            if (!valid) throw new Error(errorString)
            return DateTime.fromISO(dateISO).equals(DateTime.fromISO(otherDateISO))
        },

        plus: (durationObject: DateDurationObject | DateDurationObjectPlural) => {
            if (!validUnitsForArithmetic(durationObject)) throw new Error("You cannot apply time operations to a Date")
            if (!valid) return sennenDate
            return SennenDate(context, DateTime.fromISO(dateISO).plus(durationObject).toISODate())
        },

        minus: (durationObject: DateDurationObject | DateDurationObjectPlural) => {
            if (!validUnitsForArithmetic(durationObject)) throw new Error("You cannot apply time operations to a Date")
            if (!valid) return sennenDate
            return SennenDate(context, DateTime.fromISO(dateISO).minus(durationObject).toISODate())
        },

        startOf: (durationUnit: DateDurationKeys) => {
            if (timeUnits.includes(durationUnit)) throw new Error("Invalid startOf unit for a Date")
            if (!valid) return sennenDate
            return SennenDate(context, DateTime.fromISO(dateISO).startOf(durationUnit).toISODate())
        },

        endOf: (durationUnit: DateDurationKeys) => {
            if (timeUnits.includes(durationUnit)) throw new Error("Invalid endOf unit for a Date")
            if (!valid) return sennenDate
            return SennenDate(context, DateTime.fromISO(dateISO).endOf(durationUnit).toISODate())
        }
    }

    return sennenDate
}
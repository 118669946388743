import { toCss } from "@sennen/dashboards-react-client"
import { mergeDeepRight } from "ramda"
export const staticDefaults = (chartTheme) => {
    return {
        title: {
            text: "",
            style: {
                fontFamily: chartTheme.titleFont.family,
                color: chartTheme.titleFontColor,
                fontWeight: chartTheme.titleFont.weight,
                fontSize: toCss(chartTheme.titleFont.size)
            }
        },
        subtitle: {
            style: {
                fontFamily: chartTheme.subtitleFont.family,
                color: chartTheme.subtitleFontColor,
                fontWeight: chartTheme.subtitleFont.weight,
                fontSize: toCss(chartTheme.subtitleFont.size)
            }
        },
        chart: {
            backgroundColor: null,
            width: null,
            height: null,
            style: {
                fontFamily: chartTheme.font
            }
        },
        plotOptions: {
            series: {
                dataLabels:{
                    style:{
                        textOutline: "",
                        color: chartTheme.dataLabelColor
                    }
                },
                label: {
                    enabled: false
                }
            }
        },
        legend: {
            itemStyle: {
                color: chartTheme.legendTextColor
            }
        },
        exporting: {
            enabled: false

        },
        credits: false,
        colors: chartTheme.palette
    }
}

export const multiDefaults = (chartTheme) => {
    return {
        xAxis: {
            gridLineColor: chartTheme.gridLineColor,
            labels: {
                style: {
                    color: chartTheme.axisLabelColor
                }
            },
            title: {
                style: {
                    color: chartTheme.axisTitleColor
                }
            },
            lineColor: chartTheme.axisLabelColor,
            color: chartTheme.axisLabelColor,
            tickColor: chartTheme.axisLabelColor
        },
        yAxis: {
            gridLineColor: chartTheme.gridLineColor,
            stackLabels:{
                style:{
                    textOutline: "",
                    color: chartTheme.dataLabelColor
                }
            },
            labels: {
                style: {
                    color: chartTheme.axisLabelColor
                }
            },
            title: {
                style: {
                    color: chartTheme.axisTitleColor
                }
            },
            lineColor: chartTheme.axisLabelColor,
            color: chartTheme.axisLabelColor,
            tickColor: chartTheme.axisLabelColor
        },
    }
}

 
import {
    genericPropGetter,
    genericPropSetter
} from "../../../helpers/formGetterSetter"

import {
    qtDatePropGetter,
    qtDatePropSetter,
    qtDateTimePropGetter,
    qtDateTimePropSetter,
    qtBooleanPropGetter,
    qtBooleanPropSetter,
    qtMultiSelectPropGetter,
    qtMultiSelectPropSetter
} from "./QuickTracksFormGetterSetter"

import { equals, cond, always, T } from "ramda"

// Resolvers
export const resolveGetter = cond([
    [equals("multiSelect"), always(qtMultiSelectPropGetter)],
    [equals("checkbox"), always(qtBooleanPropGetter)],
    [equals("dateTime"), always(qtDateTimePropGetter)],
    [equals("date"), always(qtDatePropGetter)],
    [T, always(genericPropGetter)]
])

export const resolveSetter = cond([
    [equals("multiSelect"), always(qtMultiSelectPropSetter)],
    [equals("checkbox"), always(qtBooleanPropSetter)],
    [equals("dateTime"), always(qtDateTimePropSetter)],
    [equals("date"), always(qtDatePropSetter)],
    [T, always(genericPropSetter)]
])
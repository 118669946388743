import { DateTime, Duration, IANAZone } from "luxon"
import * as R from "ramda"
import {
    getTimezone,
    getLocaleZonedDateTime,
    UTCDateTimeRegex,
    isNilOrEmpty,
    diffToHumanFixed,
    getLocaleDateTime,
    formatUTCInZoneFactory
} from "./dateTimeUtils"
import { isContext } from "../../helpers/isContext"
import { DateDurationObject, DateDurationObjectPlural, validateIsoDate, validDateFormats } from "./SennenDate"
import { validTimeFormats } from "./SennenTime"

export type FormatOptions = {
    emptyValue?: string,
    timezone?: string,
    includeMilliseconds?: boolean
}

export type RelativeCalendarOptions = FormatOptions & {
    baseISO?: string
}

export type DateTimeOptions = {
    timezone?: string
}

export type DiffHumanOptions = FormatOptions & {
    minUnit?: DateTimeDurationKeysPlural
    maxUnit?: DateTimeDurationKeysPlural
}

export interface DateTimeDurationObject extends DateDurationObject {
    hour?: number;
    minute?: number;
    second?: number;
    millisecond?: number;
}

export interface DateTimeDurationObjectPlural extends DateDurationObjectPlural {
    hours?: number;
    minutes?: number;
    seconds?: number;
    milliseconds?: number;
}

export type DateTimeDurationKeys = keyof DateTimeDurationObject
export type DateTimeDurationKeysPlural = keyof DateTimeDurationObjectPlural

const customDateTimeFormats = {
    DATETIME_SHORT_24: "D T",
    DATETIME_SHORT_24_WITH_SECONDS: "D TT",
    DATETIME_MED_24: "DD T",
    DATETIME_MED_24_WITH_SECONDS: "DD TT"
}

export const validDateTimeFormats = [
    ...validDateFormats,
    ...validTimeFormats,
    "DATETIME_SHORT",
    "DATETIME_SHORT_WITH_SECONDS",
    "DATETIME_MED",
    "DATETIME_MED_WITH_SECONDS",
    "DATETIME_MED_WITH_WEEKDAY",
    ...Object.keys(customDateTimeFormats)
]

const supportedRoundingKeys: DateTimeDurationKeys[] = ["millisecond", "second", "minute", "hour"]

const formatUTCInZone = formatUTCInZoneFactory(customDateTimeFormats)

const validate = (context: any, dateTimeISO: string, options: DateTimeOptions = {}): { valid: boolean, errorString?: string } => {
    if (!isContext(context)) return { valid: false, errorString: "Context not passed" }
    if (!R.is(String, dateTimeISO)) return { valid: false, errorString: "Invalid DateTime" }
    if (!UTCDateTimeRegex.test(dateTimeISO)) return { valid: false, errorString: "Invalid UTC ISO DateTime" }
    if (R.has("timezone", options)) {
        const timezoneObj = new IANAZone(options.timezone)
        if (!timezoneObj.isValid) return { valid: false, errorString: "Invalid Timezone" }
    }
    return { valid: true }
}

export const SennenDateTime = (context: any, dateTimeISO?: string) => {
    let { valid, errorString } = validate(context, dateTimeISO)

    const toRelativeFunc = (funcName) => (options: RelativeCalendarOptions = {}) => {
        const { emptyValue = "", baseISO, timezone } = options
        const relativeOptions = {}

        if (isNilOrEmpty(dateTimeISO)) return emptyValue
        if (!valid) return errorString
        const dt = getLocaleZonedDateTime(context, dateTimeISO, timezone)

        if (!isNilOrEmpty(baseISO)) {
            let { valid, errorString } = validate(context, baseISO)
            if (!valid) return `baseISO: ${errorString}`
            relativeOptions["base"] = getLocaleZonedDateTime(context, baseISO, timezone)
        }
        return dt[funcName](relativeOptions)
    }

    const sennenDateTime = {
        isValid: valid,

        now: () => SennenDateTime(context, DateTime.utc().toISO()),

        fromLocalDate: (dateISO: string, options: DateTimeOptions = {}) => {
            const validationResult = validateIsoDate(context, dateISO)
            if (validationResult.valid === false) {
                valid = false
                errorString = validationResult.errorString
                dateTimeISO = "ERROR"
                return sennenDateTime
            }
            return SennenDateTime(
                context,
                DateTime.fromFormat(dateISO, "yyyy-MM-dd", { zone: options.timezone ?? getTimezone(context) }).setZone("utc").toISO() ?? "ERROR"
            )
        },

        toISO: () => {
            if (!valid) throw new Error(errorString)
            return dateTimeISO
        },

        toRelativeCalendar: toRelativeFunc("toRelativeCalendar"),

        toRelative: toRelativeFunc("toRelative"),

        toLocalISODate: (options: DateTimeOptions = {}) => {
            if (!valid) throw new Error(errorString)
            return DateTime.fromISO(dateTimeISO).setZone(options.timezone ?? getTimezone(context)).toISODate()
        },

        get: (unit: keyof DateTime, options: DateTimeOptions = {}) => {
            const res = DateTime.fromISO(dateTimeISO).setZone(options.timezone ?? getTimezone(context)).get(unit)
            if (R.is(Function, res)) throw new Error(`Getting ${unit} is not permitted as it is a Function.`)
            return res
        },

        toUnixMilliseconds: (): number => {
            if (!valid) return null
            return DateTime.fromISO(dateTimeISO).toUTC().toJSDate().getTime()
        },

        format: (format: string, options: FormatOptions = {}) => {
            const { emptyValue = "", timezone, includeMilliseconds } = options

            if (isNilOrEmpty(dateTimeISO)) return emptyValue
            if (!valid) return errorString
            if (R.has("timezone", options)) {
                const timezoneObj = new IANAZone(timezone)
                if (!timezoneObj.isValid) return "Invalid Timezone"
            }
            if (!validDateTimeFormats.includes(format)) return `Invalid Format`


            const formattedDateTime = formatUTCInZone(context, dateTimeISO, format, timezone)
            if (includeMilliseconds) {
                const milliseconds = String(DateTime.fromISO(dateTimeISO).get("millisecond")).padStart(3, '0')
                return `${formatUTCInZone(context, dateTimeISO, format, timezone)}.${milliseconds}`
            }
            else {
                return formattedDateTime
            }

        },

        diff: (otherDateTimeISO: string, diff: DateTimeDurationKeysPlural | DateTimeDurationKeysPlural[]): Duration => {
            return DateTime.fromISO(dateTimeISO).diff(DateTime.fromISO(otherDateTimeISO), diff)
        },

        diffHuman: (otherDateTimeISO: string, options: DiffHumanOptions = {}) => {
            const { emptyValue = "", minUnit = "seconds", maxUnit = "years" } = options

            if (isNilOrEmpty(dateTimeISO) || isNilOrEmpty(otherDateTimeISO)) return emptyValue
            if (!valid) return errorString
            const dt = getLocaleDateTime(context, dateTimeISO)

            const { errorString: otherErrorString, valid: otherValid } = validate(context, otherDateTimeISO)
            if (!otherValid) return `otherDateTimeISO: ${otherErrorString}`
            const otherDt = getLocaleDateTime(context, otherDateTimeISO)

            return diffToHumanFixed(dt, otherDt, minUnit, maxUnit, { unitDisplay: "short" } as any)
        },

        isAfter: (otherDateTimeISO: string): boolean => {
            return DateTime.fromISO(dateTimeISO) > DateTime.fromISO(otherDateTimeISO)
        },

        isEqualOrAfter: (otherDateTimeISO: string): boolean => {
            return DateTime.fromISO(dateTimeISO) >= DateTime.fromISO(otherDateTimeISO)
        },

        isBefore: (otherDateTimeISO: string): boolean => {
            return DateTime.fromISO(dateTimeISO) < DateTime.fromISO(otherDateTimeISO)
        },

        isEqualOrBefore: (otherDateTimeISO: string): boolean => {
            return DateTime.fromISO(dateTimeISO) <= DateTime.fromISO(otherDateTimeISO)
        },

        equals: (otherDateTimeISO: string): boolean => {
            return DateTime.fromISO(dateTimeISO).equals(DateTime.fromISO(otherDateTimeISO))
        },

        plus: (durationObject: DateTimeDurationObject | DateTimeDurationObjectPlural) => {
            if (!valid) return sennenDateTime
            return SennenDateTime(context, DateTime.fromISO(dateTimeISO).toUTC().plus(durationObject).toISO())
        },

        minus: (durationObject: DateTimeDurationObject | DateTimeDurationObjectPlural) => {
            if (!valid) return sennenDateTime
            return SennenDateTime(context, DateTime.fromISO(dateTimeISO).toUTC().minus(durationObject).toISO())
        },

        startOf: (durationUnit: DateTimeDurationKeys, quantity: number = 1) => {
            if (!valid) return sennenDateTime
            if (quantity === 1) {
                return SennenDateTime(context, DateTime.fromISO(dateTimeISO).toUTC().startOf(durationUnit).toISO())
            } else {
                if (!supportedRoundingKeys.includes(durationUnit)) throw new Error(`${durationUnit} does not support quantity > 1`)
                const current = DateTime.fromISO(dateTimeISO).toUTC().startOf(durationUnit).get(<keyof DateTime>durationUnit)
                const rounded = Math.floor(current / quantity) * quantity
                const result = DateTime.fromISO(dateTimeISO).toUTC().set({ [durationUnit]: rounded }).startOf(durationUnit).toISO()
                return SennenDateTime(context, result)
            }
        },

        endOf: (durationUnit: DateTimeDurationKeys, quantity: number = 1) => {
            if (!valid) return sennenDateTime
            if (quantity === 1) {
                return SennenDateTime(context, DateTime.fromISO(dateTimeISO).toUTC().endOf(durationUnit).toISO())
            } else {
                if (!supportedRoundingKeys.includes(durationUnit)) throw new Error(`${durationUnit} does not support quantity > 1`)
                const current = DateTime.fromISO(dateTimeISO).toUTC().endOf(durationUnit).get(<keyof DateTime>durationUnit)
                const rounded = Math.ceil(current / quantity) * quantity - 1
                const result = DateTime.fromISO(dateTimeISO).toUTC().set({ [durationUnit]: rounded }).endOf(durationUnit).toISO()
                return SennenDateTime(context, result)
            }
        }
    }

    return sennenDateTime
}
import {
    GenericEditorActionItem,
    GenericEditorActionItemHandlerArgs
} from "../genericEditor/GenericEditorConfigProviderInterfaces"

export interface JsonSnippetMethodResults {
    templateDataResult?: any
    templateResult?: any
    updateHandlerResult?: any
    afterUpdateHandlerResult?: any
}

export interface JsonSnippetOptions {
    name: string
    buttonText: string
    buttonIconClassName?: string
    templateDataResolver: (args: GenericEditorActionItemHandlerArgs, results: JsonSnippetMethodResults) => Promise<any>
    templateResolver: (args: GenericEditorActionItemHandlerArgs, results: JsonSnippetMethodResults) => Promise<any>
    updateHandler: (args: GenericEditorActionItemHandlerArgs, results: JsonSnippetMethodResults) => Promise<any>
    afterUpdateHandler?: (args: GenericEditorActionItemHandlerArgs, results: JsonSnippetMethodResults) => Promise<any>
}

export const JsonSnippet = (options: JsonSnippetOptions): GenericEditorActionItem => {
    const { name, buttonText, buttonIconClassName, updateHandler, templateDataResolver, templateResolver, afterUpdateHandler } = options
    const actionHandler = async (args: GenericEditorActionItemHandlerArgs) => {
        const results: JsonSnippetMethodResults = {}
        try {
            results.templateDataResult = await templateDataResolver(args, results)
            results.templateResult = await templateResolver(args, results)
            results.updateHandlerResult = await updateHandler(args, results)
            results.afterUpdateHandlerResult = afterUpdateHandler ? afterUpdateHandler(args, results) : null
            return afterUpdateHandler ? results.afterUpdateHandlerResult : results.updateHandlerResult
        } catch (e) {
            console.warn("JSON snippet failure, reason:\n", e)
            throw (e)
        }
    }
    return {
        actionType: "button",
        actionOptions: { name, text: buttonText, iconClassName: buttonIconClassName, type: "system", minWidth: "auto" },
        actionHandler: actionHandler
    }
} 
import { Theme } from "@sennen/dashboards-react-client"
import {
    assoc, clone, compose, curry,
    dissocPath, keys, lensPath,
    map, mergeDeepRight, not, over,
    pick, reduce, toPairs, values
} from "ramda"
import * as React from "react"
import ReactHighcharts from "react-highcharts"
import { assocPathString, pathString } from "../helpers/path"
import deepEqual from "deep-equal-ignore-functions"
import debounce from "lodash.debounce"

import highchartsMore from "highcharts/js/highcharts-more"
import solidGauge from "highcharts/js/modules/solid-gauge"
import heatmap from "highcharts/js/modules/heatmap"
import seriesLabel from "highcharts/js/modules/series-label"
import exporting from "highcharts/js/modules/exporting"
import sunburst from "highcharts/js/modules/sunburst"
import sankey from "highcharts/js/modules/sankey"
import boost from "highcharts/js/modules/boost"

highchartsMore(ReactHighcharts.Highcharts)
solidGauge(ReactHighcharts.Highcharts)
heatmap(ReactHighcharts.Highcharts)
seriesLabel(ReactHighcharts.Highcharts)
exporting(ReactHighcharts.Highcharts)
sunburst(ReactHighcharts.Highcharts)
sankey(ReactHighcharts.Highcharts)
boost(ReactHighcharts.Highcharts)

const mapPossibleArray = curry((iterator, items) => {
    const isArray = Array.isArray(items)
    const outItems = map(iterator, isArray ? items : [items])
    return isArray ? outItems : outItems[0]
})

const EVENT_DEFINITIONS = {
    "chart.events.selection": {
        "actionName": "onSelection",
        "actionMapper": (args) => {
            return {
                xAxis: map(pick(["min", "max"]), args.options?.xAxis || []),
                yAxis: map(pick(["min", "max"]), args.options?.yAxis || [])
            }
        },
    },
    "plotOptions.series.point.events.click": {
        "actionName": "onPointClick",
        "actionMapper": (args) => {
            return {
                "category": pathString("options.point.category", args),
                "x": pathString("options.point.x", args),
                "y": pathString("options.point.y", args),
                "data": pathString("options.point.data", args)
            }
        },
    },
    "plotOptions.series.events.legendItemClick": {
        "actionName": "onLegendClick",
        "actionMapper": (args) => {
            const { chart, index } = args.this
            const { series = [] } = chart
            const seriesReduced = compose<any, any, any>(
                over(lensPath([index, "visible"]), not),
                map(pick(["visible", "name"]))
            )(series)
            const name = args.this.userOptions?.name
            return { series: seriesReduced, index, name }
        }
    }
}

export interface HighChartBaseProps {
    chart: any,
    theme: Theme,
    height?: number,
    width?: number,
    palette?: string[],
    autoSize?: boolean
}

interface HighChartBaseState { }

export abstract class HighChartBase extends React.Component<HighChartBaseProps, HighChartBaseState> {

    abstract getChartTheme(): any
    abstract mergeOptions(options: any, chartTheme: any): any
    abstract getStaticDefaults(chartTheme: any): any
    abstract getMultiDefaults(chartTheme: any): any

    private ref
    private chart
    private resizeObserver: ResizeObserver

    public HighChartsColor = ReactHighcharts.Highcharts.Color

    constructor(props) {
        super(props)
        this.state = {
            containerHeight: null
        }
    }

    public static defaultProps: HighChartBaseProps = {
        chart: {},
        theme: {},
        height: 160,
        palette: null
    }

    public palette: string[]

    shouldComponentUpdate(nextProps: HighChartBaseProps, nextState: HighChartBaseState) {
        return !deepEqual(this.props.chart, nextProps.chart)
    }

    private initResizeObserver(ref) {
        const { autoSize = false } = this.props
        if (autoSize) {
            if (ref !== this.ref && ref) {
                this.ref = ref
                this.resizeObserver = new ResizeObserver(
                    debounce(() => this.chart?.reflow(), 500)
                )
                this.resizeObserver.observe(ref)
            }
        } else {
            this.resizeObserver?.disconnect()
            this.ref = null
        }
    }

    private subscribeToEvents(config) {
        const context = this
        const eventNames = keys(EVENT_DEFINITIONS)
        return reduce(
            (cfg, eventName) => assocPathString(
                eventName,
                function (options) {
                    const { actionMapper, actionName } = EVENT_DEFINITIONS[eventName]
                    const actionArg = actionMapper({ options, this: this })
                    context.props[actionName]?.(actionArg)
                },
                cfg
            ),
            config,
            eventNames
        )
    }

    render() {
        const { autoSize = false, chart, palette } = this.props
        this.palette = palette || values(this.props.theme.palette)
        if (typeof chart === "undefined" || Object.keys(chart).length === 0) return null
        let chartOptions = this.mergeBaseChartOptions(clone(chart))
        chartOptions = this.subscribeToEvents(chartOptions)

        let domProps = {}
        if (autoSize) {
            domProps = { style: { width: "100%", height: "100%", position: "absolute" } }
            chartOptions.chart.width = null
            chartOptions.chart.height = null
        }

        return <ReactHighcharts
            config={chartOptions}
            domProps={domProps}
            isPureConfig={true}
            callback={(chart) => {
                this.chart = chart
                this.initResizeObserver(chart.container?.parentElement)
            }}
        >
        </ReactHighcharts>
    }

    private mergeBaseChartOptions(options) {
        const chartTheme = this.getChartTheme()
        const staticOpt = this.getStaticDefaults(chartTheme)
        const multiOpt = this.getMultiDefaults(chartTheme)
        const defaultOptions = this.setSize(mergeDeepRight(staticOpt, options))
        const opt = reduce((options, def) => {
            const key = def[0] as string
            const defaults = def[1] as any
            const val = options[key]
            const mergedItems = mapPossibleArray(x => mergeDeepRight(defaults, x || {}), val)
            return assoc(key, mergedItems, options)
        }, defaultOptions, toPairs(multiOpt))
        return this.mergeOptions(opt, chartTheme)
    }

    private setSize(staticOpt) {
        const { height, width } = this.props
        staticOpt = dissocPath(["chart", "height"], staticOpt)
        staticOpt = dissocPath(["chart", "width"], staticOpt)
        return mergeDeepRight(staticOpt, { chart: { height, width } })
    }

}
import { forEach, assoc, compose, map, curry, clone, any, until, merge, sortWith, ascend, descend, prop, dissoc, times, pick } from "ramda"

export interface ContainerCoords {
    colStart: number
    rowStart: number
    colEnd: number
    rowEnd: number
    items?: ContainerCoords[]
}

export interface ContainerRect {
    col: number
    row: number
    colSpan: number
    rowSpan: number
    items?: ContainerRect[]
}

export const toContainerRect = (coords: ContainerCoords): ContainerRect => {
    const { colStart, rowStart, colEnd, rowEnd } = ensureContainerCoords(coords)
    return {
        col: colStart,
        colSpan: colEnd - colStart,
        row: rowStart,
        rowSpan: rowEnd - rowStart
    }
}

export const toContainerCoords = (rect: ContainerRect): ContainerCoords => {
    const { col, row, colSpan, rowSpan } = ensureContainerRect(rect)
    return {
        colStart: col,
        colEnd: col + colSpan,
        rowStart: row,
        rowEnd: row + rowSpan
    }
}

export const ensureContainerRect = (rect: ContainerRect): ContainerRect => {
    return merge({ col: 0, colSpan: 0, row: 0, rowSpan: 0 }, rect)
}


export const ensureContainerCoords = (coords: ContainerCoords): ContainerCoords => {
    return merge({ colStart: 0, colEnd: 0, rowStart: 0, rowEnd: 0 }, coords)
}

export const calcContainer = (container: ContainerRect): ContainerRect => {
    const currentRect = toContainerCoords(container)
    forEach(child => {
        const { colStart, colEnd, rowStart, rowEnd } = toContainerCoords(calcContainer(child))
        if (colStart < currentRect.colStart) currentRect.colStart = colStart
        if (colEnd > currentRect.colEnd) currentRect.colEnd = colEnd
        if (rowStart < currentRect.rowStart) currentRect.rowStart = rowStart
        if (rowEnd > currentRect.rowEnd) currentRect.rowEnd = rowEnd
    }, container.items || [])
    return toContainerRect(currentRect)
}

export const offsetContainer = curry((colOffset: number, rowOffset: number, container: ContainerRect): ContainerRect => {
    const offsetItem = offsetContainer(colOffset, rowOffset)
    return compose<any, any, any, any>(
        assoc("col", container.col + colOffset),
        assoc("row", container.row + rowOffset),
        assoc("items", map(offsetItem, container.items || []))
    )(container)
})

export const offsetContainerItems = curry((colOffset: number, rowOffset: number, container: ContainerRect): ContainerRect => {
    const offsetItem = offsetContainer(colOffset, rowOffset)
    return compose(
        assoc("items", map(offsetItem, container.items || []))
    )(container) as any
})


export const matchContainerRowSpan = curry((shouldMatchFn: (i: ContainerRect) => boolean, container: ContainerRect) => {
    return assoc(
        "items",
        map(i => shouldMatchFn(i) ? assoc("rowSpan", container.rowSpan, i) : i, container.items || []),
        container
    )
})

export const containerOverlaps = curry((a: ContainerRect, b: ContainerRect) => {
    const aC = toContainerCoords(a)
    const bC = toContainerCoords(b)
    const isLeft = aC.colStart >= bC.colEnd || bC.colStart >= aC.colEnd
    const isAbove = aC.rowStart >= bC.rowEnd || bC.rowStart >= aC.rowEnd
    return !(isLeft || isAbove)
})

export const calcContainerSpaces = (container: ContainerRect): ContainerRect[] => {
    const items = container.items || []
    const overlapsAny = i => any(c => containerOverlaps(i, c) && (i !== c), items)
    const spaces = []
    const pickRect = pick(["col", "colSpan", "row", "rowSpan"])

    let current: ContainerRect = merge(pickRect(container), { colSpan: 0 })

    times(x => {
        const { colSpan, col } = current
        const checkRect = merge(current, { colSpan: colSpan + 1 })
        if (overlapsAny(checkRect)) {
            if (colSpan > 0) spaces.push(current)
            current = merge(checkRect, { col: col + colSpan + 1, colSpan: 0 })
        } else {
            current = checkRect
        }
    }, container.colSpan)
    if (current.colSpan > 0) spaces.push(current)
    return spaces
}

export const layoutContainer = (container: ContainerRect): ContainerRect => {
    const clonedContaner = clone(container)

    const sortByCol = sortWith([
        ascend(prop("col") as any),
        descend(prop("colSpan") as any)
    ])

    clonedContaner.items = sortByCol(map(ensureContainerRect, clonedContaner.items || [])) as any

    const { items } = clonedContaner
    const overlapsAny = i => any(c => containerOverlaps(i, c) && (i !== c), items)
    const moveItemDownAndTrack = x => { x.row += 1; x._rowOffset = x._rowOffset || 0; x._rowOffset += 1 }
    const moveOverlapsDown = i => forEach(x => i !== x && containerOverlaps(i, x) ? moveItemDownAndTrack(x) : null, items) as any

    // Important, this loop must ensure item order is reflected in row position
    forEach(i => {
        until(
            () => !overlapsAny(i),
            () => moveOverlapsDown(i)
        )(0) // 0 is of no concequence, just used to kick off until closure loop
    }, items)

    // Move items down to match container
    clonedContaner.items = map(i => {
        return compose<any, any, any>(
            dissoc("_rowOffset"),
            offsetContainerItems(0, i._rowOffset || 0)
        )(i) as any
    }, items as any[])

    return merge(clonedContaner, calcContainer(clonedContaner))
}
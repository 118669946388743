import * as moment from "moment"
import { FormControlProps } from "@sennen/dashboard-extension-sennen-core"
import {
    equals, cond, always, T,
    isNil, isEmpty
} from "ramda"
import { QuickTracksFormControl } from "./QuickTracksForm"

const emptyValidator = (d, c) => ""
const isNullOrEmpty = (x) => isNil(x) || isEmpty(x)
const isTextBoolFalse = (x) => !(x.toString() === "True")


const resolveDataType = (c: FormControlProps): string => {
    const qtControl: QuickTracksFormControl = c.metaData ? c.metaData.qtControl : {}
    if (c.type === "date") return "date"
    if (c.type === "checkbox") return "textBoolean"
    return qtControl.NumericOnly ? "number" : "string"
}

const numericValidator = (d: any, c: FormControlProps, v: any) => {
    if (!v) return null
    const isNumeric = /^([-+]?)?(\d+\.?\d*|\.\d+)$/.test(v)
    return (!isNumeric && "Please enter a valid number, the number should not include commas.") || null
}

const requiredValidator = (dataType: string, d: any, c: FormControlProps, v: any) => {
    const qtControl: QuickTracksFormControl = c.metaData ? c.metaData.qtControl : null
    const nullCheck = dataType === "textBoolean" ? isTextBoolFalse : isNullOrEmpty
    if (qtControl && qtControl.Required && nullCheck(v)) {
        const dataTypeText = {
            "string": "some text",
            "number": "a number",
            "date": "a date"
        }[dataType]
        if (c.type === "date") return `This field cannot be empty, please select a date.`
        if (c.type === "select") return `This field cannot be empty, please select an item from the list.`
        if (c.type === "checkbox") return `This checkbox must be selected.`
        return `This field cannot be empty, please enter ${dataTypeText}.`
    }
}

export const validator = (d: any, c: FormControlProps) => {
    const dataType = resolveDataType(c)
    const typeValidator = cond([
        [equals("number"), always(numericValidator)],
        [T, always(emptyValidator)]
    ])(dataType)
    const value = d[c.id]
    return requiredValidator(dataType, d, c, value) || typeValidator(d, c, value) || ""
}
import * as React from "react"
import {
    makeDroppable,
    ThemedComponentDecorator,
    rateLimitElementEvent
} from "@sennen/dashboards-react-component"
import { ComponentElement, createComponentElement } from "@sennen/dashboards-react-component"
import styles from "./GanttStyles"
import * as R from "ramda"
import { calcContainer, offsetContainer, toContainerCoords } from "./Container"
import { GanttItem as GanntItemComponent } from "./GanttItem"
import { GanttOverlay } from "./GanttOverlay"
import {
    GanttProps, GanttPropsAnnotated,
    GanttItem, GanttItemAnnotated,
    GanttEvent, GanttEventArgs, GanttEventDispatcher,
    GanttOverlayStartMethod, GanttStyleProps
} from "./GanttInterfaces"
import { GanttItemResizeService } from "./GanttItemResizeService"
import { GRID_COLUMN_GAP, GRID_COLUMN_GAP_HALF, ROW_HEIGHT_EM_CSS } from "./GanttConstants"

const mapIndexed = R.addIndex(R.map)
const isNilOrEmpty = R.either(R.isNil, R.isEmpty)

export interface GanttState { }

const renderItemRecursively = (eventDispatcher: GanttEventDispatcher, props: GanttPropsAnnotated, item: GanttItemAnnotated = undefined, keyPrefix: string, depth = 0) => {
    const itemProps = { eventDispatcher, ganttProps: props, item, depth }
    const initialItemArray = item ?
        [<GanntItemComponent {...itemProps} key={`${keyPrefix}`}  ></GanntItemComponent>] : []
    const items = item ? item.items : props.items
    return R.reduce((acc, childItem) => {
        return R.concat(renderItemRecursively(eventDispatcher, props, childItem, `${keyPrefix}->${childItem.id}`, depth + 1), acc) as any
    }, initialItemArray, items || [])
}

const hasUniqueIdsCheck = (items: Array<GanttItem>) => {
    const ids = R.map(R.prop("id"))(items as any)
    const uniqIds = R.compose<any, any, any>(
        R.filter(R.compose(R.not, R.isNil)), R.uniq
    )(ids)
    const hasUnique = uniqIds.length === items.length
    if (!hasUnique) {
        console.error(`Missing or Duplicatate item ids. A Gantt 'item' in an 'items' array must have an 'id' and it must be unique within the array.\nids: ${JSON.stringify(ids)}`)
    }
    return hasUnique
}

const annotateItem = (parentItem: GanttItem, item: GanttItem): GanttItemAnnotated => {
    const defaults = {
        parentItem,
        location: "timeline",
        type: "item",
        borderColor: parentItem.childBorderColor,
        col: 0, colSpan: 0,
        row: 0, rowSpan: 0,
        rowOffsetPercent: parentItem.rowOffsetPercent
    } as GanttItemAnnotated
    const annotatedItem = R.merge(defaults, item)
    annotatedItem.items = annotateItems(annotatedItem, item.items)
    return annotatedItem as unknown as GanttItemAnnotated
}

const annotateItems = (parentItem: GanttItem, items: GanttItem[] = []): GanttItemAnnotated[] => {
    return hasUniqueIdsCheck(items) ?
        R.map(i => annotateItem(parentItem, i), items) : []
}

const annotateProps = (props: GanttProps): GanttPropsAnnotated => {
    const defaults = {}
    const annotations = {
        items: annotateItems(props as any, props.items)
    } as GanttPropsAnnotated
    const annotatedProps = R.mergeAll([defaults, props, annotations]) as GanttPropsAnnotated
    return annotatedProps
}

const generateGridStyles = (annotatedProps: GanttPropsAnnotated) => {
    const { cols, rows, colWidth } = annotatedProps
    const gridTemplateColumns = "3em " + R.join(" ", R.times(R.always(colWidth || "1fr"), cols))
    const gridTemplateRows = "3em " + R.join(" ", R.times(R.always(ROW_HEIGHT_EM_CSS), rows))
    return {
        gridTemplateColumns,
        gridTemplateRows
    }
}

const renderLabels = (annotatedProps: GanttPropsAnnotated) => {
    const { labels, rows } = annotatedProps
    if (isNilOrEmpty(labels)) return null

    const labelDivs = R.map(label => {

        let { col, colSpan, description, highlight } = label
        col = (R.isNil(col) ? 1 : col) + 2
        colSpan = colSpan || 1

        const labelStyles = {
            gridColumnStart: "" + col, gridColumnEnd: "span " + colSpan,
            gridRowStart: "1", gridRowEnd: "span 1"
        }

        const dividerStyles = R.merge(labelStyles, {
            gridRowStart: "1", gridRowEnd: "span " + (rows + 1)
        })

        return [
            <div key={`label-divider-${col}`} style={dividerStyles} className={`ganttTimeDivider ${highlight ? "ganttTimeDividerHighlight" : ""}`}></div>,
            <div key={`label-description-${col}`} style={labelStyles} className={`ganttTimeLabel ${highlight ? "ganttTimeLabelHighlight" : ""}`}><div>{description}</div></div>
        ]

    }, labels || [])
    const firstLabelDiv = <div key={`label-description-first`} style={{ gridColumnStart: "1", gridColumnEnd: "span 1", gridRowStart: "1", gridRowEnd: "1" }} className="ganttTimeLabel"></div>
    return R.flatten(labelDivs).concat(firstLabelDiv as any)
}

const calcVerticalLayout = (annotatedProps: GanttPropsAnnotated): GanttPropsAnnotated => {
    const { items, rows, minRows } = annotatedProps
    const rootItems = annotatedProps.items || []
    const rootContainerCoords = R.map(R.compose(toContainerCoords, calcContainer), rootItems)
    let maxRowOffset = 0
    const offsetRootItems = mapIndexed((item: GanttItemAnnotated, index) => {
        const { rowEnd } = rootContainerCoords[index]
        const offsetItem = offsetContainer(0, maxRowOffset, item)
        maxRowOffset += rowEnd + 1
        return offsetItem
    }, rootItems)
    let calcRows = R.isNil(annotatedProps.rows) ? maxRowOffset : annotatedProps.rows
    if (!R.isNil(minRows) && calcRows < minRows) calcRows = minRows
    return R.compose<any, any, any>(
        R.assoc("items", offsetRootItems),
        R.assoc("rows", calcRows)
    )(annotatedProps) as any
}

const calcHorizontalLayout = (annotatedProps: GanttPropsAnnotated): GanttPropsAnnotated => {
    const colMax = R.reduce(
        (acc, item) => R.max(acc, item.col + item.colSpan),
        20, annotatedProps.labels || []
    )
    return R.assoc("cols", R.isNil(annotatedProps.cols) ? colMax : annotatedProps.cols, annotatedProps) as any
}



@ThemedComponentDecorator(styles)
export class Gantt extends React.Component<GanttProps, GanttState> {

    constructor(props) {
        super(props)
        this.state = { isResizingItem: false }
    }

    private disposed: boolean = false
    public componentWillUnmount() {
        this.disposed = true
    }

    // Rate limited events
    private rateLimitedEventDispatchers: { [key: string]: GanttEventDispatcher } = {}

    private itemEvent(ev: React.MouseEvent<HTMLElement>, eventName: string, eventArgs: GanttEventArgs) {
        const handler: GanttEvent = this.props[eventName]
        if (handler) handler(eventArgs)
    }
    private eventDispatcher(ev: React.MouseEvent<HTMLElement>, eventName: string, eventArgs: GanttEventArgs) {
        const dispatcherName = `${eventName}-${eventArgs.origin}`
        let dispatch = this.rateLimitedEventDispatchers[dispatcherName]
        if (!dispatch) {
            dispatch = rateLimitElementEvent(this.itemEvent.bind(this))
            this.rateLimitedEventDispatchers[dispatcherName] = dispatch
        }
        ev && ev.stopPropagation()
        dispatch.apply(this, arguments)
    }

    private startOverlayMethod: GanttOverlayStartMethod
    private sizeGuideElement: ComponentElement

    // Render
    public render() {

        let annotatedProps = annotateProps(R.clone(this.props)) // clone should not be required
        annotatedProps = calcVerticalLayout(annotatedProps)
        annotatedProps = calcHorizontalLayout(annotatedProps)
        annotatedProps.startOverlayMethod = this.startOverlayMethod

        const styles: GanttStyleProps = { gridColumnGap: GRID_COLUMN_GAP, gridColumnGapHalf: GRID_COLUMN_GAP_HALF }
        const resizeService = new GanttItemResizeService(this.sizeGuideElement, annotatedProps, styles, this.eventDispatcher.bind(this))

        const dropProps = makeDroppable({
            getData: (): GanttEventArgs => {
                return { origin: "gantt", props: this.props }
            },
            droppableProps: this.props
        })

        const sizeElementStyle = {
            display: "block",
            backgroundColor: "transparent",
            gridColumnStart: "2", gridColumnEnd: "span " + annotatedProps.cols,
            gridRowStart: "2", gridRowEnd: "2"
        }

        return this["renderCssContainer"](
            <div className="gantt" {...dropProps} style={generateGridStyles(annotatedProps)}>
                {renderLabels(annotatedProps)}
                {renderItemRecursively(this.eventDispatcher.bind(this), annotatedProps, null, "root")}
                <GanttOverlay
                    onSetStartMethod={m => this.startOverlayMethod = m}
                    onResizeStart={resizeService.onOverlayStart}
                    onResize={resizeService.onResize}
                    onResizeEnd={resizeService.onResizeEnd}
                ></GanttOverlay>
                <div className="ganttSizeGuide" ref={r => this.sizeGuideElement = createComponentElement(r)} style={sizeElementStyle}></div>
            </div>
            , { scrollable: annotatedProps.scrollable ? "scroll" : "hidden" }
        )
    }

}
import { JsonSnippetInsertOptions, getLastChainedTemplateData } from "../../../actionItems/JsonInsertSnippetActionItem"
import { FormProps } from "@sennen/dashboard-extension-sennen-core"
import { GenericEditorActionItemHandlerArgs } from "../../../genericEditor/GenericEditorConfigProviderInterfaces"
import { curry, assoc, path, compose, cond, equals, always, T, find, propEq, pathOr, findIndex, propOr, has } from "ramda"
import { CreatePacker, UnPackedFunction } from "@sennen/js-function-packer"
const { pack, unpack } = CreatePacker("DashBoardFunction")

const defaultFunction = (data) => {
    return `
    // Generic mapper
    return {} // Return mapped properties here`
}

const formButtonBarFunction = (data) => {
    return `
    // FormButtonBar mapper
    return {
        buttons:[
             { 
                "name": "buttonA",
                "text": "Button A",
                "type": "primary"
            }, {
                "align": "right",
                "name": "buttonC",
                "text": "Button C",
                "type": "highIntensityText",
                "divider": "left"
            }, {
                "align": "right",
                "name": "buttonB",
                "text": "Button B",
                "type": "secondary"
            }
        ]
    } `
}


const formFunction = (data) => {
    return `
        // Form mapper
        const fn = providers.functionalMethods()
        
        // Default getters and setters
        const getter = (data, control) => {return data[control.id]}
        const setter = (data, control, nextValue) => { return fn.assoc(control.id, nextValue, data)}
        
        // Validation
        const isNullOrEmpty = (x) => fn.isNil(x) || fn.isEmpty(x)
        const requiredValidator = (data, control) => {
            const value = getter(data, control)
            if (isNullOrEmpty(value)) return "This field cannot be empty."
        }
        const addressLengthValidator = (data, control) => {
            const value = getter(data, control) || ""
            if (fn.split("\\n", value).length > 4) return "Address cannot contain more than 4 lines."
        }
        
        // Sample data
        const formData = {
            firstName: null,
            lastName: null,
            address: null
        }
    
        // return Form object
        return {
            title: "Example Customer Form",
            data: formData,
            controls:[ 
                {
                    id:"firstName", 
                    label:"First Name",
                    type: "input",
                    placeHolderText: "Please enter First Name ...",
                    getValueBinding: getter, setValueBinding: setter,
                    validationMessage: requiredValidator
                },
                {
                    id:"lastName", 
                    label:"Last Name",
                    type: "input",
                    placeHolderText: "Please enter Last Name ...",
                    getValueBinding: getter, setValueBinding: setter,
                    validationMessage: requiredValidator
                },
                {
                    id:"address", 
                    label:"Address",
                    type: "textArea",
                    getValueBinding: getter, setValueBinding: setter,
                    validationMessage: addressLengthValidator
                }
            ],
            buttons: [{ 
                // This is optional, a 'Submit' button is displayed by default.
                // To hide buttons set 'buttons:[]' 
                text: "Save Customer",
                action: "onSubmit" // Add an 'onSubmit' action handler to capture this handle the submit event
            }]
        }`
}

const changeWatcherFunction = (data) => {
    return `
    // This component will trigger an 'onValueChange' action handler 
    // every time the 'value' property changes, the trigger will only fire ONCE per change.
    // Examples:
    // 1. Watch for state changes by returning 'context.params.[statePath]'.
    //    e.g. return { value: context.value.currentDate }
    //
    // 2. Return any static value to trigger an 'onValueChange' when the dasboard first renders.
    //    e.g. return { value: firstRender" }
    //
    return { value: undefined }
    `
}

const functionMap = {
    "FormButtonBar": formButtonBarFunction,
    "Form": formFunction,
    "ChangeWatcher": changeWatcherFunction
}

const resolveFunction = (data) => {
    let func = propOr(defaultFunction, data.reactComponentName, functionMap) as any
    const resFunc = `() => { ${func(data)} \n}`
    return pack([{ name: "main", value: resFunc }])
}

const functionMapperTemplate = async data => {
    return {
        "type": "FunctionMapper",
        "properties": {
            "function": resolveFunction(data)
        }
    }
}

export const addFunctionMapperSnippet: JsonSnippetInsertOptions = {
    name: "add-function-mapper-handler",
    buttonText: "Function Mapper",
    buttonIconClassName: "fa fa-plus",
    insertLocation: "child",
    insertPath: ["mappers"],
    insertMode: "prepend",
    templateDataFormResolver: async (args: GenericEditorActionItemHandlerArgs) => {
        return {
            data: {
                type: path(["type"], args.nodeData),
                reactComponentName: path(["properties", "reactComponentName"], args.nodeData)
            }
        } as FormProps
    },
    template: functionMapperTemplate
}


export const addFunctionMapperOnCreateSnippet: JsonSnippetInsertOptions = {
    name: "add-function-mapper-handler",
    buttonText: "Add Function Mapper",
    insertLocation: "absolute",
    insertPath: async (data: any, templateData: any) => {
        const hasFunction = has(templateData.reactComponentName, functionMap)
        const { dashPartId } = templateData
        const dashParts = pathOr([], ["entry", "dashParts"], data)
        let dashPartIndex = findIndex(propEq("id", dashPartId), dashParts)
        if (!hasFunction && templateData.skipIfHasNoFunction) dashPartIndex = -1
        return ["entry", "dashParts", dashPartIndex, "mappers"]
    },
    insertMode: "prepend",
    templateDataFormResolver: async (args: GenericEditorActionItemHandlerArgs) => {
        const lastData = getLastChainedTemplateData(args)
        const { reactComponentName, dashPartId } = lastData
        return {
            data: {
                skipIfHasNoFunction: true,
                dashPartId,
                type: "reactContainer",
                reactComponentName
            }
        } as FormProps
    },
    template: functionMapperTemplate
}
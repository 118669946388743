import {
    Theme,
    renderFontCss
} from "@sennen/dashboards-react-client"

import { ThemedComponentStyles } from "@sennen/dashboards-react-component"

export const styles: ThemedComponentStyles = function (theme: Theme) {

    return {
        css: `

            @id .gauge {
                position: relative;
                overflow: hidden;
                ${renderFontCss(theme.fonts.default)}
            }

            @id .gauge.gauge--condensed {
                ${renderFontCss(theme.fonts.condensed)}
            }

            @id .gauge-svg {
                box-sizing: border-box;
                width: 100%;
                padding: 0 10%;
            }

            @id .gauge-metrics {
                position: absolute;
                bottom:0;
                line-height: 1.1;
                left: 0;
                text-align: center;
                width: 100%;
                display: inline-block; 
                color: ${theme.textColors.highIntensity};             
                margin-bottom: 1%;   
            }

            @id .gauge-min,
            @id .gauge-max  {
                line-height: 1.1;
                position: absolute;
                bottom: 0; 
            }

            @id .gauge-min {
                left:11%;
                text-align: left;
            }

            @id .gauge-max {
                right:11%;
                text-align:right;
            }

            `
    }
}
import { Provider } from "@sennen/dashboards-react-client"
import objectId from "bson-objectid"

export class ObjectIdProvider implements Provider {
    public propertyName = "objectId"

    public createMethod(configType: string, config: any): any {
        return objectId
    }

}
import { Provider, RootStoreActions } from "@sennen/dashboards-react-client"
import { getProviders } from "../../helpers/provider"
import { isNil, isEmpty } from "ramda"
import * as R from "ramda"

// Should be in some sort of resource file
const successMessages = {
    "title": "Record Saved",
    "defaultMessage": "",
    "200": ""
}
const errorMessages = {
    "title": "Save Failed",
    "defaultMessage": "Reason Unknown",
    "400": "Bad Request",
    "422": "Missing Request Parameter",
    "500": "Internal Server Error",
    "501": "Command Not Recognised",
    "403": "Operation not authorised",
    "409": "Unable to Process Request"
}

const errorTypes = {
    "title": "error",
    "defaultMessage": "error",
    "400": "error",
    "422": "error",
    "500": "error",
    "501": "error",
    "403": "warning",
    "409": "warning"
}
const reject:any = R.reject
const isNullOrEmpty = (x) => isNil(x) || isEmpty(x)

export class QuickTracksFormSubmitProvider implements Provider {

    public propertyName = "qtFormSubmit"

    public createMethod(configType: string, config: any): any {

        return (data, context) => {
            return RootStoreActions.providerDispatch((dispatch, getState) => {
                const dataToSubmit = R.pipe(
                    R.keys,
                    reject(R.startsWith("label:")),
                    R.map((k: string)=> [k, data[k]]),
                    R.fromPairs
                )(data)
                const { params, actions } = context

                // Get Providers
                const { dataSourceCommand, notify, dataRefresh } = getProviders(getState, dispatch, configType, config)

                // Execute Data Source Command            
                if (dataSourceCommand) {
                    const { viewId, masterTableId, entityId, urlParams } = params

                    dataSourceCommand('quickTracksFormSubmit', { viewId, masterTableId, entityId, data: dataToSubmit, urlParams }, (r) => {
                        if (r.statusCode < 300) {
                            dataRefresh()
                            notify(
                                successMessages["title"],
                                successMessages[r.statusCode] || successMessages["defaultMessage"],
                                { type: "success" }
                            )
                            if (actions.onSubmitSuccess) actions.onSubmitSuccess(r)
                        } else {
                            const bodyMessage = isNullOrEmpty(r.body) ? null : r.body
                            notify(
                                errorMessages["title"],
                                bodyMessage || errorMessages[r.statusCode] || errorMessages["defaultMessage"],
                                { type: errorTypes[r.statusCode] || "error" }
                            )
                            if (actions.onSubmitFailure) actions.onSubmitFailure(r)
                        }
                    })
                }
            })
        }
    }

} 
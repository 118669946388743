import { path, assoc } from "ramda"
import { FormControlProps, FormProps, SyncInfo } from "@sennen/dashboard-extension-sennen-core"
import { requiredJsonAce } from "../../helpers/formControls"
const jsonIn = d => JSON.stringify(d, null, 4)
const jsonOut = d => JSON.parse(d)

export const jsonOnSync = (providers, descriptorProviderName, context, formData, info: SyncInfo) => {
    if (info.isValid && info.hasChanged) {
        const editingDashBoard: any = path(["params", "editingDashBoard"], context)
        const updatedDashboard = assoc("entry", jsonOut(formData.entry), editingDashBoard)
        providers.dashBoardState("editingDashBoard", updatedDashboard)
    }
}

export const jsonMapper = (providers, descriptorProviderName, context): FormProps => {
    const loadState = path(["params", "loadState"], context)
    const controls = [] as FormControlProps[]
    let data = {} as any
    if (loadState === "editing") {
        controls.push(requiredJsonAce("entry", null, "60vh"))
        const editingDashBoard: any = path(["params", "editingDashBoard"], context)
        data.entry = jsonIn(editingDashBoard.entry)
    }

    return {
        controls,
        data,
        inlineLabels: false,
        buttons: [],
        hideButtonBar: true
    }

}
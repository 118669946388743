import { genericPropGetter } from "./formGetterSetter"
import { FormControlProps } from "@sennen/dashboard-extension-sennen-core"
import {
    isNil, isEmpty, map, find
} from "ramda"
import { version } from "react";

const isNullOrEmpty = (x) => isNil(x) || isEmpty(x)

export const combineValidators = (validators: any[]) => {
    return (d: any, c: FormControlProps): string => {
        const value = genericPropGetter(d, c)
        const messages = map(v => v(d, c, value), validators)
        return find(m => !isNullOrEmpty(m), messages)
    }
}

export const requiredValidator = (message?: string) => (d: any, c: FormControlProps, v: any): string => {
    if (isNullOrEmpty(v)) return message || "This field cannot be empty."
}

export const jsonValidator = (message?: string) => (d: any, c: FormControlProps, v: any): string => {
    if (v) {
        try {
            JSON.parse(v)
        } catch (e) {
            return message || "Invalid JSON value."
        }
    }
}

export const jsValidator = (message?: string) => (d: any, c: FormControlProps, v: any): string => {
    if (v) {
        try {
            JSON.stringify(v)
        } catch (e) {
            return message || "Invalid JS value."
        }
    }
}

export const regexValidator = (rgx: any, message?: string) => (d: any, c: FormControlProps, v: any): string => {  
    const passed = isNil(v) ? true : RegExp(rgx).test(v)
    if (!passed) return message || "This field is in the incorrect format."
}
import { Theme } from "@sennen/dashboards-react-client"
export default (theme: Theme) => {
    return {
        css: `
            @id {
                display: block;
                height: 100%;
                width: 100%;
                overflow: @scrollable; 
                margin: 0 2px;
                background-color: ${theme.backgroundColors.darker};
            }

            @id .gantt {                
                display: grid;                
                grid-column-gap: 7px;
                grid-row-gap: 6px;
                padding: 0px 2px;
                position: relative;
            }

            @id header {
                padding: 0 0.5em;
                line-height: 2em;
                color: ${theme.textColors.highIntensity};
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                pointer-events: none;
            }

            @id .ganttTimeLabel {
                background-color: ${theme.backgroundColors.default};
                margin: 0px -3px;                
                padding-left: .5em;
                color: ${theme.textColors.default};
                border-bottom: solid 1px ${theme.backgroundColors.lighter};
            }
            @id .ganttTimeLabel > div {
                line-height: 3em;
            }
            @id .ganttTimeLabel.ganttTimeLabelHighlight {
                color: ${theme.textColors.highIntensity};
            }

            @id .ganttTimeDivider {
                border-left: solid 1px ${theme.backgroundColors.dark};
                margin-left: -4px;
            }
            @id .ganttTimeDivider.ganttTimeDividerHighlight {
                border-left: solid 1px ${theme.backgroundColors.lighter};
            }

            @id .ganttItem {
                overflow: hidden;                
                position: relative;
                box-sizing: border-box;
                user-select: none;
            }

            @id .ganttItemDepth_1 {
                border-radius: 5px;
                z-index: 1;
            }

            @id .ganttItemDepth_2 {
                margin: -2px;
                border: solid 1px white;
                border-radius: 3px;
                z-index: 2;
            }

            @id .ganttItemDepth_3 {
                margin: -2px 2px 2px 2px;
                padding-bottom: 2px;
                border: solid 1px white;
                border-radius: 3px;
                z-index: 3;
            }

            @id .ganttItem.ganttItemInteractive:hover {
                border-color: white !important;
            }
            
            @id .ganttItemType_container {
                background: rgba(0,0,0,0.20);
                border-color: #304450;
            }

            @id .ganttItemType_block {
                background: rgba(0,0,0,0.20);
                border-color: #304350;
            }

            @id .ganttItemType_block header {
                transform-origin: 0 100%;
                transform: rotate(90deg);
                text-overflow: initial;
                overflow: visible;
            }

            @id .ganttItem header .markdown-content em {
                color: ${theme.textColors.lowIntensity};
            }

            @id .ganttItemType_placeholder {                
                flex-direction: column;
                justify-content: center;
                text-align: center;
                padding: 0 1em;
                border-style: dashed;
            }

            @id .ganttItemType_placeholder header {
                padding: 0;
                white-space: initial;
                line-height: inherit;
            }

            @id .ganttItem.ganttItemType_placeholder .ganttIcons {
                position: initial;
                width: auto;
            }

            @id .ganttItem.ganttItemType_placeholder.ganttItem--hasDescription.ganttItem--hasIcons header {
                margin-top: 0.25em;
            }

            @id .ganttItem.ganttItemType_placeholder .ganttIcons .ganttIcon.ganttIcon.ganttIcon--right,
            @id .ganttItem.ganttItemType_placeholder .ganttIcons .ganttIcon.ganttIcon.ganttIcon--left,
            @id .ganttItem.ganttItemType_placeholder .ganttIcons .ganttIcon.ganttIcon.ganttIcon--center {
                float: none;
                margin: 0;
                line-height: 1;
            }

            @id .ganttItemType_item {
                background: #23343F;
                border-color: #304350;
            }

            @id .ganttItemLocation_leftMargin {                
                margin-right: .25em;
                margin-left: .25em;
                box-sizing: border-box;
            }

            @id .ganttItem .ganttIcons {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
            }

            @id .ganttItem .ganttIcons .ganttIcon {
                line-height: 2em;
            }

            @id .ganttItem .ganttIcons .ganttIcon.ganttIcon--left {
                float: left;
                margin-left: 0.4em;
            }
            
            @id .ganttItem .ganttIcons .ganttIcon.ganttIcon--right {
                float: right;
                margin-right: 0.4em;
            }

            @id .ganttItem .ganttIcons .ganttIcon.ganttIcon--center {
                margin: 0 0.2em;
            }

            @id .ganttItemType_block header {
                color: ${theme.textColors.default};
            }

            @id .ganttItem.ganttItemDrop_allowed {                 
                box-shadow: 0 0 0 2px black, 0 0 0 4px ${theme.indicatorColors.success}, 0 0 0 5px black;   
            }

            @id .ganttItem.ganttItemDrop_allowed header,
            @id .ganttItem.ganttItemDrop_allowed .ganttIcons {
                opacity: 0.8;
            }

            @id .gantt-resize-overlay {
                box-sizing: border-box;
                z-index: 999;
                position: absolute;
                top:0;
                left:0;
                display: none;
                border: solid 1px white;
                border-radius: 3px;
                pointer-events: none;
                background: rgba(0,0,0,0.60);
                box-shadow: ${theme.shadows.dark};
            }
        `
    }
}
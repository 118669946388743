import * as React from "react"
import {
    GanttItemAnnotated, GanttEventArgs, GanttEventDispatcher,
    GanttPropsAnnotated, GanttIcon, GanttOverlayModes
} from "./GanttInterfaces"
import { makeDroppable } from "@sennen/dashboards-react-component"
import { createComponentElement } from "@sennen/dashboards-react-component"
import * as R from "ramda"
import { Markdown } from "@sennen/dashboard-extension-sennen-core"
import { ROW_HEIGHT_EM_CSS, GRID_COLUMN_GAP } from "./GanttConstants"

const mapIndexed = R.addIndex(R.map)
const isNullOrEmpty = R.either(R.isNil, R.isEmpty)

export type GanttItemMouseOperations = "click" | "none" | GanttOverlayModes

export class GanttItemProps {
    ganttProps: GanttPropsAnnotated
    item: GanttItemAnnotated
    eventDispatcher: GanttEventDispatcher
    depth: number
}

export class GanttItemState {
    mouseDownOperation: GanttItemMouseOperations
    dropStatus: "none" | "allowed" | "denied"
}

export class GanttItem extends React.Component<GanttItemProps, GanttItemState> {

    constructor(props) {
        super(props)
        this.state = { mouseDownOperation: "none", dropStatus: "none" }
    }

    private onMouseDown(ev: React.MouseEvent<HTMLElement>, item: GanttItemAnnotated) {
        const { ganttProps } = this.props
        const operation = getMouseOperation(ev, item)
        this.setState({ mouseDownOperation: operation })
        if (operation === "resize-left" || operation === "resize-right" || operation === "move-left-right") {
            ev.preventDefault()
            ev.stopPropagation()
            ganttProps.startOverlayMethod({
                element: createComponentElement(ev.currentTarget),
                mode: operation,
                sourceReference: item
            })
        }
    }

    private getKey() {
        let key = ""
        let item = this.props.item as any
        while (item) {
            key = `[${item.id}]${key}`
            item = item.parentItem
        }
        return key
    }

    private setDropState(newStatus) {
        if (this.state.dropStatus !== newStatus)
            this.setState({ ...this.state, dropStatus: newStatus })
    }

    public render() {

        const { eventDispatcher, item, depth, ganttProps } = this.props
        const { mouseDownOperation, dropStatus } = this.state
        const key = this.getKey()

        const styles = generateItemStyles(item)

        const dropProps = makeDroppable({
            getData: (): GanttEventArgs => {
                return { origin: "item", item }
            },
            onAllowDropResult: (result) => this.setDropState(R.isNil(result) ? null : result ? "allowed" : "denied"),
            onDragReset: () => this.setDropState("none"),
            droppableProps: ganttProps
        })

        const classNames = [
            `ganttItem ganttItemType_${item.type}`,
            `ganttItemDepth_${depth}`,
            `ganttItemLocation_${item.location}`,
            `ganttItemDrop_${dropStatus}`
        ]
        if (item.resizable) classNames.push("ganttItemInteractive")
        if (!isNullOrEmpty(item.description)) classNames.push("ganttItem--hasDescription")
        if (!isNullOrEmpty(item.icons)) classNames.push("ganttItem--hasIcons")

        return <section
            key={key}
            style={styles.section}
            className={R.join(" ", classNames)}
            onMouseDown={ev => this.onMouseDown(ev, item)}
            onClick={ev => {
                if (mouseDownOperation === "click") {
                    eventDispatcher(ev, "onClick", { item, targetElement: createComponentElement(ev.currentTarget), origin: "item" })
                }
            }}
            onMouseEnter={ev => eventDispatcher(ev, "onMouseEnter", { item, targetElement: createComponentElement(ev.currentTarget), origin: "item" })}
            onMouseLeave={ev => eventDispatcher(ev, "onMouseLeave", { item, targetElement: createComponentElement(ev.currentTarget), origin: "item" })}
            onMouseMove={ev => setItemCursor(ev, item)}
            {...dropProps}
        >
            {renderIcons(eventDispatcher, item.icons, { item, origin: "itemIcon" })}
            <header style={styles.header}><Markdown markdown={item.description}></Markdown></header>

        </section >

    }

}

const renderIcons = (eventDispatcher: GanttEventDispatcher, icons: Array<GanttIcon> = [], partialEventArgs: GanttEventArgs) => {
    if (icons.length) {
        const iconElements = mapIndexed((icon: GanttIcon, index) => {
            const createArgs = ev => {
                return {
                    ...partialEventArgs,
                    ...{ icon, targetElement: createComponentElement(ev.currentTarget) }
                } as GanttEventArgs
            }

            return <i
                key={`icon-${index}`}
                className={`${icon.className} ganttIcon ganttIcon--${icon.align || "right"}`}
                style={{ color: icon.color, cursor: icon.cursor }}
                onClick={ev => eventDispatcher(ev, "onClick", createArgs(ev))}
                onMouseEnter={ev => eventDispatcher(ev, "onMouseEnter", createArgs(ev))}
                onMouseLeave={ev => eventDispatcher(ev, "onMouseLeave", createArgs(ev))}
                onMouseMove={ev => ev.stopPropagation()}
            />

        }, icons)
        return <div className="ganttIcons">{iconElements}</div>
    }
    return null
}

const generateItemStyles = (item: GanttItemAnnotated) => {
    const { col, colSpan, row, rowSpan, type, rowOffsetPercent = 0 } = item
    const transform = `translateY(calc((${ROW_HEIGHT_EM_CSS} + ${GRID_COLUMN_GAP}px) * ${rowOffsetPercent}))`
    let styles = {
        section: {
            borderColor: item.borderColor || "none",
            display: type === "placeholder" ? "flex" : "block",
            backgroundColor: item.fillColor,
            gridColumnStart: "" + (col + 2),
            gridColumnEnd: "span " + colSpan,
            gridRowStart: "" + (row + 2),
            gridRowEnd: "span " + rowSpan,
            transform
        },
        header: {
            color: item.textColor || undefined
        }
    }

    if (item.location === "leftMargin") {
        styles.section.gridColumnStart = "1"
        styles.section.gridColumnEnd = "2"
    }

    if (item.location === "none") {
        styles.section.display = "none"
    }

    return styles
}

const getMouseOperation = (ev: React.MouseEvent<HTMLElement>, item: GanttItemAnnotated): GanttItemMouseOperations => {
    const { left, right, top } = createComponentElement(ev.currentTarget).getRelativeMousePos()
    if (item.resizable) {
        if (left >= 0 && left <= 10) return "resize-left"
        if (right >= 0 && right <= 10) return "resize-right"
    }
    if (item.moveable) {
        if (top >= 0 && top <= 10) return "move-left-right"
    }
    return "click"
}


const setItemCursor = (ev: React.MouseEvent<HTMLElement>, item: GanttItemAnnotated) => {

    ev.target["style"].cursor = {
        "resize-left": "ew-resize",
        "resize-right": "ew-resize",
        "move-left-right": "move",
        "none": "auto",
        "click": "auto"
    }[getMouseOperation(ev, item)]

}


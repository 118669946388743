import * as React from "react"
import * as R from "ramda"
import { LayoutConfiguration } from "@sennen/dashboards-interfaces"
import {
    ComponentRender,
    Theme,
    scale,
    toCss
} from "@sennen/dashboards-react-client"
import { ThemedComponentDecorator, ThemedComponentStyles } from "@sennen/dashboards-react-component"

@ThemedComponentDecorator((theme: Theme) => {
    return {
        css: `
            @id .flowChild {
                padding: ${toCss(theme.padding.medium)} 0;
            }
        `
    }
})
export class FlowLayout extends React.Component<{ configuration: LayoutConfiguration, componentRender: ComponentRender }> {

    public styles

    render() {
        const children = this.props.configuration.children.map((child, index) => {
            const overflowVisible = !!R.path(["properties", "overflowVisible"], child)
            return <div className="flowChild" key={"flow-child-" + index} style={this.styles.child}>
                {this.props.componentRender.renderLayoutChild(child, { overflowVisible })}
            </div>
        })
        return this["renderCssContainer"](<div>{children}</div>)
    }

} 
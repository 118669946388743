import { FormProps } from "@sennen/dashboard-extension-sennen-core"
import { JsonSnippetInsertOptions } from "../../../actionItems/JsonInsertSnippetActionItem"
import { GenericEditorActionItemHandlerArgs, GenericEditorActionItem } from "../../../genericEditor/GenericEditorConfigProviderInterfaces"
import { stringInput, requiredSelect, requiredIdInput } from "../../../../helpers/formControls"
import { map, keys, identity, merge, sortBy, path } from "ramda"
import * as R from "ramda"
import { getRegisteredType } from "../../../clientRegistry/clientRegistry"
import { appendToLayoutSnippet } from "./layout"
import { addFunctionMapperOnCreateSnippet } from "./dashPartFunctionMapper"
import { confirmP } from "./helpers"

const toTextValue = x => identity({ text: x, value: x })
const forEachIndexed = R.addIndex(R.forEach)

const reactDashPartTemplate = async data => {
    const { dashPartId, reactComponentName, title } = data
    return {
        "id": dashPartId,
        "type": "reactContainer",
        "mappers": [],
        "properties": {
            "reactComponentName": reactComponentName
        },
        "actionHandlers": [],
        "childProperties": {
            "title": title || "",
            "hideChrome": false,
            "hideToolbar": false
        },
        "dataSourceCommands": [],
        "dataSourceSubscriptions": []
    }
}

export const addDashPartChildSnippet: JsonSnippetInsertOptions = {
    name: "new-react-dashpart",
    buttonText: "New Dashpart",
    buttonIconClassName: "fa fa-plus",
    insertLocation: "absolute",
    insertPath: ["entry", "dashParts"],
    insertMode: "prepend",
    templateDataFormResolver: async (args: GenericEditorActionItemHandlerArgs) => {
        const components = await getRegisteredType(args.providers, "components")
        const componentItems = map(toTextValue, sortBy(identity, keys(components)))
        return {
            formTitle: "New Dashpart",
            data: {
                layoutChildType: "dashPart",
                dashPartId: undefined,
                reactComponentName: undefined
            },
            controls: [
                requiredIdInput("dashPartId", "ID"),
                requiredSelect("reactComponentName", "Component", componentItems),
                merge(stringInput("title", "Title"), { placeHolderText: "Optional" })
            ]
        } as FormProps
    },
    template: reactDashPartTemplate
}

export const deleteDashPartActionItem: GenericEditorActionItem = {
    actionType: "button",
    actionOptions: {
        name: "delete-dashpart",
        text: "Delete",
        type: "system",
        iconClassName: "fa fa-trash",
        minWidth: "auto",
        divider: "left"
    },
    actionHandler: async (args: GenericEditorActionItemHandlerArgs) => {
        const { providers, data } = args
        const dashPartPath = R.path(["node", "parsedPath"], args) as any
        const dastPartId = args.nodeData.id
        const confirmRes = await confirmP(providers, `Are you sure you want to delete the DashPart '${dastPartId}'?`)
        if (confirmRes) {
            const layouts = R.pathOr([], ["entry", "layouts"], data)
            let layoutChildPath = null
            forEachIndexed((layout: any, layoutIndex) => {
                const dashPartChildIndex = R.findIndex(
                    R.pathEq(["typeProperties", "dashPartId"], dastPartId),
                    layout.children || []
                )
                if (dashPartChildIndex > -1) layoutChildPath = ["entry", "layouts", layoutIndex, "children", dashPartChildIndex]
            }, layouts)
            const removed = R.compose(
                d => layoutChildPath ? R.dissocPath(layoutChildPath, d) : d,
                R.dissocPath(dashPartPath)
            )(data)
            return removed
        }
        return null
    }
}

export const addDashPartSnippet = [addDashPartChildSnippet, appendToLayoutSnippet, addFunctionMapperOnCreateSnippet]

import { FormProps, SyncInfo } from "@sennen/dashboard-extension-sennen-core"
import { pathOr } from "ramda"

const modalLayoutP = (providers, layoutId, onDisplay) => {
    return new Promise((res, rej) => providers.navigation.modalLayout(layoutId, {
        resultCallBack: res,
        cancelCallBack: () => rej(new Error("Modal cancelled by user.")),
        visibleCallBack: onDisplay
    }))
}

export const showModalDataForm = async (providers: any, form: FormProps): Promise<any> => {
    providers.dashBoardState("modalDataForm", form)
    const result = await modalLayoutP(providers, "modalDataFormLayout", () => {
        providers.focus.dashPart("modalDataForm")
    })
    return result
}

export const modalDataFormMapper = (providers, descriptorProviderName, context): FormProps => {
    return pathOr({}, ["params", "modalDataForm"], context) as FormProps
}

export const modalDataFormSubmit = (providers, descriptorProviderName, context, formData) => {
    providers.navigation.modalLayoutResult("modalDataFormLayout", formData)
}

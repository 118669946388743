import { getLocale } from "./dateTimeUtils"
import { ClientApp } from "@sennen/dashboards-react-client"
import { isContext } from "../../helpers/isContext"
import * as R from "ramda"

export const language = {

    getCurrent: (context) => {
        if (!isContext(context)) return { valid: false, errorString: "Context not passed" }
        return R.path(
            ["state", "__requestContextParams", "languageKey"],
            context

        )
    },

    setCurrent: (languageKey: string) => {
        ClientApp.socketManager.emit("app:updateRequestContextParams", { languageKey })
    },

    getLocale: (context) => {
        if (!isContext(context)) return { valid: false, errorString: "Context not passed" }
        return getLocale(context)
    },

    getLanguages: (context) => {        
        if (!isContext(context)) return { valid: false, errorString: "Context not passed" }
        return R.defaultTo(
            [],
            R.path(
                ["state", "__config", "appLanguages"],
                context
            )
        )
    }
}

import { FormProps } from "@sennen/dashboard-extension-sennen-core"
import { JsonSnippetInsertOptions } from "../../../actionItems/JsonInsertSnippetActionItem"
import { GenericEditorActionItemHandlerArgs } from "../../../genericEditor/GenericEditorConfigProviderInterfaces"
import { requiredStringInput, idInput, checkbox, select } from "../../../../helpers/formControls"
import { path } from "ramda"

export const addMenuItemSnippet: JsonSnippetInsertOptions = {
    name: "new-menu-item",
    buttonText: "Add Menu Item",
    insertLocation: "child",
    insertPath: ["items"],
    insertMode: "append",
    templateDataFormResolver: async (args: GenericEditorActionItemHandlerArgs) => {
        const parentTitle = path(["title"], args.nodeData)
        return {
            formTitle: `Add to '${parentTitle}'`,
            data: {
                title: undefined,
                dashBoardId: undefined,
                authorisation: "__private"
            },
            controls: [
                requiredStringInput("title", "Title"),
                idInput("dashBoardId", "Dashboard ID"),
                select("authorisation", "Authorisation", [
                    { text: "Public (visible to everyone, including users who are NOT logged-in)", value: "__public" },
                    { text: "Private (only visible to logged-in users)", value: "__private" }
                ])
            ]
        } as FormProps
    },
    template: async (data: any) => {
        const { title, dashBoardId, authorisation } = data
        return {
            title, dashBoardId,
            "authorisation": {
                "allow": [
                    authorisation
                ]
            }
        }
    }
}
import { split, path, curry, times, test, modulo, divide, isNil, any } from "ramda"
import { DateTime, Duration } from "luxon"
import { getTimezone } from "../../providers/SennenProvider/dateTimeUtils"

export const ValidDateTime = (isoString: string): DateTime => {
    const invalid = test(/([\+,Z])/g, isoString)
    if (invalid) throw new Error(`Invalid date '${isoString}', offset times containing [Z] or [+] not supported.`,)
    return DateTime.fromISO(isoString)
}

export interface InternalTime {
    hours: number
    minutes: number
    seconds: number
}

export const convertZonedDateTime = (context: any, dateTimeUTC: string): string => {
    if (!dateTimeUTC) return null
    const dateTime = DateTime.fromISO(dateTimeUTC).setZone(getTimezone(context))
    if (!dateTime.isValid) {
        console.log(`invalid datetime ${dateTimeUTC}`)
        return null
    }
    return dateTime.toISO().substring(0, 16)
}

// export const toInternalTime = (value: string): InternalTime => {
//     const splitValue = split(":", value || "")
//     const parsePart = pos => parseInt(path([pos], splitValue) || "0", 10)
//     return { hours: parsePart(0), minutes: parsePart(1), seconds: parsePart(2) }
// }

// export const floorTime = (value: InternalTime): InternalTime => {
//     return { hours: value.hours, minutes: 0, seconds: 0 }
// }

// export const ceilingTime = (value: InternalTime): InternalTime => {
//     const hours = value.seconds > 0 || value.minutes > 0 ? value.hours + 1 : value.hours
//     return { hours, minutes: 0, seconds: 0 }
// }

export const roundTimeToMinuteIncrements = curry((minuteIncrements: number, value: InternalTime): InternalTime => {
    const minutesRounded = Math.round(value.minutes / minuteIncrements) * minuteIncrements
    return {
        hours: minutesRounded === 60 ? value.hours + 1 : value.hours,
        minutes: minutesRounded == 60 ? 0 : minutesRounded,
        seconds: 0 //value.seconds
    }
})

const detailedDiff = (dtA: DateTime, dtB: DateTime): InternalTime => {
    const diff = dtA.diff(dtB, ["hours", "minutes", "seconds"]).toObject()
    return { hours: diff.hours || 0, minutes: diff.minutes || 0, seconds: diff.seconds || 0 }
}

export const timeToCol = curry((startTime: string, minutesPerCol: number, time: string) => {
    const startDT = ValidDateTime(startTime)
    const timeDT = ValidDateTime(time)
    const diff = detailedDiff(timeDT, startDT)
    const rounded = roundTimeToMinuteIncrements(minutesPerCol, diff)
    const colsPerHour = 60 / minutesPerCol
    return (rounded.hours * colsPerHour) + (rounded.minutes / minutesPerCol)
})

export const colToTime = curry((startTime: string, minutesPerCol: number, col: number) => {
    const startDT = ValidDateTime(startTime)
    const hourDivisor = 60 / minutesPerCol
    const durationObj = {
        hours: Math.floor(divide(col, hourDivisor)),
        minutes: modulo(col, hourDivisor) * minutesPerCol
    }
    return startDT.plus(Duration.fromObject(durationObj)).toISO({ includeOffset: false })
})

export const generateTimeLabels = curry((startTime: string, endTime: string, minutesPerCol: number, timeFormat: string) => {
    if (any(isNil, [startTime, endTime, minutesPerCol])) return []
    const startDT = ValidDateTime(startTime)
    const endDT = ValidDateTime(endTime)
    const { hours, minutes, seconds } = detailedDiff(endDT, startDT)
    const roundedHours = minutes || seconds ? hours + 1 : hours
    const colsPerHour = 60 / minutesPerCol
    const labels = times(n => {
        const current = startDT.plus({ hours: n })
        const description = current.toFormat(timeFormat)
        return { col: n * colsPerHour, colSpan: colsPerHour, description }
    }, roundedHours)
    return labels
})

import { Provider } from "@sennen/dashboards-react-client"
import { FormControlProps, FormProps } from "@sennen/dashboard-extension-sennen-core"
import { genericPropGetter, genericPropSetter, integerPropGetter, integerPropSetter, jsPropGetter, jsPropSetter } from "./formGetterSetter"
import { requiredValidator, combineValidators, jsonValidator, jsValidator, regexValidator } from "./formValidators"
import * as R from "ramda"

const required = combineValidators([requiredValidator()])
const requiredJson = combineValidators([requiredValidator(), jsonValidator()])
const requiredJs = combineValidators([requiredValidator(), jsValidator()])

export const tabbedFunctionEditor = (id, label, metaData: { [key: string]: any }) => {
    return hostedComponent(id, label, "TabbedFunctionEditor", metaData)
}

export const dashBoardComponent = (id, label, dashBoardUrl: string, dashBoardParams: { [key: string]: any } = {}) => {
    return hostedComponent(
        id, label, "EditorDashBoardHost",
        { dashBoardUrl, dashBoardParams },
        (d: any, c: FormControlProps, v: any) => {
            return R.prop("validationMessage", genericPropGetter(d, c) || {})
        }
    )
}

export const monacoEditor = (id, label, getter, setter, options) => {
    const componentDef = dashBoardComponent(id, label, "/id/00000000-1010-0000-0000-100000000013", options)
    componentDef.getValueBinding = (d: any, c: FormControlProps) => {
        const value = getter(d, c)
        return { editorNode: value }
    }
    componentDef.setValueBinding = (d: any, c: FormControlProps, next: any) => {
        const { editorNode } = next
        return setter(d, c, editorNode)
    }
    return componentDef
}

export const requiredJavaScriptAce = (id, label, height = "30vh") => monacoEditor(
    id, label, genericPropGetter, genericPropSetter, { height }
)

export const requiredJsonAce = (id, label, height = "30vh"): FormControlProps => monacoEditor(
    id, label, genericPropGetter, genericPropSetter, { height, language: "json" }
)

export const baseControl = (type, id, label) => {
    return {
        id, label, type,
        placeHolderText: "",
        getValueBinding: genericPropGetter,
        setValueBinding: genericPropSetter,
        metaData: {},
        disabled: false
    }
}

export const checkbox = (id, label) => baseControl("checkbox", id, label)

export const requiredStringInput = (id, label) => {
    return {
        ...stringInput(id, label),
        ...{ validationMessage: required }
    }
}

export const stringInput = (id, label): FormControlProps => {
    return {
        id, label,
        type: "input",
        placeHolderText: "Enter text identifier",
        getValueBinding: genericPropGetter,
        setValueBinding: genericPropSetter,
        disabled: false,
        metaData: {},
    }
}

export const markdownEditor = (id, label): FormControlProps => {
    return {
        id, label,
        type: "markdownEditor",
        placeHolderText: null,
        getValueBinding: genericPropGetter,
        setValueBinding: genericPropSetter,
        disabled: false,
        metaData: {},
    }
}

export const textArea = (id, label): FormControlProps => {
    return {
        id, label,
        type: "textArea",
        placeHolderText: null,
        getValueBinding: genericPropGetter,
        setValueBinding: genericPropSetter,
        disabled: false,
        metaData: {},
    }
}

export const requiredJsAce = (id, label, height = "30vh"): FormControlProps => {
    return {
        id, label,
        type: "ace",
        placeHolderText: "",
        getValueBinding: jsPropGetter,
        setValueBinding: jsPropSetter,
        disabled: true,
        metaData: {
            mode: "json",
            height
        },
        validationMessage: requiredJs
    }
}
export const requiredJsonStringAce = (id, label, height = "30vh"): FormControlProps => {
    return {
        id, label,
        type: "ace",
        placeHolderText: "",
        getValueBinding: genericPropGetter,
        setValueBinding: genericPropSetter,
        disabled: false,
        metaData: {
            mode: "json",
            height
        },
        validationMessage: requiredJson
    }
}


export const requiredPgSqlAce = (id, label, height = "30vh"): FormControlProps => {
    return {
        id, label,
        type: "ace",
        placeHolderText: "",
        getValueBinding: genericPropGetter,
        setValueBinding: genericPropSetter,
        disabled: false,
        metaData: {
            mode: "sql",
            height
        },
        validationMessage: required
    }
}

export const requiredPlainTextAce = (id, label, height = "30vh"): FormControlProps => {
    return {
        id, label,
        type: "ace",
        placeHolderText: "",
        getValueBinding: genericPropGetter,
        setValueBinding: genericPropSetter,
        disabled: false,
        metaData: {
            mode: "text",
            height
        },
        validationMessage: required
    }
}



export const hostedComponent = (id, label, componentName, componentProps = {}, validationMessage?): FormControlProps => {
    return {
        id, label,
        type: "hostedComponent",
        hostedComponentName: componentName,
        placeHolderText: "",
        getValueBinding: genericPropGetter,
        setValueBinding: genericPropSetter,
        disabled: false,
        metaData: componentProps,
        validationMessage
    }
}


export const select = (id, label, items: { text: string, value: any }[]): FormControlProps => {
    return R.merge(
        baseControl("select", id, label),
        {
            placeHolderText: "Please select ...",
            metaData: { items }
        }
    )
}

export const requiredSelect = (id, label, items: { text: string, value: any }[]): FormControlProps => {
    return R.merge(
        select(id, label, items),
        {
            validationMessage: required
        }
    )
}

export const idInput = (id, label): FormControlProps => {
    return {
        ...stringInput(id, label),
        ...{
            validationMessage: combineValidators([
                regexValidator("^[a-zA-Z0-9_-]*$", "Invalid value, spaces and special characters are not allowed.")
            ])
        }
    }
}


export const requiredIdInput = (id, label): FormControlProps => {
    return {
        ...stringInput(id, label),
        ...{
            validationMessage: combineValidators([
                requiredValidator(),
                regexValidator("^[a-zA-Z0-9_-]*$", "Invalid value, spaces and special characters are not allowed.")
            ])
        }
    }
}

export const integerInput = (id, label): FormControlProps => {
    return {
        ...stringInput(id, label),
        ...{
            getValueBinding: integerPropGetter,
            setValueBinding: integerPropSetter,
            validationMessage: combineValidators([
                regexValidator("^[0-9]*$", "Invalid number.")
            ])
        }
    }
}

export const requiredTextArea = (id, label) => {
    return {
        ...textArea(id, label),
        ...{ validationMessage: required }
    }
}
import { FormControlProps } from "@sennen/dashboard-extension-sennen-core"
import { defaultTo } from "ramda"
import { pathString, assocPathString } from "./path"

// Generic 
export const genericPropGetter = (d: any, c: FormControlProps) => pathString(c.id, defaultTo({}, d))
export const genericPropSetter = (d: any, c: FormControlProps, next: any) => assocPathString(c.id, next, defaultTo({}, d))
export const integerPropGetter = (d: any, c: FormControlProps) => {
    const value = parseInt(genericPropGetter(d, c), 10)
    return isNaN(value) ? "" : value.toString()
}
export const integerPropSetter = (d: any, c: FormControlProps, next: any) => {
    const value = parseInt(next, 10)
    return genericPropSetter(d, c, isNaN(value) ? null : value)
}

export const jsPropGetter = (d: any, c: FormControlProps) => JSON.stringify(pathString(c.id, defaultTo({}, d)))
export const jsPropSetter = (d: any, c: FormControlProps, next: any) => assocPathString(c.id, JSON.parse(next), defaultTo({}, d))


import { QuickTracksFormControl } from "./QuickTracksForm"
import { any, propEq, split, join } from "ramda"
import moment from "moment"
import { timestamp } from "rxjs/operator/timestamp";

const parseQtDate = (qtControl: QuickTracksFormControl, value: string) => {
    if (!value) return ""
    const dateFormat = (qtControl.Format || "DD/MM/YYYY").toUpperCase()
    return moment(value, dateFormat).format("DD/MM/YYYY")
}

export const defaultValueGenerator = (controlType: string, qtControl: QuickTracksFormControl, currentValue: any) => {
    const { PickList, TimePicker } = qtControl

    if (controlType === "select" && PickList && PickList.Items && PickList.Items && PickList.Items.length) {
        const listContainsValue = any(propEq("Value", currentValue), PickList.Items)
        return listContainsValue ? currentValue : PickList.Items[0].Value
    }

    /* qt date format "DD/MM/YYYY,HH:MM:SSS" */
    if (controlType === "date") return parseQtDate(qtControl, currentValue)

    /* qt datetime format "DD/MM/YYYY,HH:MM:SSS" */
    if (controlType === "dateTime") {
        const defaultTime = TimePicker.ShowMinutes ? (TimePicker.ShowSeconds ? "00:00:00" : "00:00") : "00"
        const timeValue = (TimePicker && TimePicker.ControlValue && TimePicker.ControlValue.ObjectValue) || defaultTime
        return `${parseQtDate(qtControl, currentValue)},${timeValue}`
    }

    return currentValue
}
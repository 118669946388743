import { QuickTracksForm, QuickTracksFormControl, QuickTracksFormPickListItem } from "./QuickTracksForm"
import { Provider } from "@sennen/dashboards-react-client"
import { FormProps, FormControlProps, FormButtonProps } from "@sennen/dashboard-extension-sennen-core"
import {
    compose, head, split, partialRight, last,
    prop, map, fromPairs, pathOr, path
} from "ramda"
import { resolveGetter, resolveSetter } from "./QuickTracksFormBindings"
import { metaDataGenerator } from "./QuickTracksFormMetaData"
import { defaultValueGenerator } from "./QuickTracksFormDefaultValues"
import { validator } from "./QuickTracksFormValidation"
import { isVisible } from "./QuickTracksFormVisibility"
import { hostedComponent } from "../../../helpers/formControls"

const headS = (ary: string[]): string => head(ary) // get round bad type definition in @types/ramda
const lastS = (ary: string[]): string => last(ary) // and again!!!!
const parseTypeId = compose(lastS, split("."), headS, split(","))

const lookupResolver = (lookup: any): (any) => string => partialRight(prop, [lookup]) as any
const isHidden = (d, c) => !isVisible(d, c)

const getQtControlType = (c: QuickTracksFormControl) => {
    let type = parseTypeId(c.TypeId)
    type = type === "DatePicker" ? (c.TimePicker ? "DateTimePicker" : "DatePicker") : type
    type = type === "DropDownList" ? (c.MultiSelect ? "MultiDropDownList" : "DropDownList") : type
    return type
}

const resolveControlType = compose(lookupResolver({
    "RadioButtons": "multiSelect",
    "TextBox": "input",
    "DropDownList": "select",
    "MultiDropDownList": "multiSelect",
    "Label": "textArea",
    "TextBoxMultiLine": "textArea",
    "HtmlBlock": "divider",
    "DatePicker": "date",
    "DateTimePicker": "dateTime",
    "CheckBox": "checkbox"
}), getQtControlType)

const formControlGenerator = (qtControl: QuickTracksFormControl): FormControlProps => {
    const type = resolveControlType(qtControl)
    const isLabel = getQtControlType(qtControl) === "Label"
    return {
        id: `${isLabel ? "label:" : ""}${qtControl.ControlName}`,
        type,
        label: qtControl.LabelText,
        placeHolderText: qtControl.PlaceHolderText,
        getValueBinding: resolveGetter(type),
        setValueBinding: resolveSetter(type),
        metaData: { qtControl },
        hidden: isHidden,
        validationMessage: validator,
        disabled: isLabel,
        readonly: isLabel,
        description: qtControl.TextHint
    } as any
}

const controlMapper = map((qtControl: QuickTracksFormControl) => {
    const control = formControlGenerator(qtControl)
    return {
        ...control,
        ...{
            metaData: {
                ...control.metaData,
                ...metaDataGenerator(control.type, qtControl)
            }
        }
    }
})

const dataMapper = compose(
    fromPairs,
    map((qtControl: QuickTracksFormControl): any => {
        const controlType = resolveControlType(qtControl) as string
        const value = qtControl.ControlValue && qtControl.ControlValue.ObjectValue
        const isLabel = getQtControlType(qtControl) === "Label"
        return [
            `${isLabel ? "label:" : ""}${qtControl.ControlName}`,
            defaultValueGenerator(controlType, qtControl, value)
        ]
    })
)

const buttonMapper = (form: QuickTracksForm): FormButtonProps[] => {
    const submitText = pathOr("submit", ["SubmitButton", "SaveButtonText"], form)
    return [{
        action: "onSubmit",
        text: submitText
    }]
}

const titleMapper = (input: any): string => {
    const urlParamsTitle: string = path(["params", "urlParams", "Title"], input)
    const formTitle: string = path(["data", "rawForm", "Title"], input)
    return urlParamsTitle || formTitle
}

const unauthorisedForm = {
    hideButtonBar: true,
    data: {},
    controls: [hostedComponent("error-alert", "", "Alert", { id: "error-alert-label", type: "warning", title: "Unauthorised:", message: "You are not authorised to access this form." })]
}

export class QuickTracksFormMappingProvider implements Provider {

    public propertyName = "qtFormMapping"

    public createMethod() {
        return (context: any): FormProps => {
            const form: QuickTracksForm = (context.data && context.data.rawForm) || {}
            if (form.unauthorised) return unauthorisedForm
            const allControls = (form.FormControlCollection ? form.FormControlCollection.FormControls : form.FormControls) || []
            const controls = (allControls).filter(c => c.Hidden === false)
            const formProps: FormProps = {
                buttons: buttonMapper(form),
                controls: controlMapper(controls),
                data: dataMapper(controls),
                inlineLabels: true
            }
            formProps["formTitle"] = titleMapper(context)
            formProps.hideButtonBar = !allControls.length
            return formProps
        }
    }

}
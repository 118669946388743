import * as React from "react"
import { Theme, ComponentRender } from "@sennen/dashboards-react-client"
import * as R from "ramda"

export type formControlEvent = (controlId: string, value: any) => void

export interface EditorDashBoardHostProps {
    id: string
    theme?: Theme
    value: string
    height?: string
    disabled?: boolean
    componentRender?: ComponentRender
    onChange?: formControlEvent
    onBlur?: formControlEvent
    confirm?: (message: string, onOk?: () => void, onCancel?: () => void) => void
    dashBoardUrl?: string,
    dashBoardParams?: { [key: string]: any }
}

export class EditorDashBoardHost extends React.Component<EditorDashBoardHostProps, any> {

    private onInvokeAction(actionName, ...args) {
        const { id, value, onBlur } = this.props

        const updateValuePath = (path, updateWith) => {
            const updated = R.assocPath(path, updateWith, value)
            onBlur?.(id, updated)
        }

        if (actionName === "updateValidationMessage") updateValuePath(["validationMessage"], args[0])
        if (actionName === "updateEditorNode") updateValuePath(["editorNode"], args[0])
        if (actionName === "updateEditorNodeByPath") {
            const pathSuffix = args[0]
            if (R.is(Array, pathSuffix)) {
                updateValuePath(R.concat(["editorNode"], pathSuffix), args[1])
            } else {
                console.error(new Error(`Invalid path passed to updateEditorNodeByPath: '${R.toString(pathSuffix)}'`))
            }
        }

    }

    render() {
        const { props } = this
        const { componentRender, dashBoardUrl, dashBoardParams = {}, value } = props
        const updatedParams = Object.assign({}, dashBoardParams, value)
        return componentRender.renderDashBoard(dashBoardUrl, updatedParams, this.onInvokeAction.bind(this))
    }
}
const getRoundedClientRect = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect()
    return {
        x: Math.floor(rect.x),
        y: Math.floor(rect.y),
        width: Math.floor(rect.width),
        height: Math.floor(rect.height),
    }
}

export const trackElementPosition = (element: HTMLElement, onPositionChange: () => void, frequencyMs = 250) => {
    let lastRect = getRoundedClientRect(element)
    const interval = setInterval(
        () => {
            const rect = getRoundedClientRect(element)
            if (
                rect.x !== lastRect.x || rect.y !== lastRect.y ||
                rect.width !== lastRect.width || rect.height !== lastRect.height
            ) {
                lastRect = rect
                onPositionChange()
            }
        },
        frequencyMs
    )
    return () => clearInterval(interval)
}
import { FormProps } from "@sennen/dashboard-extension-sennen-core"
import { JsonSnippetInsertOptions } from "../../../actionItems/JsonInsertSnippetActionItem"
import { GenericEditorActionItemHandlerArgs } from "../../../genericEditor/GenericEditorConfigProviderInterfaces"
import { requiredSelect, requiredIdInput, integerInput } from "../../../../helpers/formControls"
import { map, identity, merge, sortBy, pathOr } from "ramda"

const toTextValue = x => identity({ text: x, value: x })

export const addDataSourceCommand: JsonSnippetInsertOptions = {
    name: "add-ds-cmd",
    buttonText: "DataSource Command",
    buttonIconClassName: "fa fa-plus",
    insertLocation: "child",
    insertPath: ["dataSourceCommands"],
    insertMode: "append",
    template: async data => {
        const { dataSourceId, name } = data
        return {
            "name": name,
            "properties": {},
            "dataSourceId": dataSourceId,
            "authorisation": {}
        }
    },
    templateDataFormResolver: async (args: GenericEditorActionItemHandlerArgs) => {
        const dataSources = pathOr([], ["context", "data", "dataSourceList"], args)
        const dataSourceItems = map(toTextValue, sortBy(identity, dataSources))

        return {
            formTitle: "Add DataSource Command",
            data: {
                dataSourceId: null,
                name: null
            },
            controls: [
                requiredIdInput("name", "Name"),
                requiredSelect("dataSourceId", "Data Source", dataSourceItems)
            ]
        } as FormProps
    }
}

export const addDataSourceCommandSnippet = [addDataSourceCommand]
import { Theme } from "@sennen/dashboards-interfaces"
import { ThemedComponentStyles } from "@sennen/dashboards-react-component"
import Color from "color"

export const styles: ThemedComponentStyles = function (theme: Theme) {
    const { indicatorColors, backgroundColors } = theme

    const defaultColor = Color(backgroundColors.default)
    const bgColor = Color(indicatorColors.warning).alpha(0.05).string()
    const borderColor = Color(indicatorColors.warning).mix(defaultColor, 0.4).string()

    return {
        css: `
         
            .dashboard-editor-debug-overlay {
                box-sizing: border-box;
                position: absolute;
                background-color: ${bgColor};
                border: dashed 1px ${borderColor};
                z-index: 10000000;                
                pointer-events: none;
                border-radius: 3px;
                border-top-right-radius: 0px;
            }   

            .dashboard-editor-debug-overlay.dashboard-editor-debug--hidden {
                display: none;
            }

            .dashboard-editor-debug-handle {
                cursor: pointer;
                position: absolute;
                box-sizing: border-box;
                z-index: 10000000;
                display: grid;
                align-items: center;
                justify-items: center;                
                border-top-right-radius: 3px;
                border-top-left-radius: 3px;
                color: ${backgroundColors.dark};
                background-color: ${borderColor};
            }

            .dashboard-editor-debug-handle.dashboard-editor-debug--hidden {
                border-radius: 3px;
            }

            .dashboard-editor-debug-handle:hover {  
                background-color: ${indicatorColors.warning};
                color: ${backgroundColors.default};
            }

            .dashboard-editor-debug-handle:hover + .dashboard-editor-debug-overlay {
                border-color: ${indicatorColors.warning};
            }

        `
    }
}
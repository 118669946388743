import { Provider } from "@sennen/dashboards-interfaces"
import { RootStoreActions, ClientApp } from "@sennen/dashboards-react-client"
import * as R from "ramda"
const isFunction = R.is(Function)
export class RegisteredProvider implements Provider {

    public propertyName = "registered"

    public createMethod(configType: string, config: any): any {
        return (registeredType: string, callback: any) => {

            // Extract globally registered items
            const globalRegisteredType = {
                "components": "reactComponents",
            }[registeredType] ?? registeredType

            const { globalRegistry } = ClientApp
            const gloallyRegistered = globalRegistry[globalRegisteredType] ?? {}

            return RootStoreActions.providerDispatch(async (dispatch, getState) => {
            
                const es6Map = R.pathOr([], ["root", "app", "registered", registeredType], getState())

                // Extract legacy registrations from store
                const legacyRegistered = R.compose(
                    R.reduce((acc, kv) => {
                        acc[kv[0]] = kv[1]
                        return acc
                    }, {}),
                    R.sortBy(R.nth(0))
                )(Array.from(es6Map))

                if (isFunction(callback)) callback({ ...gloallyRegistered, ...legacyRegistered })
            })
        }
    }

}
import { SennenDate } from "./SennenDate"
import { DateTime } from "luxon"
import { getTimezone } from "./dateTimeUtils"
import * as R from "ramda"

export const quickTracks = {
    parseLegacyDate: (context: any, dateString: string) => {
        if (dateString.endsWith("Z")) {
            const systemTimezone = R.path(["state", "__config", "timezone"], context) as string
            return SennenDate(
                context, 
                DateTime.fromISO(dateString).setZone(systemTimezone).toISODate()
            )
        }
        return SennenDate(context, dateString)
    }
}
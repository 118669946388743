import { toCss } from "@sennen/dashboards-react-client"
import * as ReactHighcharts from "react-highcharts"
const color = ReactHighcharts.Highcharts.Color
export const staticDefaults = (chartTheme) => {
    return {
        chart: {
            type: "heatmap",
            backgroundColor: null,
            style: {
                fontFamily: chartTheme.font
            }
        },
        legend: {
            align: "right",
            layout: "vertical",
            verticalAlign: "middle"
        },
        title: {
            text: "",
            style: {
                fontFamily: chartTheme.titleFont.family,
                color: chartTheme.titleFontColor,
                fontWeight: chartTheme.titleFont.weight,
                fontSize: toCss(chartTheme.titleFont.size)
            }
        },
        subtitle: {
            style: {
                fontFamily: chartTheme.subtitleFont.family,
                color: chartTheme.subtitleFontColor,
                fontWeight: chartTheme.subtitleFont.weight,
                fontSize: toCss(chartTheme.subtitleFont.size)
            }
        },
        plotOptions: {
            heatmap: {
                dataLabels: {
                    enabled: true,
                    format: "{point.labelValue}",
                    style: {
                        fontFamily: chartTheme.labelFont.family,
                        color: chartTheme.labelFontColor,
                        fontWeight: chartTheme.labelFont.weight,
                        fontSize: toCss(chartTheme.labelFont.size),
                        textOutline: null,
                    }
                },
                marker: {
                    enabled: false,
                    lineWidth: 0
                },
                states: {
                    hover: {
                        enabled: false
                    }
                }
            },
            bubble: {
                showInLegend: false,
                color: color(chartTheme.bubbleColor).setOpacity(.4).get("rgba"),
                borderWidth: 0,

            }
        },
        tooltip: {
            enabled: false
        },
        exporting: {
            enabled: false

        },
        credits: false
    }
}

export const multiDefaults = (chartTheme) => {
    return {
        series: {

        },
        xAxis: {
            gridLineColor: chartTheme.gridLineColor,
            labels: {
                style: {
                    color: chartTheme.axisLabelColor
                }
            },
            title: null,
            lineColor: chartTheme.axisLabelColor,
            color: chartTheme.axisLabelColor,
            tickColor: chartTheme.axisLabelColor
        },
        yAxis: {
            gridLineColor: chartTheme.gridLineColor,
            labels: {
                style: {
                    color: chartTheme.axisLabelColor
                }
            },
            title: {
                style: {
                    color: chartTheme.axisTitleColor
                }
            },
            lineColor: chartTheme.axisLabelColor,
            color: chartTheme.axisLabelColor,
            tickColor: chartTheme.axisLabelColor
        },
    }
}
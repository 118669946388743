import * as React from "react"
import { SennenAuthClientService } from "./SennenAuthClientService"
import {
    ConnectedComponentDecorator,
    State, RootStateReader
} from "@sennen/dashboards-react-client"

export interface SennenAuthClientComponentProps {
    checkAuth: () => void,
    checkAuthResponse: () => void
}

const mapStateToProps = (state: State): any => {
    return { state }
}

const mapDispatchToProps = (dispatch: any) => {
    return { dispatch }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { state } = stateProps
    const { dispatch } = dispatchProps

    const rootReader = new RootStateReader(state)
    const displayLoginForm = rootReader.read("app/authorisation/authenticationState", "") === "show-login-prompt"
    const logout = rootReader.read("app/authorisation/authenticationState", "") === "show-logout-prompt"
    const clientConfig = rootReader.read("app/authorisation/config", null)

    const checkConfig = () => {
        if (!clientConfig) {
            console.error(new Error(`SennenAuth: clientConfig not found`))
            return false
        }
        return true
    }

    const props = {
        checkAuth: () => {
            if (displayLoginForm) {
                if (!checkConfig()) return
                SennenAuthClientService.login(clientConfig)
            }
            if (logout) {
                if (!checkConfig()) return
                SennenAuthClientService.logout(clientConfig)
            }
        }
    }
    return { ...ownProps, ...props }
}

@ConnectedComponentDecorator(mapStateToProps, mapDispatchToProps, mergeProps)
export class SennenAuthClientComponent extends React.Component<SennenAuthClientComponentProps> {

    render() {
        this.props.checkAuth()
        return <div id="SennenAuthClientComponent"></div>
    }
}
import { map, filter, compose, isNil, pathOr, prop, slice, assocPath, assoc, dissoc, head, flatten } from "ramda"
import { FormProps, FormControlProps } from "@sennen/dashboard-extension-sennen-core"
import { Provider } from "@sennen/dashboards-react-client"
import {
    genericPropGetter,
    genericPropSetter
} from "../../../helpers/formGetterSetter"
import {
    combineValidators,
    requiredValidator,
    jsonValidator
} from "../../../helpers/formValidators"
import { stringify } from "querystring";
import { LibraryEntry } from "@sennen/dashboards-interfaces"

const filterNil = filter<any>(i => !isNil(i))
const libEntry = e => e && (prop("entry", e) || {})
const libProps = e => e && (prop("properties", e) || {})
const firstLibEntry = e => e && e.length && libEntry(e[0])
const firstLibProps = e => e && e.length && libProps(e[0])
const stringifyPath = (p: string[], obj: {}) => {
    const currentValue = pathOr(null, p, obj)
    return assocPath(p, JSON.stringify(currentValue, null, 4), obj)
}
const parsePath = (p: string[], obj: {}) => {
    const currentValue = pathOr(null, p, obj)
    return assocPath(p, JSON.parse(currentValue), obj)
}

const formDataEntryPropsProp = "__ENTRY_PROPS"
const libEntryToFormData = (entry) => {
    let dsEntry = libEntry(entry)
    let dsProps = libProps(entry)
    return assoc(formDataEntryPropsProp, dsProps, dsEntry)
}
const formDataToLibEntry = (formData): LibraryEntry => {
    return {
        entry: dissoc(formDataEntryPropsProp, formData),
        properties: formData[formDataEntryPropsProp] || {}
    }
}

const required = combineValidators([requiredValidator()])
const requiredJson = combineValidators([requiredValidator(), jsonValidator()])
const json = combineValidators([jsonValidator()])

export class DataSourceEditorProvider implements Provider {

    public propertyName = "dataSourceEditor"

    public createMethod() {
        return function () {
            const methods = {
                "dataSourceList-dataSourceIdList": (i) => {
                    return { ids: i.params.dataSourceMetaDataKey }
                },
                "dataSourceList-dataSources": (i) => {
                    return { ids: firstLibEntry(i.data.dataSourceIdList) }
                },
                "dataSourceList-mapper": (i) => {
                    const dsList: any[] = i.data.dataSources || []
                    if (dsList) {
                        return {
                            rows: map(ds => {
                                const entry = libEntry(ds)
                                const props = libProps(ds)
                                return {
                                    rowId: props.id,
                                    cells: [{ text: entry.id }, { text: `[${entry.connectionId}]` }]
                                }
                            }, filterNil(dsList))
                        }
                    }
                },
                "dataSourceList-rowClick": (providers, row, data) => {
                    return providers.dashBoardState("selectedDataSourceId", row.rowId)
                },
                "dataSourceEditor-dataSource": (i) => {
                    return { ids: i.params.selectedDataSourceId }
                },
                "dataSourceEditor-onSubmit": (providers, formData) => {
                    let parsed = formData
                    parsed = parsePath(["connectionProperties"], parsed)
                    parsed = parsePath(["queryAuthorisation"], parsed)
                    parsed = parsePath(["commandAuthorisation"], parsed)
                    const entry = formDataToLibEntry(parsed)
                    const { id, libraryServiceName } = entry.properties
                    providers.dataSourceCommand("writeDataSource", { entry, id, libraryServiceName }, (r) => {
                        if (r.statusCode === 200) {
                            providers.notify("DataSource Saved", `'${formData.id}' saved.`, { type: "success" })
                        } else if (r.statusCode === 403) {
                            providers.notify("DataSource Save Failed", `Operation not authorised.`, { type: "warning" })
                        } else {
                            providers.notify("DataSource Save Failed", `Failed to save '${formData.id}'.`, { type: "error" })
                        }
                    })
                },
                "dataSourceEditor-mapper": (i): FormProps => {
                    const { dataSource, connectionList } = i.data
                    const hasData = !!(dataSource && dataSource.length && firstLibEntry(dataSource))
                    const connectionItems = map(i => { return { value: i, text: i } }, connectionList || [])
                    const controls: FormControlProps[] = [
                        {
                            type: "input",
                            id: "id",
                            label: "Id",
                            placeHolderText: "Enter text identifier",
                            getValueBinding: genericPropGetter,
                            setValueBinding: genericPropSetter,
                            disabled: !hasData,
                            metaData: {},
                            validationMessage: required
                        },
                        {
                            type: "select",
                            id: "connectionId",
                            label: "Connection",
                            placeHolderText: "Enter text identifier",
                            getValueBinding: genericPropGetter,
                            setValueBinding: genericPropSetter,
                            disabled: !hasData,
                            metaData: {
                                items: flatten([{ value: "", text: "Please select a connection..." }, connectionItems])
                            },
                            validationMessage: required,
                        },
                        {
                            type: "ace",
                            id: "connectionProperties",
                            label: "Connection Properties",
                            placeHolderText: "Enter text identifier",
                            getValueBinding: genericPropGetter,
                            setValueBinding: genericPropSetter,
                            disabled: !hasData,
                            metaData: {
                                mode: "json"
                            },
                            validationMessage: requiredJson
                        },
                        {
                            type: "ace",
                            id: "queryAuthorisation",
                            label: "Query Authorisation",
                            placeHolderText: "Enter text identifier",
                            getValueBinding: genericPropGetter,
                            setValueBinding: genericPropSetter,
                            disabled: !hasData,
                            metaData: {
                                mode: "json",
                                height: "10em"
                            },
                            validationMessage: json
                        },
                        {
                            type: "ace",
                            id: "commandAuthorisation",
                            label: "Command Authorisation",
                            placeHolderText: "Enter text identifier",
                            getValueBinding: genericPropGetter,
                            setValueBinding: genericPropSetter,
                            disabled: !hasData,
                            metaData: {
                                mode: "json",
                                height: "10em"
                            },
                            validationMessage: json
                        }

                    ]
                    let data = {}
                    if (hasData) {
                        data = libEntryToFormData(head(dataSource))
                        data = stringifyPath(["connectionProperties"], data)
                        data = stringifyPath(["queryAuthorisation"], data)
                        data = stringifyPath(["commandAuthorisation"], data)
                    }
                    return { controls, data }
                }
            }

            const methodName = arguments[0]
            const args = slice(1, Infinity, arguments as any)
            return methods[methodName].apply(this, args)
        }
    }

}
import { QuickTracksFormControl } from "./QuickTracksForm"
import { FormControlProps } from "@sennen/dashboard-extension-sennen-core"

export const isVisible = (d: any, c: FormControlProps) => {
    const qtControl: QuickTracksFormControl = c.metaData.qtControl || {}
    const { DependantControl } = qtControl
    if (qtControl && DependantControl && DependantControl.DependedUpon === false) {
        return d[DependantControl.TargetControlName] === DependantControl.TargetControlValue
    }
    return !qtControl.Hidden
}
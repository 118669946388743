import {
    GanttPropsAnnotated,
    GanttItemAnnotated,
    GanttOverlayState,
    GanttStyleProps,
    GanttEventDispatcher
} from "./GanttInterfaces"

import { ComponentElement, ComponentElementRect } from "@sennen/dashboards-react-component"
import * as R from "ramda"

const getMouseCol = (sizeGuideElement: ComponentElement, props: GanttPropsAnnotated, styles: GanttStyleProps) => (): number => {
    if (sizeGuideElement) {
        const pos = sizeGuideElement.getRelativeMousePos()
        pos.left += styles.gridColumnGapHalf
        const sizeRect = sizeGuideElement.getRectRelativeToContainer()
        sizeRect.width += styles.gridColumnGap
        const colWidth = sizeRect.width / props.cols
        return Math.floor((pos.left) / colWidth)
    }
    return 0
}

const colToContainerX = (sizeGuideElement: ComponentElement, props: GanttPropsAnnotated, styles: GanttStyleProps) => (col: number): number => {
    if (sizeGuideElement) {
        const sizeRect = sizeGuideElement.getRectRelativeToContainer()
        const colWidth = (sizeRect.width + styles.gridColumnGap) / props.cols
        return sizeRect.left + (col * colWidth) - styles.gridColumnGapHalf
    }
    return 0
}

export class GanttItemResizeService {

    public onResizeEnd: (state: GanttOverlayState) => void
    public onOverlayStart: (initialState: GanttOverlayState) => GanttOverlayState
    public onResize: (state: GanttOverlayState) => ComponentElementRect

    constructor(sizeGuideElement: ComponentElement, ganttProps: GanttPropsAnnotated, styles: GanttStyleProps, eventDispatcher: GanttEventDispatcher) {
        const resolveMouseCol = getMouseCol(sizeGuideElement, ganttProps, styles)
        const resolveColX = colToContainerX(sizeGuideElement, ganttProps, styles)

        const resizeInCols = (state: GanttOverlayState) => {
            const { cols } = ganttProps
            const item = state.sourceReference as GanttItemAnnotated
            const mouseCol = resolveMouseCol()
            let left = item.col
            let right = item.col + item.colSpan
            if (state.resizeMode === "resize-right") right = R.clamp(left + 1, cols, mouseCol + 1)
            if (state.resizeMode === "resize-left") left = R.clamp(0, right - 1, mouseCol)
            if (state.resizeMode === "move-left-right") {
                const diff = mouseCol - state.startState.mouseCol
                left = R.clamp(0, cols - item.colSpan, left + diff)
                right = left + item.colSpan
            }
            return { left, right }
        }

        const calculateOverlayOffset = (initialState: GanttOverlayState) => {
            const { elementRect } = initialState
            const { col, colSpan } = initialState.sourceReference as GanttItemAnnotated
            const calcRect = {
                left: resolveColX(col),
                width: resolveColX(col + colSpan) - resolveColX(col)
            }
            return {
                left: calcRect.left - elementRect.left,
                width: calcRect.width - elementRect.width
            }
        }

        this.onResizeEnd = (state: GanttOverlayState) => {
            const { left, right } = resizeInCols(state)
            const item = state.sourceReference as GanttItemAnnotated
            eventDispatcher(null, "onUpdate", {
                origin: "item", item,
                updateType: state.resizeMode,
                updates: { col: left, colSpan: right - left, row: item.row, rowSpan: item.rowSpan }
            })
        }

        this.onOverlayStart = (initialState: GanttOverlayState) => {
            return {
                ...initialState,
                startState: {
                    mouseCol: resolveMouseCol(),
                    offset: calculateOverlayOffset(initialState)
                }
            }
        }

        this.onResize = (state: GanttOverlayState): ComponentElementRect => {
            const { left, right } = resizeInCols(state)
            const offset = R.pathOr({ left: 0, width: 0 }, ["startState", "offset"], state)
            return R.compose<any, any, any>(
                R.assoc("left", resolveColX(left) - offset.left),
                R.assoc("width", resolveColX(right) - resolveColX(left) - offset.width)
            )(state.elementRect)
        }

    }
}
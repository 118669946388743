import { Provider, RootStoreActions } from "@sennen/dashboards-react-client"
import { getProviders } from "../../helpers/provider"

const updateFormState = (providers: any, stateKey: string, formProps: any) => {
    const { dashBoardState } = providers
    if (!dashBoardState) throw new Error("Unable to resolve 'dashBoardState' provider")
    if (!stateKey) throw new Error("No 'stateKey' defined")
    dashBoardState(stateKey, formProps)
}

export class QuickTracksFormCreateProvider implements Provider {
    public propertyName = "qtFormCreate"
    public createMethod(configType: string, config: any) {
        return (stateKey, viewId) => {
            return RootStoreActions.providerDispatch((dispatch, getState) => {
                const providers = getProviders(getState, dispatch, configType, config)
                updateFormState(providers, stateKey, { viewId })
            })
        }
    }
}

export class QuickTracksFormEditProvider implements Provider {
    public propertyName = "qtFormEdit"
    public createMethod(configType: string, config: any) {
        return (stateKey, viewId, masterTableId, entityId) => {
            return RootStoreActions.providerDispatch((dispatch, getState) => {
                const providers = getProviders(getState, dispatch, configType, config)
                updateFormState(providers, stateKey, { viewId, masterTableId, entityId })
            })
        }
    }
}

export class QuickTracksFormProvider implements Provider {
    public propertyName = "qtForm"
    public createMethod(configType: string, config: any) {
        return (stateKey, viewId, urlParams) => {
            return RootStoreActions.providerDispatch((dispatch, getState) => {
                const providers = getProviders(getState, dispatch, configType, config)
                updateFormState(providers, stateKey, { viewId, urlParams })
            })
        }
    }
}
import { Provider } from "@sennen/dashboards-react-client"
import { path } from "ramda"

export class QuickTracksFormDsMappingProvider implements Provider {

    public propertyName = "qtFormDsMapping"

    public createMethod() {
        return input => {
            const viewId = path(["params", "viewId"], input)
            if (!viewId) { return false }
            return {
                viewId: viewId, ///// CHANGE THIS TO VIEWID ALL THE WAY DOWN THE STACK!
                masterTableId: path(["params", "masterTableId"], input),
                entityId: path(["params", "entityId"], input),
                urlParams: path(["params", "urlParams"], input)
            }
        }
    }

}
import { Theme } from "@sennen/dashboards-react-client"
import {
    mergeDeepRight, clone,
    mergeWith, times, map, contains,
    addIndex, reduce, toPairs,
    assoc, curry, values, pathOr
} from "ramda"
import { HighChartBase } from "./HighChartBase"
import { staticDefaults, multiDefaults } from "./HighChartDefaults"
import { genLineTheme, genAreaTheme, genColumnTheme, genPieTheme } from "./HighChartThemeGenerators"

const mapIndexed = addIndex(map)
const reduceIndexed = addIndex(reduce)

const getTypeExt = x => (Array.isArray(x) ? "array" : typeof x) as string

const getSeriesType = (chartProps, seriesProps): string => {
    const chartType = chartProps.chart && chartProps.chart.type
    return (seriesProps && seriesProps.type) ? seriesProps.type : (chartType ? chartType : "line")
}

export interface HighChartProps {
    chart: any,
    theme: Theme,
    fillContainer: boolean
}

export class HighChart extends HighChartBase {

    constructor(props) {
        super(props)
    }

    getChartTheme() {
        const { theme } = this.props
        const bgColor = theme.backgroundColors.default

        const isLargeDataSet = this.isLargeDataSet()

        let chartTheme = {
            font: theme.fonts.default.family,
            titleFont: theme.fonts.title,
            titleFontColor: theme.textColors.highIntensity,
            subtitleFont: theme.fonts.default,
            subtitleFontColor: theme.textColors.lowIntensity,
            gridLineColor: theme.backgroundColors.light,
            axisTitleColor: theme.textColors.default,
            axisLabelColor: theme.textColors.lowIntensity,
            legendTextColor: theme.textColors.default,
            dataLabelColor: theme.textColors.highIntensity,
            line: {},
            area: {},
            column: {},
            bar: {},
            pie: {},
            palette: this.palette
        } as any

        const generateThemeStylesForColor = (rootChartTheme, styleName, color) => {
            chartTheme.line[styleName] = genLineTheme(bgColor, color, isLargeDataSet)
            chartTheme.area[styleName] = genAreaTheme(bgColor, color, isLargeDataSet)
            chartTheme.column[styleName] = genColumnTheme(bgColor, color, isLargeDataSet)
            chartTheme.bar[styleName] = genColumnTheme(bgColor, color, isLargeDataSet)
            chartTheme.pie[styleName] = genPieTheme(bgColor, color, isLargeDataSet)
            return rootChartTheme
        }

        // Add Palette Colors
        chartTheme = reduceIndexed((rootChartTheme, color, index) => {
            return generateThemeStylesForColor(rootChartTheme, index + 1, color)
        }, chartTheme, this.palette)

        // Add Indicator Colors
        chartTheme = reduce((rootChartTheme, indicator) => {
            return generateThemeStylesForColor(rootChartTheme, indicator[0], indicator[1])
        }, chartTheme, toPairs(theme.indicatorColors))


        chartTheme["spline"] = chartTheme.line;
        chartTheme["areaspline"] = chartTheme.area;
        return chartTheme;
    }

    mergeOptions(chart: any, chartTheme: any) {
        if (chart && chart.series) {
            const isSingle = !(getTypeExt(chart.series) === "array")
            const series = isSingle ? [chart.series] : chart.series
            const newSeries = mapIndexed((s: any, i) => {
                const chartType = getSeriesType(chart, s)
                if (chartType && contains(chartType, ["area", "line", "column", "spline", "areaspline", "pie", "bar"])) {
                    let style = s.themeStyle || (i < this.palette.length ? (i + 1) : 1)
                    style = style && style.toString() // coerce to string if not null
                    return mergeDeepRight(chartTheme[chartType][style], s)
                }
                return s
            }, series)
            chart.series = isSingle ? newSeries[0] : newSeries
            chart.time = {
                ...chart.time ?? {},
                useUTC: true
            }
        }
        return chart
    }

    private isLargeDataSet() {
        const series = pathOr([], ["props", "chart", "series"])(this)
        let max = 0
        map(s => {
            if (s.data && (s.data.length > max)) max = s.data.length
        }, series)
        return max > 50
    }

    getStaticDefaults(chartTheme: any) {
        return staticDefaults(chartTheme)
    }

    getMultiDefaults(chartTheme: any) {
        return multiDefaults(chartTheme)
    }

}
import { ProviderDefinition, PropStore } from "@sennen/dashboards-react-client"

const updateFormState = (store: PropStore, stateKey: string, formProps: any) => {
    if (!stateKey) throw new Error("No 'stateKey' defined")
    store.updateProps([[[stateKey], formProps]])
}

export const QuickTracksFormCreateProvider = {
    name: "qtFormCreate",
    causesMutation: true,
    createMethod: options => {
        return (stateKey, viewId) => {
            updateFormState(options.propStore, stateKey, { viewId })
        }
    }
} as ProviderDefinition

export const QuickTracksFormEditProvider = {
    name: "qtFormEdit",
    causesMutation: true,
    createMethod: options => {
        return (stateKey, viewId, masterTableId, entityId) => {
            updateFormState(options.propStore, stateKey, { viewId, masterTableId, entityId })
        }
    }
} as ProviderDefinition

export const QuickTracksFormProvider = {
    name: "qtForm",
    causesMutation: true,
    createMethod: options => {
        return (stateKey, viewId, urlParams) => {
            updateFormState(options.propStore, stateKey, { viewId, urlParams })
        }
    }
} as ProviderDefinition
import * as ReactHighcharts from "react-highcharts"
const color = ReactHighcharts.Highcharts.Color

export const genAreaTheme = (bgColor, color1, isLargeDataSet) => {

    return {
        color: color1,
        fillColor: {
            linearGradient: [0, 0, 0, "75%"],
            stops: [
                [0, color(color1).setOpacity(0.05).get("rgba")],
                [1, color(color1).setOpacity(0.05).get("rgba")]
            ]
        },
        lineWidth: isLargeDataSet ? 1 : 2,
        marker: {
            enabled: !isLargeDataSet,
            fillColor: color1,
            lineWidth: 2,
            radius: 5,
            lineColor: bgColor,
            symbol: "circle"
        }
    }
}

export const genLineTheme = (bgColor, color1, isLargeDataSet) => {
    return {
        color: color1,
        lineWidth: isLargeDataSet ? 1 : 2,
        marker: {
            enabled: !isLargeDataSet,
            lineWidth: 2,
            radius: 5,
            lineColor: bgColor,
            symbol: "circle"
        }
    }
}

export const genColumnTheme = (bgColor, color1, isLargeDataSet) => {
    return {
        color: color1,
        borderColor: bgColor,
        borderWidth: isLargeDataSet ? 0 : 2
    }
}

export const genPieTheme = (bgColor, color1, isLargeDataSet) => {
    return {
        borderColor: bgColor
    }
}
import * as React from "react"
import { pathOr, path, curry } from "ramda"
import * as R from "ramda"
import { GenericEditorConfig } from "./GenericEditorConfigProviderInterfaces"
import { setBusyIfNotEditing, showRunCommandResult, runCommand, runCommandNonBlocking } from "./GenericEditorProviderShared"
import { queryStringAsObject } from "../../helpers/url"
import { syncShowDashBoard, syncShowModalForm } from "./GenericEditorProviderSyncMethods"
import * as uuid from "uuid"

const isNilOrEmpty = R.either(R.isNil, R.isEmpty)

const confirmActionIfValid = (providers, params, callBack) => {
    const { lastSync } = params
    if (lastSync && !lastSync.info.isValid) {
        providers.confirm("Some fields on this page contain errors, changes may not be saved. Do you want to continue?", () => callBack())
    } else {
        callBack()
    }
}

const getPublishErrors = (editing: any) => {
    const editingEntry = editing.entry
    const editingString = JSON.stringify(editingEntry)

    const errors = []
    if (R.contains("debugger", editingString)) errors.push("Contains 'debugger' statements")
    if (R.contains("console.log", editingString)) errors.push("Contains 'console.log' statements")
    if (R.contains(":999999", editingString)) errors.push("Contains references to development dashboards ':999999'")
    if (
        isNilOrEmpty(R.path(["authorisation", "allow"], editingEntry)) &&
        isNilOrEmpty(R.path(["authorisation", "deny"], editingEntry))
    ) {
        errors.push("No roles set on 'authorisation.allow' or 'authoristation.deny'")
    }
    if (errors.length) return errors
    return null
}

const appendViewUrlParams = (url) => {
    const decoded = queryStringAsObject()
    const { viewUrlParams } = decoded
    return viewUrlParams ? `${url}?${decodeURIComponent(viewUrlParams)}` : url
}

export const buttonBarMapper = (providers, descriptorProviderName, i) => {
    return setBusyIfNotEditing(i, {})
}

export const buttonBarOnClick = (providers, descriptorProviderName, context, buttonName) => {

    setTimeout(() => providers.dashBoardState("buttonBarAction", {
        timeStamp: Date.now(),
        buttonName
    }), 100)

}

export const buttonBarDeferredOnClick = (providers, descriptorProviderName, context) => {

    const buttonName = R.path(["params", "buttonBarAction", "buttonName"], context)
    const loadState: string = pathOr("init", ["params", "loadState"], context)
    const requestedId = path(["params", "requestedId"], context)
    const devId = path(["params", "devId"], context)
    const unSavedChanges = path(["params", "unSavedChanges"], context)

    if (loadState !== "editing") return

    switch (buttonName) {

        case "new":
            syncShowModalForm(providers, context, "new");
            break

        case "docs":
            providers.navigation.modalDashBoard("id/00000000-1010-0000-0000-100000000000")
            break

        case "library":
            providers.navigation.openUrl("/id/dashboards-editor.sennen-library?menu=false")
            break

        case "discard":
            const hasDevVersion = pathOr("init", ["params", "previewAvailable"], context)
            if (!hasDevVersion) {
                providers.notify(`Discard Failed`, `No development version exists. The dashboard has not been saved.`, { type: "warning" })
                return
            }
            providers.confirm(
                "## Confirm Deletion\n\nAre you sure you want to **discard** the **development version (:999999)** of this dashboard? This action will **permanently delete** it for all users, and the dashboard cannot be recovered.",
                () => runCommand(
                    providers, descriptorProviderName, context, "discard-dev", null,
                    (editorConfig: GenericEditorConfig, commandData, result) => {
                        if (result.statusCode == 200) {
                            window.location.reload()

                        } else {
                            const message = result.body ?? "Failed to discard development version"
                            console.log(message, result)
                            providers.notify("Error", `${message}.\n\n See console for more detail.`, { type: "error" })

                        }
                    }
                )

            )
            break

        case "save":

            const showResAndSetPreviewState = curry((providers: any, editorConfig: GenericEditorConfig, commandData, result) => {
                if (result.statusCode === 200) providers.dashBoardState("loadState", "saved")
                providers.focus.dashPart("dashBoardEditor")
                return showRunCommandResult(providers, editorConfig, commandData, result)
            })

            confirmActionIfValid(providers, context.params, () => {

                runCommand(providers, descriptorProviderName, context, "save", null, (editorConfig: GenericEditorConfig, commandData, result) => {
                    if (result.statusCode === 409) {
                        providers.confirm(
                            result.body,
                            () => runCommand(providers, descriptorProviderName, context, "overwrite", null, showResAndSetPreviewState(providers)),
                            () => showResAndSetPreviewState(providers, editorConfig, commandData, result)
                        )
                    } else {
                        showResAndSetPreviewState(providers, editorConfig, commandData, result)
                    }
                })
            })
            break

        case "publish":

            if (unSavedChanges) {
                providers.notify("Unsaved Changes", "Please save your changes before publishing", { type: "warning" })
                break
            }

            const publishErrors = getPublishErrors(context.params.editingDashBoard)

            if (publishErrors) {
                const message = R.compose(
                    R.join("\n"),
                    R.map(m => `* ${m}.`)
                )(publishErrors)
                providers.confirm(`## PUBLISH FAILED\n${message}`)
                break
            }

            publishDashBoard(providers, context, descriptorProviderName)

            break

        case "live":
            window.open(appendViewUrlParams(`/id/${requestedId}`))
            break

        case "preview":
            const previewAvailable = pathOr("init", ["params", "previewAvailable"], context)
            if (previewAvailable) {
                window.open(appendViewUrlParams(`/id/${devId}`))
            } else {
                providers.notify(`No Preview Available`, `Please 'Save' your changes`, { type: "warning" });
            }
            break


    }

}

const publishDashBoard = (providers: any, context: any, descriptorProviderName: any) => {

    let publishResult = null
    let progressClosed = false

    syncShowDashBoard(providers, context,
        {
            dashBoardUrl: "/id/00000000-2222-0000-9999-000000000005",
            dashBoardParams: {
                title: "Please enter a publish comment",
                okButtonOptions: { text: "publish", iconClassName: "fa fa-code-fork", type: "successIndicator" },
                cancelButtonOptions: { text: "cancel", type: "highIntensityText" },
                defaultComment: R.path(["params", "editingDashBoard", "properties", "changeDetails", "changeReason"], context)
            }
        },
        ({ eventName, eventData }) => {
            if (eventName === "onResult" && !R.isNil(eventData)) {
                const changeDetails = { ...eventData, timestamp: Date.now() }
                confirmActionIfValid(providers, context.params, () =>
                    providers.confirm("## CONFIRM PUBLISH\nDo you wish to continue?", () => publish(changeDetails))
                )
            }
        }
    )

    let sendProgressDashBoardMessage = (message) => console.warn("No progress dashboard message handler set", message)

    const redirectOnPublishOrShowError = curry((providers: any, editorConfig: GenericEditorConfig, commandData, result) => {
        const success = result.statusCode === 200
        publishResult = success ? "success" : "error"
        sendProgressDashBoardMessage?.({ publishResult })
        if (progressClosed) {
            return showRunCommandResult(providers, editorConfig, commandData, result)
                .then(() => reloadEditor(2000))
        }
    })

    const reloadEditor = (delay) =>
        setTimeout(() => window.location.reload(), delay)

    const publish = (changeDetails) => {
        const progressTrackingId = uuid.v4()

        syncShowDashBoard(
            providers, context,
            {
                dashBoardUrl: "/id/dashboards-editor.publish-status",
                dashBoardParams: { progressTrackingId, onMessage: (cb) => sendProgressDashBoardMessage = cb }
            },
            () => {
                progressClosed = true
                if (publishResult) reloadEditor(50)
            }
        )
        // Make editor busy so no updates can be made during publish process
        providers.dashBoardState("loadState", "publishing")
        runCommandNonBlocking(
            providers, descriptorProviderName, context, "publish", { changeDetails, progressTrackingId },
            (editorConfig: GenericEditorConfig, commandData, result) => {
                if (result.statusCode === 409) {
                    providers.confirm(
                        result.body,
                        () => runCommandNonBlocking(
                            providers, descriptorProviderName, context, "force-publish", null,
                            () => redirectOnPublishOrShowError(providers, editorConfig, commandData, result)
                        ),
                        () => redirectOnPublishOrShowError(providers, editorConfig, commandData, result)
                    )
                } else {
                    redirectOnPublishOrShowError(providers, editorConfig, commandData, result)
                }
            }
        )
    }

}

import { split, path, is } from "ramda"
const isString = is(String)

export interface VersionId {
    id: string
    version: number
}

export const fromIdString = (id): VersionId => {
    const idParts = split(":", id || "")
    return {
        id: path([0], idParts),
        version: parseInt(path([1], idParts), 10)
    }
}

export const versionIdToIdString = (id: VersionId): string => {
    return `${id.id}:${id.version}`
}

export function toIdString(id: string, version: number): string
export function toIdString(id: VersionId): string
export function toIdString(id: any, version: number = null): string {
    if (isString(id)) {
        return versionIdToIdString({ id, version })
    } else {
        return versionIdToIdString(id)
    }
}

export const toDevIdString = (id: string): string => {
    const vid = fromIdString(id)
    vid.version = 999999
    return toIdString(vid)
}
import { Button } from "@sennen/dashboard-extension-sennen-core"
import { Theme, toCss } from "@sennen/dashboards-react-client"
import { ComponentElement, ThemedComponentDecorator } from "@sennen/dashboards-react-component"
import * as R from "ramda"
import * as React from "react"


export interface FormButtonBarButtonProps {
    id: string
    text: string,
    type: string,
    name: string,
    align: "left" | "right"
    divider?: "left" | "right"
    readonly: boolean
}

export interface FormButtonBarButtonEventOptions extends FormButtonBarButtonProps {
    targetElement: ComponentElement
}

export interface FormButtonBarProps {
    theme?: Theme
    buttons: FormButtonBarButtonProps[]
    onButtonClick: (name: string, option: FormButtonBarButtonEventOptions) => void,
    disabled: boolean
}


@ThemedComponentDecorator((theme: Theme) => {
    const { padding } = theme
    const { medium } = padding
    const medPad = toCss(medium)
    return {
        css: `
            @id .formButtonBar {
                
            }
            @id .formButtonBar .formButtonBar-left {
                margin-right: ${medPad};
            } 
            @id .formButtonBar .formButtonBar-left:last-child {
                margin-right: 0;
            } 
            @id .formButtonBar .formButtonBar-right {
                float: right;
                margin-left: ${medPad};
            } 
            @id .formButtonBar .formButtonBar-divider-left {
                border-left: solid 1px ${theme.backgroundColors.lighter};
                padding-left: ${medPad};
            } 
            @id .formButtonBar .formButtonBar-divider-right {
                border-right: solid 1px ${theme.backgroundColors.lighter};
                padding-right: ${medPad};
            } 

        `
    }
})
export class FormButtonBar extends React.Component<FormButtonBarProps, any> {
    public render() {
        const { disabled } = this.props
        const buttons = R.map((b: FormButtonBarButtonProps) => {
            const onClick = (t, o) => {
                const options = R.merge(b, { targetElement: o.targetElement })
                this.props.onButtonClick(b.name || b.text, options)
            }
            const alignClass = "formButtonBar-" + ((b.align === "right") ? "right" : "left")
            const classNames = [alignClass]
            classNames.push(`formButtonBar-divider-${b.divider || "none"}`)
            const overrides = {} as any
            if (disabled && !b.readonly) overrides.disabled = true
            const buttonProps = {
                theme: this.props.theme,
                onClick,
                ...b,
                ...overrides
            }
            return <span key={b.name} className={R.join(" ", classNames)}>
                <Button {...buttonProps}> {b.text} </Button >
            </span >
        }, R.defaultTo([], this.props.buttons))
        return this["renderCssContainer"](<div className="formButtonBar cf">{buttons}</div>)
    }
}

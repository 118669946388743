import { slice } from "ramda"
import { Provider } from "@sennen/dashboards-react-client"

import { editorMapper, editorOnControlEvent, editorOnChangeSync } from "./GenericEditorProviderEditorDashPart"
import { modaFormSubmit, modalFormControlEvent, modalFormMapper, modalFormOnSync } from "./GenericEditorProviderModalFormDashPart"
import { jsonMapper, jsonOnSync } from "./GenericEditorProviderJsonDashPart"
import { dataPipeDsMapper, dataPipeMapper, dataPipeLoadStateWatcherMapper, dataPipeLoadStateWatcherOnChange } from "./GenericEditorProviderDataPipeDashPart"
import { asyncConfirmCheck, asyncShowDashBoardCheck } from "./GenericEditorProviderAsyncMethods"
import { explorerMapper, explorerRowClick } from "./GenericEditorProviderExplorerDashPart"
import { buttonBarMapper, buttonBarOnClick, buttonBarDeferredOnClick } from "./GenericEditorProviderButtonBarDashPart"
import { modalDataFormMapper, modalDataFormSubmit } from "./GenericEditorProviderModalDataFormDashPart"
import { titleBarMapper } from "./GenericEditorTitleDashPart"

export class GenericEditorProvider implements Provider {

    public propertyName = "genericEditor"

    public createMethod() {
        return function () {
            const methods = {
                "dataPipeLoadStateWatcher-mapper": dataPipeLoadStateWatcherMapper,
                "dataPipeLoadStateWatcher-onChange": dataPipeLoadStateWatcherOnChange,

                "dataPipe-dsParams": dataPipeDsMapper,
                "dataPipe-mapper": dataPipeMapper,

                "explorer-mapper": explorerMapper,
                "explorer-rowClick": explorerRowClick,

                "json-onsync": jsonOnSync,
                "json-mapper": jsonMapper,

                "modal-form-mapper": modalFormMapper,
                "modal-form-control-event": modalFormControlEvent,
                "modal-form-submit": modaFormSubmit,
                "modal-form-onSync": modalFormOnSync,

                "editor-mapper": editorMapper,
                "editor-onChangeSync": editorOnChangeSync,
                "editor-onControlEvent": editorOnControlEvent,

                "buttonBar-mapper": buttonBarMapper,
                "buttonBar-onButtonClick": buttonBarOnClick,
                "buttonBar-onDeferredButtonClick": buttonBarDeferredOnClick,
                
                "title-mapper": titleBarMapper,

                "modal-data-form-mapper": modalDataFormMapper,
                "modal-data-form-submit": modalDataFormSubmit,

                "explorer-timertick": (providers, descriptorProviderName, context) => {
                    asyncConfirmCheck(providers, context)
                    asyncShowDashBoardCheck(providers, context)
                },
            }
            const methodName = arguments[0]
            const args = slice(1, Infinity, arguments as any)
            return methods[methodName].apply(this, args)
        }
    }


}
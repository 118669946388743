import * as R from "ramda"
import { FormProps, SyncInfo } from "@sennen/dashboard-extension-sennen-core"
import { GenericEditorConfig } from "./GenericEditorConfigProviderInterfaces"
import { resolveEditorConfig, runCommand, showRunCommandResult } from "./GenericEditorProviderShared"
import { hostedComponent, requiredStringInput, requiredSelect } from "../../helpers/formControls"
import { requiredValidator, combineValidators, jsonValidator, jsValidator, regexValidator } from "../../helpers/formValidators"
import * as uuid from "uuid"

const isNilOrEmpty = x => R.isNil(x) || R.isEmpty(x)
const validNamespaces = ["dashboards-editor", "components-core"]

export const modalFormMapper = (providers, descriptorProviderName, context): FormProps => {
    const editorConfig = resolveEditorConfig(providers, descriptorProviderName)
    const { entityDescription } = editorConfig
    const loadState = R.path(["params", "loadState"], context) as string
    const modalSyncData = R.path(["params", "modalSyncData"], context) || {} as any
    const libraryServices = R.path(["data", "libraryServices"], context) as string[]

    const form: FormProps = {
        controls: [],
        data: {},
        inlineLabels: true,
        buttons: []
    }
    if (loadState === "editing" && !isNilOrEmpty(libraryServices)) {
        const arrayToSelectItems = x => { return { text: x, value: x } }

        const editingLibraryServiceName = R.path(["params", "editingDashBoard", "properties", "libraryServiceName"], context) as string

        form.data = modalSyncData
        if (!form.data.libraryServiceName) form.data.libraryServiceName = editingLibraryServiceName
        if (!form.data.template) form.data.template = "new"

        form.controls.push(
            R.merge(
                requiredStringInput("id", `${entityDescription} ID`),
                {
                    placeHolderText: `Click below to generate a new ${entityDescription} ID`, disabled: !modalSyncData.idUnlocked,
                    validationMessage: combineValidators([
                        requiredValidator(),
                        (data = {} as any) => {
                            if (!R.isNil(data.id)) {
                                const validUuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(data.id)
                                if (validUuid) return null
                                const parts = R.split(".", data.id)
                                if (parts.length !== 2) return "id must be a UUID(v4) or match pattern 'namespace.id'."
                                const [namespace, id] = parts
                                if (!R.contains(namespace, validNamespaces)) return `Invalid namespace. Valid: ${R.toString(validNamespaces)}.`
                                if (R.isEmpty(id)) return "An id following a namespace cannot be empty."
                            }
                            return null
                        }
                    ])
                }
            )
        )

        form.controls.push(hostedComponent("buttonBar", null, "FormButtonBar", {
            buttons: [
                { text: "Generate UUID", name: "generateId", type: "primary" },
                {
                    text: "", iconAlign: "center", minWidth: "auto",
                    iconClassName: `fa fa-${modalSyncData.idUnlocked ? "unlock" : "lock"}`,
                    name: "unlock", type: "system", align: "right", shortcutKey: "^U,u"
                },
            ],
            events: ["onButtonClick"]
        }))

        form.controls.push(
            R.merge(requiredStringInput("description", `Description`), { placeHolderText: `Please enter a ${entityDescription} description` })
        )

        form.controls.push(
            requiredSelect("libraryServiceName", "Library Service", R.map(arrayToSelectItems, libraryServices))
        )

        form.buttons = [
            {
                action: "onSubmit",
                text: "Create"
            }
        ]

    }

    return form
}

export const modalFormControlEvent = (providers, descriptorProviderName, context, controlId, eventName, eventParams) => {
    if (controlId === "buttonBar" && eventName === "onButtonClick") {
        const buttonId = eventParams[0]
        const { modalSyncData = {} } = context.state
        if (buttonId === "generateId") providers.dashBoardState("modalSyncData/id", uuid.v4())
        if (buttonId === "unlock") providers.dashBoardState("modalSyncData/idUnlocked", !modalSyncData.idUnlocked)
    }
}

export const modaFormSubmit = (providers, descriptorProviderName, context, formData) => {
    runCommand(providers, descriptorProviderName, context, "new", formData, (editorConfig: GenericEditorConfig, commandData, result) => {
        const showResult = () => showRunCommandResult(providers, editorConfig, commandData, result)
        if (result.statusCode === 409) {
            providers.confirm(result.body, showResult, showResult)
        } else if (result.statusCode == 200) {
            providers.navigation.changeDashBoard("id/00000000-2222-0000-9999-000000000003", { "dashBoardId": formData.id })
        } else {
            showResult()
        }
    })
}

export const modalFormOnSync = (providers, descriptorProviderName, context, formData, info: SyncInfo) => {
    if (info.hasChanged) providers.dashBoardState("modalSyncData", formData)
} 
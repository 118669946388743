import { toCss, renderFontCss } from "@sennen/dashboards-react-client"
export const staticDefaults = (chartTheme) => {
    return {
        
        chart: {
            type: "solidgauge",
            backgroundColor: null,
            style: {
                fontFamily: chartTheme.font
            }
        },
        pane: {
            size: "160%",
            center: [
                "50%",
                "85%"
            ],
            endAngle: 90,
            background: {
                shape: "arc",
                innerRadius: "60%",
                outerRadius: "70%",
            },
            startAngle: -90
        },
        title: {
            text: "",
            style: {
                fontFamily: chartTheme.titleFont.family,
                color: chartTheme.titleFontColor,
                fontWeight: chartTheme.titleFont.weight,
                fontSize: toCss(chartTheme.titleFont.size)
            }
        },
        subtitle: {
            style: {
                fontFamily: chartTheme.subtitleFont.family,
                color: chartTheme.subtitleFontColor,
                fontWeight: chartTheme.subtitleFont.weight,
                fontSize: toCss(chartTheme.subtitleFont.size)
            }
        },
        yAxis: {
            max: 100,
            min: 0,
            stops: [
                [
                    0.1, "danger"
                ],
                [
                    0.5, "warning"
                ],
                [
                    1, "success"
                ]
            ],
            title: {
                y: -70
            },
            labels: {
                y: 16
            },
            endOnTick: true,
            lineColor: chartTheme.gridLineColor,
            lineWidth: 2,
            tickColor: chartTheme.gridLineColor,
            tickAmount: 0,
            tickInterval: 1,
            minorTickInterval: null
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    useHTML: true,
                    borderWidth: 0
                }
            }
        },
        tooltip: {
            enabled: false
        },
        exporting: {
            enabled: false

        },
        credits: false
    }
}

export const multiDefaults = (chartTheme) => {
    const label = `<span style="${renderFontCss(chartTheme.labelFont)}color:${chartTheme.labelColor};display:block;">{point.labelText}</span>`
    const subLabel = `<span style="${renderFontCss(chartTheme.subLabelFont)}color:${chartTheme.subLabelColor};display:block;">{point.subLabelText}</span>`
    return {
        series: { 
            dataLabels: {
                format: `<div style="text-align:center">${label}${subLabel}</div>`
            }
        }
    }
}
import {
    timeToCol, colToTime, generateTimeLabels, convertZonedDateTime
} from "./InternalTime"

import {
    layoutContainer, matchContainerRowSpan, calcContainerSpaces
} from "./Container"

import { Provider } from "@sennen/dashboards-react-client"

export class GanttProvider implements Provider {

    public propertyName = "gantt"

    public createMethod(configType: string, config: any): any {
        return {
            timeToCol,
            colToTime,
            convertZonedDateTime,
            generateTimeLabels,
            layoutContainer,
            matchContainerRowSpan,
            calcContainerSpaces
        }
    }

}



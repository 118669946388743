import * as R from "ramda"

export const asyncConfirm = (question, answerPath) => {
    return {
        asyncConfirm: {
            question,
            answerPath,
            visible: false
        }
    }
}

export const asyncConfirmCheck = (providers, context) => {
    const confirm = R.path(["params", "asyncConfirm"], context) as any
    if (confirm && !confirm.visible) {
        providers.dashBoardState({
            "asyncConfirm/visible": true,
            "asyncConfirm/result": null
        })
        const cbResult = r => () => providers.dashBoardState(confirm.answerPath, r)
        providers.confirm(confirm.question, cbResult("ok"), cbResult("cancel"))
    }
}

export const getAsyncDashBoardEvent = (providers, context) =>
    R.path(["params", "showDashBoardEvent"], context) as any

export const asyncShowDashBoardCheck = (providers, context) => {
    const dashBoard = R.path(["params", "showDashBoard"], context) as any
    const onEvent = eventName => eventData =>
        providers.dashBoardState({
            "showDashBoard": null,
            "showDashBoardEvent": { eventName, eventData }
        })

    if (dashBoard && !dashBoard.visible && dashBoard.mode === "async") {
        providers.dashBoardState("showDashBoard/visible", true)
        providers.navigation.modalLayout("modalDashBoardLayout", {
            resultCallBack: onEvent("onResult"),
            cancelCallBack: onEvent("onCancel")
        })
    }
}

export const asyncShowDashBoard = (options) => {
    return {
        showDashBoardEvent: null,
        showDashBoard: {
            ...options,
            visible: false,
            mode: "async"
        }
    }
}

import * as React from "react"

export class FileViewer extends React.Component<any, any> {
    public render() {
        return <h1>FileViewer Component Obsolete, please use 'Embed' component</h1>
    }
}

// import { Theme } from "@sennen/dashboards-interfaces"
// import { Button } from "@sennen/dashboards-react-client"
// import { ThemedComponentDecorator } from "@sennen/dashboards-react-component"
// import { join } from "ramda"
// import * as FileViewerComponent from 'react-file-viewer';
// import * as R from "ramda"

// export interface FilePreviewProps {
//     fileName?: string
//     base64Data?: string
//     url?: string
//     downloadEnabled?: boolean
//     theme?: Theme
// }

// const getMimeType = (fileType) => {
//     const types = {
//         docx: "application/vndopenxmlformats-officedocumentwordprocessingmldocument",
//         xlsx: "application/vndopenxmlformats-officedocumentwordprocessingmldocument",
//     }
//     return types[fileType] || `application/${fileType}`
// }

// const getDataUrl = (fileName, data) => {
//     return `data:${getMimeType(fileName)};base64,${data}`
// }

// const downloadButtonClick = (fileName, data) => e => {
//     const base64ToArrayBuffer = (data) => {
//         const binaryString = window.atob(data);
//         const binaryLen = binaryString.length;
//         const bytes = new Uint8Array(binaryLen);
//         for (let i = 0; i < binaryLen; i++) {
//             const ascii = binaryString.charCodeAt(i);
//             bytes[i] = ascii;
//         }
//         return bytes;
//     }

//     const arrBuffer = base64ToArrayBuffer(data);
//     const newBlob = new Blob([arrBuffer], { type: getMimeType(getFileExtension(fileName)) });
//     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//         window.navigator.msSaveOrOpenBlob(newBlob);
//         return;
//     }

//     const objectUrl = window.URL.createObjectURL(newBlob);

//     const link = document.createElement('a');
//     document.body.appendChild(link); //required in FF, optional for Chrome
//     link.href = objectUrl;
//     link.download = fileName;
//     link.click();
//     window.URL.revokeObjectURL(objectUrl);
//     link.remove();
// }

// const getFileExtension = R.pipe(R.takeLast(4), R.when(R.contains("."), R.takeLast(3)))


// @ThemedComponentDecorator((theme: Theme) => {
//     return {
//         css: `
//             @id .fileViewer {
//                 min-height: 400px;
//             }
//             @id .downloadButton {
//                 margin-right: 10px;
//             } 
//             @id .topPanel {
//                 text-align: right;
//                 padding: 16px;
//             }

//             @id .issuesNotice {
//                 padding: 10px;
//             }


//         `
//     }
// })


// export class FileViewer extends React.Component<FilePreviewProps, any> {
//     public render() {
//         return this["renderCssContainer"](

//             <div>
//                 {
//                     (this.props.downloadEnabled && this.props.base64Data) &&
//                     <div className="topPanel">
//                         <Button className="downloadButton" theme={this.props.theme} iconClassName="fa fa-download" onClick={downloadButtonClick(this.props.fileName, this.props.base64Data)} text="Download" type="system" />
//                     </div>

//                 }

//                 <FileViewerComponent className={"fileViewer"}
//                     fileType={getFileExtension(this.props.fileName)}
//                     filePath={this.props.base64Data ? getDataUrl(this.props.fileName, this.props.base64Data) : this.props.url}
//                 />
//                 {
//                     (this.props.downloadEnabled && this.props.base64Data) &&
//                     <span className="issueNotice">
//                         If you have issues viewing, please download instead
//                     </span>
//                 }
//             </div>
//         )
//     }
// }
import { GenericEditorConfig, GenericEditorDescriptor, GenericEditorAlert, GenericEditorAlertType } from "./GenericEditorConfigProviderInterfaces"
import { descriptorByMatch, setBusyIfNotEditing, resolveEditorConfig, resolveEditorAlertStyles } from "./GenericEditorProviderShared"
import { matchPaths } from "../helpers/configEditing"
import { pathString } from "../../helpers/path"
import {
    values, split, join, path, pathOr, defaultTo,
    pick, assocPath, curry, map, pluck, compose,
    reduce, dissoc, sortBy, prop, replace, merge, is, sortWith, ascend, startsWith
} from "ramda"
import { Theme } from "@sennen/dashboards-interfaces"
interface PathDetails {
    match: string
    path: string
    descriptor: GenericEditorDescriptor
}

const confirmActionIfValid = (providers, params, callBack) => {
    const { lastSync } = params
    if (lastSync && !lastSync.info.isValid) {
        providers.confirm("Some fields on this page contain errors, changes will not be saved. Do you want to continue?", () => callBack())
    } else {
        callBack()
    }
}

const getPathDetails = curry((config: GenericEditorConfig, pathMatchPair: any): PathDetails => {
    const path = pathMatchPair[0]
    const match = pathMatchPair[1]
    const descriptor = descriptorByMatch(config.descriptors, pathMatchPair[1])
    return { path, match, descriptor }
})

const genMDownIcon = (theme: Theme, alert: GenericEditorAlert, defaultIcon, defaultColor) => {
    const { color = defaultColor, iconClassName = defaultIcon } = resolveEditorAlertStyles(theme, alert)
    const iconMod = startsWith("fa", iconClassName) ? "fa-lg" : "ri-lg"
    const className = `treeview-fixed-icon  ${iconMod} ${iconClassName}`
    return color ? `{{ icon('${className}','${color}') }}` : `{{ icon('${className}') }}`
}

const getNodeText = (theme: Theme, data: any, pathDetails: PathDetails): string => {
    const nodeData = pathString(pathDetails.path, data)
    const { alert } = pathDetails.descriptor
    const alertResp = defaultTo({} as GenericEditorAlert, alert?.(nodeData))
    const { description, icon, iconColor = theme.textColors.lowIntensity } = pathDetails.descriptor
    const resIcon = typeof icon === "function" ? icon(nodeData) : icon
    const resDesc = (typeof description === "function" ? description(nodeData) : description)
    const iconString = !!resIcon ? `${genMDownIcon(theme, alertResp, resIcon, iconColor)}` : ""
    return `${iconString}&nbsp;${defaultTo("", resDesc)}`
}

const getGroupedPath = compose(
    split(" "),
    replace("entry.", "entry "),
    join("] "),
    split("].")
)

const mapPathTreeView = curry((theme: Theme, data: any, sortByDescription: boolean, pathDetails: PathDetails[]) => {

    const objectRepresentaion = reduce((acc, p) => {
        const { description } = p.descriptor
        const nodeData = pathString(p.path, data)
        const ramdaPath = getGroupedPath(p.path)
        const sortOrder = pathOr(9999, ["descriptor", "sortOrder"], p)
        const sortText = typeof description === "function" ? description(nodeData) : description
        const groupBy = defaultTo("", p.descriptor.groupBy?.(nodeData))
        return assocPath(ramdaPath, {
            __node: {
                text: getNodeText(theme, data, p),
                data: pick(["path", "match"], p),
                sortOrder,
                sortText,
                groupBy,
                id: p.path,
                emptyNodeText: p.descriptor.emptyNodeText
            }
        }, acc)
    }, {}, pathDetails)

    const objToTreeNodes = (obj) => {
        const nodes = map(p => {
            const node = p.__node
            const cleaned = dissoc("__node", p)
            node.nodes = objToTreeNodes(cleaned)
            if (node.emptyNodeText && node.nodes?.length === 0) {
                node.nodes = [{ text: node.emptyNodeText, id: `${node.id}-empty`, data: node.data }]
            }
            return node
        }, values(obj))

        const sorts: Array<any> = [
            ascend<any>(prop("sortOrder")),
            ascend<any>(prop("groupBy"))
        ]
        if (sortByDescription) sorts.push(ascend(<any>prop("sortText")))
        return sortWith(sorts, nodes)
    }

    const nodes = objToTreeNodes(objectRepresentaion)
    const rootNode = merge(nodes[0], { collapsed: false })
    return { rootNode }

})

const mapExplorerNodes = (theme: Theme, config: GenericEditorConfig, editing: any, selectedNode: any) => {
    const matches = pluck("match")(config.descriptors) as string[]
    const matchedPaths = matchPaths(matches, editing)
    const props = compose(
        mapPathTreeView(theme, editing, config.explorerSortByDescription),
        map(getPathDetails(config))
    )(matchedPaths)
    if (selectedNode) {
        props["selectedNodeId"] = selectedNode.path
    }
    return props
}

export const explorerMapper = (providers, descriptorProviderName, context) => {
    let mapped = {}
    const loadState: string = pathOr("init", ["params", "loadState"], context)
    const editorConfig = resolveEditorConfig(providers, descriptorProviderName)
    const selectedNode = context.params.selectedNode
    const theme: Theme = defaultTo({}, providers?.theme?.getCurrent())
    if (loadState === "editing") {
        const editing = path(["params", "editingDashBoard"], context)
        mapped = mapExplorerNodes(theme, editorConfig, editing, selectedNode)
    }
    return setBusyIfNotEditing(context, mapped)
}

export const explorerRowClick = (providers, descriptorProviderName, context, node) => {
    const nodeDetails = node.data
    confirmActionIfValid(providers, context.params, () => {
        providers.dashBoardState("lastSync", null)
        providers.dashBoardState("selectedNode", nodeDetails)
    })
}
import { DateTime, DurationUnits } from "luxon"
import * as R from "ramda"

export const getTimezone = (context: any) => Intl.DateTimeFormat().resolvedOptions().timeZone

export const getLocale = (context: any) => R.path(
    ["state", "__requestContextParams", "language", "locale"],
    context
) ?? "en-gb"


export const formatUTCInZoneFactory = (customFormats: Record<string, string>) => (context: any, iso: string, format: string, timezone?: string) => {
    const isCustomFormat = R.has(format, customFormats)
    const formatString = isCustomFormat ? customFormats[format] : DateTime[format]
    const dt = getLocaleZonedDateTime(context, iso, timezone)
    return isCustomFormat ? dt.toFormat(formatString) : dt.toLocaleString(formatString)
}

export const formatUTC = (context: any, iso: string, format: string) => {
    const dt = getLocaleDateTime(context, iso)
    return dt.toLocaleString(DateTime[format])
}

export const getLocaleZonedDateTime = (context: any, iso: string, timezone?: string) => {
    const zone = timezone ? timezone : getTimezone(context)
    const locale = getLocale(context)

    return R.compose<any, any, any, DateTime>(
        dt => dt.setLocale(locale),
        dt => dt.setZone(zone),
        dts => DateTime.fromISO(dts)
    )(iso)
}

export const getLocaleDateTime = (context: any, iso: string) => {
    const locale = getLocale(context)

    return R.compose<any, any, DateTime>(
        dt => dt.setLocale(locale),
        dts => DateTime.fromISO(dts)
    )(iso)
}


const orderedUnits = ["years", "months", "days", "hours", "minutes", "seconds", "milliseconds"]
const orderedUnitsReversed = R.reverse(orderedUnits)

const clampUnits = (minUnit, maxUnit): DurationUnits => {
    const minPos = R.findIndex(R.equals(minUnit), orderedUnitsReversed)
    const maxPos = R.findIndex(R.equals(maxUnit), orderedUnitsReversed)
    const units = R.slice(
        minPos === -1 ? 0 : minPos,
        maxPos === -1 ? orderedUnits.length : maxPos + 1,
        orderedUnitsReversed
    ) as DurationUnits
    return units.length ? units : orderedUnits as DurationUnits
}

const roundUnit = x => x < 0 ? Math.ceil(x) : Math.floor(x)

export const diffToHumanFixed = (dateTimeA: DateTime, dateTimeB: DateTime, minUnit, maxUnit, toHumanOptions): string => {
    const dur = dateTimeA.diff(dateTimeB, clampUnits(minUnit, maxUnit))
    const includedUnits = R.keys(dur["values"]) as Array<string>
    const culledUnits = R.filter(R.includes(R.__, includedUnits), orderedUnits)
    const lastUnit = R.last(culledUnits)
    const roundedDur = dur.set(R.objOf(lastUnit, roundUnit(dur[lastUnit])))
    let remainingUnits = [R.last(culledUnits)]
    if (dur.toMillis() !== 0) {
        remainingUnits = R.reject((unit: string) => roundedDur[unit] === 0, culledUnits)
    }
    return roundedDur.shiftTo(...remainingUnits as any).toHuman(toHumanOptions)
}

export const isNilOrEmpty = R.either(R.isNil, R.isEmpty)

export const UTCDateTimeRegex = /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(?:[.][0-9]{3})?Z$/
export const UTCDateRegex = /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])$/
export const UTCTimeRegex = /^(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(?:[.][0-9]{3})?$/
import { Theme, renderFontCss } from "@sennen/dashboards-react-client"
import {
    mergeDeepRight, clone,
    mergeWith, times, map, contains, forEach,
    addIndex, reduce, toPairs,
    assoc, curry, values, assocPath, path
} from "ramda"
import { HighChartBase, HighChartBaseProps } from "./HighChartBase"
import { staticDefaults, multiDefaults } from "./HighChartHeatMapDefaults"
import { genLineTheme, genAreaTheme, genColumnTheme } from "./HighChartThemeGenerators"

const mapIndexed = addIndex(map)
const reduceIndexed = addIndex(reduce)

const getTypeExt = x => (Array.isArray(x) ? "array" : typeof x) as string

const getSeriesType = (chartProps, seriesProps): string => {
    const chartType = chartProps.chart && chartProps.chart.type
    return (seriesProps && seriesProps.type) ? seriesProps.type : (chartType ? chartType : "line")
}

export interface HighChartHeatMapProps extends HighChartBaseProps { }

export class HighChartHeatMap extends HighChartBase {

    constructor(props) {
        super(props)
    }

    public static defaultProps: HighChartHeatMapProps = {
        chart: {},
        theme: {},
        height: 500
    }

    getChartTheme() {
        const { theme } = this.props
        const bgColor = theme.backgroundColors.default
        const p = theme.palette

        let chartTheme = {
            font: theme.fonts.default.family,
            titleFont: theme.fonts.title,
            titleFontColor: theme.textColors.highIntensity,
            subtitleFont: theme.fonts.default,
            subtitleFontColor: theme.textColors.lowIntensity,
            gridLineColor: theme.backgroundColors.light,
            axisTitleColor: theme.textColors.default,
            axisLabelColor: theme.textColors.lowIntensity,
            legendTextColor: theme.textColors.default,
            labelFont: theme.fonts.default,
            labelFontColor: theme.textColors.highIntensity,
            bubbleColor: theme.backgroundColors.default,
            stopColors: {}
        } as any

        // Add Palette Colors
        chartTheme = reduceIndexed((rootChartTheme: any, color, index) => {
            const c = color//this.HighChartsColor(color).setOpacity(0.75).get("rgba")
            rootChartTheme.stopColors[index + 1] = c
            return rootChartTheme
        }, chartTheme, values(theme.palette))

        // Add Indicator Colors
        chartTheme = reduce((rootChartTheme, indicator) => {
            const c = indicator[1]//this.HighChartsColor(indicator[1]).setOpacity(0.75).get("rgba")
            rootChartTheme.stopColors[indicator[0] as string] = c
            return rootChartTheme
        }, chartTheme, toPairs(theme.indicatorColors))

        return chartTheme;
    }

    mergeOptions(chart: any, chartTheme: any) {

        // color props
        forEach(colorPath => {
            const color = path(colorPath, chart) as any
            const calcColor = this.HighChartsColor(chartTheme.stopColors[color] || color).setOpacity(.75).get("rgba")
            chart = assocPath(colorPath, calcColor, chart)
        },
            [
                ["colorAxis", "minColor"],
                ["colorAxis", "maxColor"]
            ]
        )

        // Stop Colors
        chart.yAxis.stops = map(stop => {
            if (stop.length >= 2) {
                const color = stop[1]
                stop[1] = this.HighChartsColor(chartTheme.stopColors[color] || color).setOpacity(.75).get("rgba")
            }
            return stop
        }, chart.yAxis.stops || [])

        return chart
    }

    getStaticDefaults(chartTheme: any) {
        return staticDefaults(chartTheme)
    }

    getMultiDefaults(chartTheme: any) {
        return multiDefaults(chartTheme)
    }

}

import * as R from "ramda"
import { GenericEditorEditor, GenericEditorActionItemHandlerArgs, GenericEditorAlert } from "./GenericEditorConfigProviderInterfaces"
import { setBusyIfNotEditing, resolveDescriptors, descriptorByMatch, resolveEditorDescriptor, resolveEditorAlertStyles } from "./GenericEditorProviderShared"
import { FormProps, FormControlProps, SyncInfo } from "@sennen/dashboard-extension-sennen-core"
import { hostedComponent } from "../../helpers/formControls"
import { pathString, assocPathString, parsePath } from "../../helpers/path"
import { Theme } from "@sennen/dashboards-interfaces"

const getNodeEditorAndData = (providers, descriptorProviderName, selectedNode, editingDashBoard): GenericEditorActionItemHandlerArgs => {
    const configDescriptors = resolveDescriptors(providers, descriptorProviderName)
    const { match, path } = selectedNode
    const descriptor = descriptorByMatch(configDescriptors, match)
    const { editor } = descriptor
    const rawData = pathString(path, editingDashBoard)
    return {
        providers,
        nodeEditor: editor,
        nodeData: rawData,
        data: editingDashBoard,
        node: {
            match,
            path,
            parsedPath: parsePath(path)
        }
    } as unknown as GenericEditorActionItemHandlerArgs
}

export const editorMapper = (providers, descriptorProviderName, context): FormProps => {

    const { params } = context
    const { selectedNode, editingDashBoard, isReadonly } = params
    let controls = [] as FormControlProps[]
    let data = {}

    if (selectedNode && editingDashBoard) {
        const { nodeEditor, nodeData } = getNodeEditorAndData(providers, descriptorProviderName, selectedNode, editingDashBoard)
        if (nodeEditor) {
            const theme: Theme = R.defaultTo({}, providers?.theme?.getCurrent())
            const actionBar = createActionBar(theme, nodeEditor, nodeData)
            data = nodeEditor.toForm ? nodeEditor.toForm(nodeData, editingDashBoard) : nodeData
            controls = nodeEditor.controls ? nodeEditor.controls(data, context) : []
            if (actionBar) {
                controls.unshift({ id: "divider", type: "divider" } as any)
                controls.unshift(actionBar)
            }
            if (isReadonly) controls = R.map(R.assoc("disabled", true), controls) as any
        }
    }
    return setBusyIfNotEditing(context, { controls, data, hideButtonBar: true }) as any

}


export const editorOnChangeSync = (providers, descriptorProviderName, context, formData, info: SyncInfo) => {
    if (info.isValid) {
        const editor = resolveEditorDescriptor(providers, descriptorProviderName, context.params)
        const dataPath = R.path(["params", "selectedNode", "path"], context)

        const { editingDashBoard } = context.params
        if (editor && editingDashBoard) {
            const data = editor.fromForm ? editor.fromForm(formData) : formData
            const dashBoard = assocPathString(dataPath, data, editingDashBoard)
            providers.dashBoardState("editingDashBoard", dashBoard)
        }
    }
    providers.dashBoardState("lastSync", { formData, info })

}

export const editorOnControlEvent = async (providers, descriptorProviderName, context, controlName: string, eventName: string, eventArgs: any) => {
    const { params } = context
    const { selectedNode, editingDashBoard } = params
    const handlerArgs = getNodeEditorAndData(providers, descriptorProviderName, selectedNode, editingDashBoard)
    handlerArgs.context = context
    const actionBarHandlerResult = await actionBarHandler(controlName, eventName, eventArgs, handlerArgs)
    if (actionBarHandlerResult) providers.dashBoardState("editingDashBoard", actionBarHandlerResult)
}

const createActionBar = (theme: Theme, nodeEditor: GenericEditorEditor, data) => {
    const { actions, alert } = nodeEditor
    const alertResp = alert?.(data)
    if (actions || alertResp) {
        const actionItems = actions || []
        const buttonActions = R.filter(i => i.actionType === "button", actionItems)
        const buttons = R.map(b => R.merge(b.actionOptions, { size: "small" }), buttonActions)
        if (alertResp) buttons.unshift(createAlertButton(theme, alertResp))
        return hostedComponent(
            "EditorActionBar", null, "FormButtonBar",
            { buttons, events: ["onButtonClick"] }
        )
    }
}

const createAlertButton = (theme: Theme, alert: GenericEditorAlert) => {
    const { type } = alert
    const { iconClassName } = resolveEditorAlertStyles(theme, alert)
    return {
        type: `${type}Indicator`,
        text: alert.message, iconClassName,
        readonly: true, hideBorder: true, hidePadding: true,
        overrideTextCasing: true
    }
}

const actionBarHandler = async (controlName: string, eventName: string, eventArgs: any, handlerArgs: GenericEditorActionItemHandlerArgs) => {
    const { actions } = handlerArgs.nodeEditor
    if (controlName === "EditorActionBar" && eventName === "onButtonClick") {
        const buttonName = eventArgs[0]
        const actionItems = actions || []
        const action = R.find(i => i.actionType === "button" && i.actionOptions.name === buttonName, actionItems)
        if (action && action.actionHandler) {
            return await action.actionHandler(handlerArgs)
        }
    }
}
import {
    GenericEditorActionItem,
    GenericEditorActionItemHandlerArgs
} from "../genericEditor/GenericEditorConfigProviderInterfaces"
import { FormProps } from "@sennen/dashboard-extension-sennen-core"
import { JsonSnippet, JsonSnippetOptions, JsonSnippetMethodResults } from "./JsonSnippetActionItem"
import { path, last, assocPath, is, concat, clone, init, apply, pathOr, always, merge, map, flatten } from "ramda"

import { showModalDataForm } from "../genericEditor/GenericEditorProviderModalDataFormDashPart"
import { ChainedActionItem } from "../genericEditor/GenericEditorProviderShared"

const isArray = is(Array)
const isFunction = is(Function)


export type JsonSnippetInsertMode = "append" | "prepend"
export type JsonSnippetInsertLocation = "none" | "child" | "sibling" | "parent" | "absolute"
export type RamdaPath = ReadonlyArray<string | number>
export type JsonInsertSnippetInsertPathFunction = (data: any, templateData: any) => Promise<RamdaPath>
export type JsonInsertSnippetInsertPath = RamdaPath | JsonInsertSnippetInsertPathFunction

export interface JsonSnippetInsertOptions {
    name: string
    buttonText: string,
    buttonIconClassName?: string,
    insertLocation: JsonSnippetInsertLocation
    insertPath?: JsonInsertSnippetInsertPath
    insertMode: JsonSnippetInsertMode
    template: (data: any) => Promise<any>
    templateDataFormResolver?: (args: GenericEditorActionItemHandlerArgs) => Promise<FormProps>
    afterUpdateHandler?: (args: GenericEditorActionItemHandlerArgs, results: JsonSnippetMethodResults) => Promise<any>
}

export interface JsonNodeInsertOptions {
    rootPath: ReadonlyArray<string | number>
    insertLocation: JsonSnippetInsertLocation
    insertPath: ReadonlyArray<string | number>
    insertMode: JsonSnippetInsertMode
}

export interface JsonSnippetUpdateHandlerResult {
    updateMethod: (data: any) => Promise<any>
    handlerMethodResults: JsonSnippetMethodResults
}

export const JsonNodeInsert = (
    data: any,
    toInsert: any,
    options: JsonNodeInsertOptions
) => {
    const { rootPath, insertLocation, insertMode, insertPath } = options
    if (insertLocation === "none") return data
    let pathToInsert = rootPath
    if (insertLocation === "parent") pathToInsert = init(pathToInsert)
    if (insertLocation === "absolute") pathToInsert = []
    pathToInsert = concat(pathToInsert as any, insertPath as any)
    const pathData = clone(pathOr([], pathToInsert as any, data))
    if (isArray(pathData)) {
        if (insertMode === "append")
            (pathData as Array<any>).push(toInsert)
        if (insertMode === "prepend")
            (pathData as Array<any>).unshift(toInsert)
    }
    return assocPath(pathToInsert as any, pathData, data)
}

const insertSingleJsonSnippet = (options: JsonSnippetInsertOptions): GenericEditorActionItem => {
    const { name, buttonText, buttonIconClassName, template, insertLocation } = options
    const insertPath = pathOr([], ["insertPath"], options) as any
    const insertMode = pathOr("append", ["insertMode"], options)
    const templateDataFormResolver = pathOr(always(null), ["templateDataFormResolver"], options)
    const insertOptions = {
        name, buttonText, buttonIconClassName,
        templateDataResolver: async (args: GenericEditorActionItemHandlerArgs) => {
            const templateDataForm = await apply(templateDataFormResolver, [args]) as FormProps
            if (templateDataForm) {
                if (templateDataForm.controls && templateDataForm.controls.length) {
                    const formResult = await showModalDataForm(
                        args.providers,
                        merge(templateDataForm, {
                            inlineLabels: true,
                            buttons: [{ action: "onSubmit", text: "OK" }]
                        })
                    )
                    return formResult
                } else {
                    return templateDataForm.data
                }
            }
            return {}
        },
        templateResolver: async (args: GenericEditorActionItemHandlerArgs, results: JsonSnippetMethodResults) => {
            return template(results.templateDataResult)
        },
        updateHandler: async (args: GenericEditorActionItemHandlerArgs, results: JsonSnippetMethodResults) => {
            return {
                handlerMethodResults: results,
                updateMethod: async (data) => {
                    const insertPathResult = isFunction(insertPath) ?
                        await insertPath(data, results.templateDataResult) :
                        insertPath

                    return JsonNodeInsert(data, results.templateResult, {
                        rootPath: args.node.parsedPath,
                        insertLocation,
                        insertMode,
                        insertPath: insertPathResult
                    })
                }
            } as JsonSnippetUpdateHandlerResult
        }
    } as JsonSnippetOptions
    return JsonSnippet(insertOptions)
}

export const JsonInsertSnippet = (options: JsonSnippetInsertOptions | JsonSnippetInsertOptions[]): GenericEditorActionItem => {
    const actionItems = map(insertSingleJsonSnippet, flatten([options]))
    actionItems.push({
        actionType: "transaction",
        actionOptions: null,
        actionHandler: async (args: GenericEditorActionItemHandlerArgs): Promise<any> => {
            let data = args.data
            for (let result of args.chainResults as JsonSnippetUpdateHandlerResult[]) {
                data = await result.updateMethod(data)
            }
            return data
        }
    })
    return ChainedActionItem(actionItems)
}

export const getLastChainedTemplateData = (args: GenericEditorActionItemHandlerArgs) => {
    const lastChainResult = last(args.chainResults)
    return pathOr(null, ["handlerMethodResults", "templateDataResult"], lastChainResult)
}


import moment from "moment"
import { FormControlProps } from "@sennen/dashboard-extension-sennen-core"
import { prop, assoc, defaultTo, path, assocPath, split, join, curry, isNil, isEmpty } from "ramda"
import { genericPropGetter, genericPropSetter } from "../../../helpers/formGetterSetter"
import { QuickTracksFormControl } from "./QuickTracksForm"

const isNullOrEmpty = (x) => isNil(x) || isEmpty(x)

const convertDateFormat = (from: string, to: string) => (value: string): string => {
    const timeValue = moment(value, from)
    return timeValue.isValid() ? timeValue.format(to) : ""
}

const qtTimeFormat = (c: FormControlProps): string => {
    const qtc: QuickTracksFormControl = (c && c.metaData && c.metaData.qtControl) || {}
    const tp = qtc.TimePicker || { ShowMinutes: true, ShowSeconds: true }
    const format = tp.ShowMinutes ? (tp.ShowSeconds ? "HH:mm:ss" : "HH:mm") : "HH"
    return format
}

const convertFromQtDate = convertDateFormat("DD/MM/YYYY", "YYYY-MM-DD")
const convertToQtDate = convertDateFormat("YYYY-MM-DD", "DD/MM/YYYY")

const convertToQtTime = (c: FormControlProps, value: string): string => {
    return convertDateFormat("HH:mm:ss", qtTimeFormat(c))(value)
}

const convertFromQtTime = (c: FormControlProps, value: string): string => {
    return convertDateFormat(qtTimeFormat(c), "HH:mm:ss")(value)
}

// Quick Tracks Date
export const qtDatePropGetter = (d: any, c: FormControlProps) => {
    const value = genericPropGetter(d, c)
    return convertFromQtDate(genericPropGetter(d, c))
}
export const qtDatePropSetter = (d: any, c: FormControlProps, next: any) => {
    if (isNullOrEmpty(next)) return genericPropSetter(d, c, "")
    const dateValue = moment(next, "YYYY-MM-DD")
    return dateValue.isValid() ? genericPropSetter(d, c, dateValue.format("DD/MM/YYYY")) : d
}

// Quick Tracks Boolean
export const qtBooleanPropGetter = (d: any, c: FormControlProps) => {
    return genericPropGetter(d, c) === "True"
}
export const qtBooleanPropSetter = (d: any, c: FormControlProps, next: any) => {
    return genericPropSetter(d, c, next === true ? "True" : "False")
}

// Quick Tracks DateTime
export const qtDateTimePropGetter = (d: any, c: FormControlProps) => {
    const value = genericPropGetter(d, c)
    const parts = split(",", value || "")
    const datePart = path([0], parts) as string
    const timePart = path([1], parts) as string
    return convertFromQtDate(datePart) + "T" + convertFromQtTime(c, timePart)
}
export const qtDateTimePropSetter = (d: any, c: FormControlProps, next: any) => {
    if (isNullOrEmpty(next)) return null
    const parts = split("T", next)
    const datePart = path([0], parts) as string
    const timePart = path([1], parts) as string
    const qtValue = `${convertToQtDate(datePart)},${convertToQtTime(c, timePart)}`
    return genericPropSetter(d, c, qtValue)
}

// Quick Tracks Multi Select 
export const qtMultiSelectPropGetter = (d: any, c: FormControlProps) => {
    const value = genericPropGetter(d, c)
    return (!value || value === "") ? undefined : split(",", value)
}
export const qtMultiSelectPropSetter = (d: any, c: FormControlProps, next: any) => {
    const qtValue = Array.isArray(next) ? join(",", next) : null
    return genericPropSetter(d, c, qtValue)
}
import { JsonSnippetInsertOptions } from "../../../actionItems/JsonInsertSnippetActionItem"
import { FormProps } from "@sennen/dashboard-extension-sennen-core"
import { GenericEditorActionItemHandlerArgs } from "../../../genericEditor/GenericEditorConfigProviderInterfaces"
import { pathOr, propOr, nth, identity, map } from "ramda"
import { requiredSelect } from "../../../../helpers/formControls"

const stringToSelectItem = x => identity({ text: x, value: x })
const onComponentEventArgs = `{
    component /* Component (target of Event) */,
    eventName /* Name of Event */,
    eventData /* Array of arguments passed to Event */
}`
const typeActions = {
    "Table": ["onRowClick", "onRowMouseEnter", "onRowMouseLeave", "onHeaderRowClick", "onRowControlEvent", "onPageChange"],
    "Form": ["onSubmit", "onSync", "onChangeSync", "onControlEvent"],
    "Timer": ["onTick"],
    "FormButtonBar": ["onButtonClick"],
    "TreeView": ["onNodeSelect", "onNodeCollapse", "onNodeExpand"],
    "OpenLayersMap": ["onFeatureClick", "onFeatureMouseEnter", "onFeatureMouseExit", "onMouseOver", "onMoveEnd", "onDrawStart", "onDrawEnd", "onDrawChange", "onToggleLayerVisibility"],
    "Tabs": ["onTabClick"],
    "ChangeWatcher": ["onValueChange"],
    "Grid": ["onComponentEvent"],
    "Stack": ["onComponentEvent"],
    "Button": ["onClick"],
    "EditableFormField": ["onEditSuccess"]
}

const typeActionFunctionArgs = {
    "Grid:onComponentEvent": onComponentEventArgs,
    "Stack:onComponentEvent": onComponentEventArgs,
    "Tabs:onTabClick": "tab",
    "Table:onRowControlEvent": "controlId, eventName, eventData, row",
    "Table:onRowClick": "row",
    "Table:onRowMouseEnter": "row",
    "Table:onRowMouseLeave": "row",
    "Table:onHeaderRowClick": "row",
    "Table:onPageChange": "pageNo",
    "Form:onSubmit": "data",
    "Form:onSync": "data, info",
    "Form:onChangeSync": "data, info",
    "Form:onControlEvent": "controlId, eventName, eventData",
    "Timer:onTick": "",
    "FormButtonBar:onButtonClick": "name",
    "TreeView:onNodeSelect": "node",
    "TreeView:onNodeCollapse": "node, dict",
    "TreeView:onNodeExpand": "node, dict",
    "OpenLayersMap:onFeatureClick": "",
    "OpenLayersMap:onFeatureMouseEnter": "",
    "OpenLayersMap:onFeatureMouseExit": "",
    "OpenLayersMap:onMouseOver": "",
    "OpenLayersMap:onMoveEnd": "mapInfo",
    "OpenLayersMap:onDrawStart": "",
    "OpenLayersMap:onDrawEnd": "",
    "OpenLayersMap:onDrawChange": "",
    "OpenLayersMap:onToggleLayerVisibility": "eventArgs",
    "ChangeWatcher:onValueChange": "currentValue, previousValue",
    "Button:onClick": "text, { targetElement }",
    "EditableFormField:onEditSuccess": "id, value"
}

const getTypeActions = (type: string): string[] => {
    return propOr([], type, typeActions)
}

export const addActionHandlerSnippet: JsonSnippetInsertOptions = {
    name: "add-action-handler",
    buttonText: "Action Handler",
    buttonIconClassName: "fa fa-plus",
    insertLocation: "child",
    insertPath: ["actionHandlers"],
    insertMode: "append",
    templateDataFormResolver: async (args: GenericEditorActionItemHandlerArgs) => {

        const type = pathOr("", ["nodeData", "properties", "reactComponentName"], args)
        const typeActions = getTypeActions(type)
        const typeActionsList = map(stringToSelectItem, typeActions)

        return {
            data: {
                type,
                actionName: nth(0, typeActions)
            },
            controls: !typeActions.length ? [] : [
                requiredSelect("actionName", "Action name", typeActionsList)
            ]
        } as FormProps
    },
    template: async data => {
        const defaultArgs = "arg1, arg2, arg3"
        const typePropName = `${data.type}:${data.actionName}`
        const args = propOr(defaultArgs, typePropName, typeActionFunctionArgs)
        const actionName = data.actionName || "on${ActionName}"
        return {
            "type": "FunctionActionHandler",
            "actionName": actionName,
            "properties": {
                "function": `(${args}) => {\n\n}`
            }
        }
    }
}
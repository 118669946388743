import { ProviderDefinition } from "@sennen/dashboards-react-client"
import { SennenDateTime } from "./SennenDateTime"
import { SennenDate } from "./SennenDate"
import { SennenTime } from "./SennenTime"
import { highCharts } from "./highCharts"
import { quickTracks } from "./quickTracks"
import { language } from "./language"
import { getTimezone } from "./dateTimeUtils"

/*
    ADVICE: Keep groups of related methods nested inside an object, don't add lots of methods to the root (for cleanlyness)
*/
export const SennenProvider: ProviderDefinition = {
    name: "sennen",
    causesMutation: false,
    createMethod: (options): any => {
        return {
            SennenDateTime,
            SennenDate,
            SennenTime,
            getTimezone,
            language,
            highCharts,
            quickTracks
        }
    }
}
import { Theme } from "@sennen/dashboards-interfaces"
import { ThemedComponentDecorator } from "@sennen/dashboards-react-component"
import { renderFontCss } from "@sennen/dashboards-react-client"
import { curry, clamp, merge, defaultTo } from "ramda"
import * as React from "react"

export interface BlockGaugeProps {
    axisMin?: number
    axisMax?: number
    axisPosition?: number
    value?: number
    text?: string
    theme?: Theme
    color?: string
    textColor?: string
    backgroundColor?: string
    condensedFont?: boolean
    capColor?: string
}

export interface BlockGaugeState { }

const toPercent = curry((min, max, value) => {
    const clampedValue = clamp(min, max, value)
    const total = Math.abs(min) + Math.abs(max)
    return Math.round(((clampedValue - min) / total) * 10000) / 100
})

@ThemedComponentDecorator(theme => {
    return {
        css: `        
            @id .block-gauge {        
                position: relative;        
                display: inline-block;
                width: 100%;
                height: 100%;
                box-sizing: border-box; 
                border-radius: 2px;
            }
        
            @id .block-gauge-bar {
                position: absolute;
                display: block;
                top:0; 
                height: 100%;
                background-color: white;
                box-sizing: border-box;
                border-radius: 2px;
                opacity: 0.8;
            }

            @id .block-gauge-bar.block-gauge-bar-above {                
                border-left: solid 2px @capColor;
                border-right: solid 2px @capColor;
            }

            @id .block-gauge-bar.block-gauge-bar-below {
                border-left: solid 2px @capColor;
                border-right: solid 2px @capColor;
            }

            @id .block-gauge-value {                
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                box-sizing: border-box;
                padding: 0 10px;
                white-space: nowrap;
            }

            @id .block-gauge-condensed .block-gauge-value {
                ${renderFontCss(theme.fonts.condensed)}
            }
        `
    }
})
export class BlockGauge extends React.Component<BlockGaugeProps, BlockGaugeState> {

    public static defaultProps: BlockGaugeProps = {
        axisMin: -100,
        axisMax: 100,
        axisPosition: 0,
        value: 0,
        condensedFont: true
    }

    public render() {
        const { axisMin, axisMax, axisPosition, value, text, color, theme, textColor, condensedFont, backgroundColor, capColor } = this.props
        const percent = toPercent(axisMin, axisMax)

        const axisPos = percent(axisPosition)
        const valuePos = percent(value)
        const styles = { left: "0", width: "0" }
        const barClassName = `block-gauge-bar ${value < axisPos ? "block-gauge-bar-below" : "block-gauge-bar-above"}`
        if (value < axisPosition) {
            styles.left = valuePos + "%"
            styles.width = (axisPos - valuePos) + "%"
        } else {
            styles.left = axisPos + "%"
            styles.width = (valuePos - axisPos) + "%"
        }
        const barStyles = merge(styles, { backgroundColor: color || theme.palette.color1 })
        const valueStyles = merge(styles, { color: textColor || theme.textColors.highIntensity })

        const gaugeStyles = { backgroundColor }
        const gaugeClassName = `block-gauge ${condensedFont ? "block-gauge-condensed" : ""}`

        return this["renderCssContainer"](
            <div className={gaugeClassName} style={gaugeStyles}>
                &nbsp;
                <div style={barStyles} className={barClassName}></div>
                <div style={valueStyles} className="block-gauge-value">{text || value}</div>
            </div>,
            { capColor: defaultTo(theme.textColors.highIntensity, capColor) }
        )

    }

}
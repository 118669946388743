
import { Provider } from "@sennen/dashboards-react-client"
import GradientProvider from "./GradientProvider"

import HeatMapProvider from "./HeatMapProvider"

export class ColoursProvider implements Provider {

    public propertyName = "colours"

    public createMethod(configType: string, config: any): any {
        return {
            gradient: GradientProvider(configType, config),
            heatMap: HeatMapProvider(configType, config)
        }
    }

} 